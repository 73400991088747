import styled from "styled-components";

export const Container = styled.div`
    
    input { 
        background: transparent;
        width: 100%;
        border: 0;
        color: white;
        background: rgba(0, 0, 0, .2);
        padding: 1.6rem 2.5rem;
        border-radius: var(--border-radius);
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);

        ::placeholder {
            color: var(--color-gray-light);
        }
    }
    
    span { 
        color: var(--color-tertiary);
    }
`;