import React, { 
    useState, 
    useCallback, 
    useEffect 
} from "react";
import * as Fi from "react-icons/fi";

import { get } from "../../../../../services/promises";

import { useAxiosErrors } from "../../../../../context/axiosErrors";

import Button from "../../../../../components/Button";
import { Modal } from "../../../../../components/Modal";
import List from "../../../../../components/List";
import ListItem from "../../../../../components/ListItem";
import Skeleton from "../../../../../components/Skeleton";

import Create from "../../../../../components/Tag/Create";

import { TagData } from "../../../../../models";

import { 
    Container 
} from "./styles";

const ManagerConfigTag: React.FC = () => {

    const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
    const [ tags, setTags ] = useState<TagData[] | null>(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadTags() {
        get("manager/tag")
            .then(response => {
                setTags(response as TagData[]);
                setIsLoading(false);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadTags();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTagRegistered = useCallback(() => {
        setShowCreateModal(false);
        loadTags();
    }, [loadTags]);

    return (
        <Container>
            <header>
                <h3>Tags Registradas</h3>
                <Button styliest="success" onClick={() => setShowCreateModal(true)}>
                    Nova Tag
                </Button>
            </header>
            <section>
                <List>
                    {
                        isLoading ? <Skeleton /> :
                        tags && tags.length > 0 ?
                        tags.map(tag => (
                            <ListItem key={tag.id}>
                                <label>{tag.title}</label>
                                <menu>
                                    <Fi.FiEdit size={20} />
                                    <Fi.FiTrash size={20} />
                                </menu>
                            </ListItem>
                        )) :
                        <p>Nenhuma tag encontrada</p>
                    }
                </List>
            </section>

            { 
                showCreateModal &&
                <Modal 
                    visible={showCreateModal} 
                    size="big"
                    handleClose={setShowCreateModal}>
                    <Create 
                        handleTagRegistered={handleTagRegistered}
                        setVisibility={setShowCreateModal} 
                    />
                </Modal>
            }
        </Container>
    );
}

export default ManagerConfigTag;
