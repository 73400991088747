import React from "react";

import { DiscountData } from "../../../models";

import DiscountUpdate from "../Update";

interface DiscountUpdateByMemberProps {
    discount: DiscountData;
    setVisibility(value: boolean): void;
    handleUpdated(): void;
}

const DiscountUpdateByMember: React.FC<DiscountUpdateByMemberProps> = ({discount, setVisibility, handleUpdated}) => {
    return (
        <DiscountUpdate
            routeToGetAllCategories="member/category/main"
            routeToGetAllCategoriesByCategory="member/category/bycategory"
            routeToUpdate="member/discount"
            routeToSubmitToApprove="member/discount/submittoapproval"
            routeToUpdateImage={`member/discount/${discount.id}/updateImage`}
            routeToGetAllDiscountCategories="member/discountcategory/bydiscount"
            routeToRegisterDiscountCategory="member/discountcategory"
            routeToRemoveDiscountCategory="member/discountcategory"
            discount={discount}
            inManagerArea={false}
            setVisibility={setVisibility}
            handleUpdated={handleUpdated}
        />
    );
}

export default DiscountUpdateByMember;