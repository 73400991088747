import React, {
    useEffect,
    useCallback,
    useState
} from "react";

import { get } from "../../../../../services/promises";

import { useAxiosErrors } from "../../../../../context/axiosErrors";

import Button from "../../../../../components/Button";
import { Modal } from "../../../../../components/Modal";
import List from "../../../../../components/List";
import Skeleton from "../../../../../components/Skeleton";

import CategoryCreate from "../../../../../components/Category/Create";
import CategoryListItem from "../../../../../components/Category/ListItem";

import { CategoryData } from "../../../../../models";

import { 
    Container 
} from "./styles";

const ManagerConfigCategory: React.FC = () => {

    const [ categories, setCategories ] = useState<CategoryData[]>({} as CategoryData[]);
    const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadCategories() {
        get("manager/category?justMain=true")
            .then(response => {
                setCategories(response as CategoryData[]);
                setIsLoading(false);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadCategories();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegistered = useCallback(() => {
        loadCategories();
        setShowCreateModal(false);
    }, [loadCategories]);

    const handleUpdated = useCallback(() => {
        loadCategories();
    }, [loadCategories]);

    return (
        <Container>
            <header>
                <h3>Categorias Registradas</h3>
                <Button styliest="success" onClick={() => setShowCreateModal(true)}>
                    Nova Categoria
                </Button>
            </header>
            <section>
                <List>
                    {
                        isLoading ? <Skeleton /> :
                        categories && categories.length > 0 ?
                        categories?.map(category => (
                            <CategoryListItem 
                                key={category.id}
                                category={category} 
                                handleCategoryUpdated={handleUpdated} />
                        )) :
                        <p>Nenhuma categoria encontrada</p>
                    }
                </List>
            </section>
            <Modal 
                visible={showCreateModal} 
                size="small"
                handleClose={setShowCreateModal}>
                <CategoryCreate 
                    setVisibility={setShowCreateModal}
                    handleCategoryRegistered={handleRegistered}
                />
            </Modal>
        </Container>
    );
}

export default ManagerConfigCategory;
