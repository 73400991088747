import React, {
    useCallback,
    useState
} from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../../../context/auth";
import { CompanyData } from "../../../../models";

import { Modal } from "../../../../components/Modal";
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import AsideMenuItem from "../../../../components/AsideMenuItem";
import CompanyProfile from "../../../../components/Company/Profile";
import CompanyUpdate from "../../../../components/Company/Update";

import {
    Container 
} from "./styles";

const AreaCompanyProfile: React.FC = () => {

    const [ showModalUpdate, setShowModalUpdate ] = useState<boolean>(false);

    const { company, updateCompany } = useAuth();
    const navigation = useHistory();

    const handleCompanyUpdated = useCallback(async () => {
        await updateCompany();
        setShowModalUpdate(false);
        //eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Header>
                <h1>Seu Estabelecimento</h1>
                <b onClick={() => navigation.push("/member")}>
                    Voltar para Meus Estabelecimentos
                </b>
            </Header>
            <section>
                <AsideMenu>
                    <AsideMenuItem 
                        onClick={() => setShowModalUpdate(true)}
                    >
                        Editar Dados
                    </AsideMenuItem>
                </AsideMenu>
                <article>
                    <CompanyProfile
                        company={company as CompanyData}
                    />
                </article>
            </section>
            <Modal
                visible={showModalUpdate}
                size="small"
                handleClose={setShowModalUpdate}
            >
                <CompanyUpdate 
                    company={company as CompanyData} 
                    handleCompanyUpdated={handleCompanyUpdated}
                    setVisibility={setShowModalUpdate} />
            </Modal>
        </Container>
    );
}

export default AreaCompanyProfile;