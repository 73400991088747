import styled from "styled-components";

export const Container = styled.div`

    text-align: center;

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        svg { 
            margin-right: 1rem;
            color: var(--color-tertiary);
        }
    }

    footer { 
        text-align: center;
        margin-top: 2.2rem;

        label {     
            font-size: 1.2rem;
            cursor: pointer;
        }
    }

`;