import styled from "styled-components"; 

export const Container = styled.main`

    display: grid;
    grid-template-areas: 
        "header"
        "content"
        "footer";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    margin: var(--padding-2x);

    header {
        grid-area: header;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin-bottom: var(--margin-2x);
    }

    section {
        grid-area: content;
        overflow-y: auto;

        img { 
            margin: var(--margin-2x);
        }

        > div { 
            text-align: center;
            padding: var(--margin-2x);
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }

    footer {
        grid-area: footer;
        display: grid;
        grid-template-areas: "input button";
        grid-template-columns: 1fr 10%;
        margin-top: var(--margin-2x);

        & > form {
            grid-area: input;
        }

        input {
            text-align: center;
            font-size: 3rem;
            font-weight: bold;
            text-transform: uppercase;

            ::placeholder {
                font-weight: normal;
                font-size: 2rem;    
            }
        }

        & > div {
            grid-area: button;
            background-color: #6EB257;
            width: 100%;
            margin-left: 1rem;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: var(--border-radius);

            :hover {
                opacity: .9;
            }
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr 30%;
        }

    }

`;

export const ModalDiscount = styled.div`

    text-align: center;

    section {
        img { 
            height: 10rem;
            width: 10rem;
            border-radius: 5rem;
            object-fit: cover;
        }
    }

    footer { 
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;


        margin-top: 3rem;

        small { 
            margin-top: 1rem;
            cursor: pointer;
        }
    }






`;

export const ContainerWithoutDiscounts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: -4rem;

    img {
        width: 30vw;
        margin-bottom: var(--margin-2x);
    }

    p {
        cursor: pointer;
    }
`;