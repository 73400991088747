import styled from "styled-components";

export const Container = styled.main`

    text-align: center;

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    section {
        margin-top: var(--margin-2x);
    }

    form {

        input {
            text-align: center;
            margin-top: var(--margin-2x);
        }

        button {
            width: 100%;
            margin: var(--margin) auto;
        }
    }

    small { 
        cursor: pointer;
    }


`;