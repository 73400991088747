import styled, { css } from "styled-components";
import { Form as FormUnform } from "@unform/web";

import InputComponent from "../../Input";

interface CategoryProps {
    selected: boolean;
}

export const Container = styled.main`
    
    width: 100%;

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: var(--margin-2x);

        menu {
            display: flex;
            flex-direction: row;
            align-items: center;

            small {
                margin-right: var(--margin-2x);
                cursor: pointer;   
            }
        }
    }

    & > section {            
        display: grid;
        grid-template-columns: 1fr 3fr 6fr;
        grid-gap: var(--margin-2x);
        max-width: 100%;         
        
        & > div {
            
            :first-child {
                text-align: center;
    
                main {
                    margin-bottom: var(--margin);
                }
            }
    
            :last-child {  

                position: relative;
    
                .discountDaysList {
                    display: grid;
                    grid-template-columns: repeat(7, 1fr);
                    gap: var(--margin);
                    list-style: none;
                    margin: var(--margin) auto;
                    position: relative;
                }   

                footer {
                    bottom: 0;
                    position: absolute;
                    margin-bottom: var(--margin-2x);
                }
            }
    
        }
    }

    @media (max-width: 768px) {
        & > section {
            display: flex;
            flex-direction: column;  
        
            & > div {        
                :last-child {          
                    .discountDaysList {
                        display: flex;
                        flex-direction: column;

                        li {
                            margin-top: var(--margin-2x);
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

`;

export const Form = styled(FormUnform)`

    menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--margin-2x);
        margin-top: var(--margin);
    }

    input, textarea { 
        margin-bottom: var(--margin);
    }

    footer 
    {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: var(--margin-2x);

        p {
            margin-bottom: var(--margin);
        }
    }

`;

export const Input = styled(InputComponent)`
`;

export const Categories = styled.ul`
    margin-bottom: 20px;
    overflow-x: scroll;
    display: flex;
    width: 55vw;
    white-space: nowrap;

    ::-webkit-scrollbar {
        display: none;
    }
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Category = styled.li<CategoryProps>`

    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--color-tertiary);
    border-radius: var(--border-radius);
    padding: var(--padding-inner);
    cursor: pointer;
    transition: background .2s;
    margin-right: var(--margin);
    font-size: 1.2rem;

    :hover {
        background: var(--color-tertiary);
    }

    ${
        props => props.selected && css`
            background: var(--color-tertiary);
        `
    }

`;