import styled from "styled-components";

export const Container = styled.main`

    cursor: pointer;

    :hover {
        color: var(--color-white-original);
    }
    

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg { 
            margin-right: var(--margin);
        }
    }

    input { 
        display: none;
    }

`;