import styled, { css } from "styled-components";
import { Form as FormUnForm } from "@unform/web";

interface DiscountDayNameProps {
    selected: boolean;
}

export const Container = styled.div`
    
`;

export const DiscountDayName = styled.div<DiscountDayNameProps>`
    
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--color-tertiary);
    border-radius: var(--border-radius);
    padding: var(--padding-inner);
    cursor: pointer;
    transition: background .2s;

    :hover {
        background: var(--color-tertiary);
    }

    ${
        props => props.selected && css`
            background: var(--color-tertiary);
        `
    }

`;

export const DiscountDayPeriods = styled.div`

    small {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: var(--margin) auto;
        cursor: pointer;
    }

`;

export const FormDiscountDayPeriod = styled(FormUnForm)`

    h2, select, button {
        margin-bottom: var(--margin) !important;
    }

    section {
        display: flex;
        flex-direction: row;
        gap: var(--margin);
    }    

    small {
        cursor: pointer;
    }
`

export const DiscountDayPeriodList = styled.ul`

    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    max-width: 100%;
    gap: var(--margin);

    li {
        width: 100%;
        border: solid 1px var(--color-tertiary);
        padding: var(--padding-inner);
        text-align: center;
        border-radius: var(--border-radius);
    }

    svg {
        margin-top: var(--margin);
        cursor: pointer;
    }
`;