import React from "react";

import { DiscountData } from "../../../models";

import DiscountUpdate from "../Update";

interface DiscountUpdateByMemberProps {
    discount: DiscountData;
    setVisibility(value: boolean): void;
    handleUpdated(): void;
}

const DiscountUpdateByManager: React.FC<DiscountUpdateByMemberProps> = ({discount, setVisibility, handleUpdated}) => {
    return (
        <DiscountUpdate
            routeToGetAllCategories="manager/category/main"
            routeToGetAllCategoriesByCategory="manager/category/bycategory"
            routeToUpdate="manager/discount"
            routeToUpdateImage={`manager/discount/${discount.id}/updateImage`}
            routeToGetAllDiscountCategories="manager/discountcategory/bydiscount"
            routeToRegisterDiscountCategory="manager/discountcategory"
            routeToRemoveDiscountCategory="manager/discountcategory"
            discount={discount}
            inManagerArea={true}
            setVisibility={setVisibility}
            handleUpdated={handleUpdated}
        />
    );
}

export default DiscountUpdateByManager;