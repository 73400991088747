import styled from "styled-components";

export const Container = styled.main`

    display: grid;
    grid-template-areas:
        "header"
        "section";
    grid-template-rows: auto 1fr;

    & > header {
        grid-area: "header";
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;

            & > main { 
                margin-right: var(--margin-2x);
            }
        }
    }

    & > section {
        grid-area: "section";
        display: grid;
        grid-template-columns: 1fr 4fr;

        & > menu {
            text-align: left;
        }

        & > div {

            & > header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: var(--margin-2x);

                small {
                    display: flex;
                    align-items: center;
                }
            }
            
        }
    }

`;