import styled, { css } from "styled-components";

interface ContainerProps {
    inProcess?: boolean,
    isSmall: boolean;
}

export const Container = styled.main<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--rgba-black-transparent);
    border-style: solid;
    border-color: var(--color-white-adjusted);
    border-radius: 50%;
    max-height: 100%;
    max-width: 100%;


    ${ props => props.isSmall 
        
        ? css`
            height: 50px;
            width: 50px;
            border-width: .3rem;
        `
        : css`
            height: 150px;
            width: 150px;
            border-width: .5rem;
        `
    }

    img {
        border-radius: 50%;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    ${
        props => props.inProcess && css`
            animation: loadImage .3s infinite;

            @keyframes loadImage {
                0% { transform: scale(1) }
                50% { transform: scale(1.05)}
                100% { transform: scale(1)}
            }
        `
    }
`;