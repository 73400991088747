import React, { 
    useRef, 
    useEffect
}  from 'react';
import * as Fi from "react-icons/fi";

import { useField } from '@unform/core';

import { 
    Container
} from "./styles";

interface Props {
  name: string;
  label?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const ImageInput: React.FC<InputProps> = ({ name, label, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref: HTMLInputElement) {
                ref.value = '';
            }
        })
    }, [fieldName, registerField]);

    return (
        <Container>
            <div onClick={() => inputRef.current?.click()}>
                <Fi.FiImage size={15} /> { label && label }
                { error && <span>{error}</span>}
            </div>
            <input
                type="file"
                ref={inputRef}
                {...rest}
            />
        </Container>
    );
};

export default ImageInput;