import React from "react";

import {
    Container
} from "./styles";

const AsideMenu: React.FC = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    );
}

export default AsideMenu;