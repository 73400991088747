import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import api from "./services/api";
import { useAuth } from "./context/auth";

import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import AccountRoute from "./pages/Account/routes";
import ManagerRoute from "./areas/manager/routes";
import MemberRoute from "./areas/member/routes";
import CompanyRoute from "./areas/company/routes";

function Routes() {

    const { signOut } = useAuth();

    api.interceptors.response.use(response => {
        return response
    }, error => {
        return new Promise((resolve, reject) => {      
            if(error.response.status === 401 && error.config && !error.config._retry) {
    
                const originalReq = error.config;
                originalReq._retry = true;
                
                const refreshToken = localStorage.getItem("@D.Club:refreshtoken") as string;
    
                var res = api.post('account/newrefreshtoken', JSON.parse(refreshToken)).then(async tokenRefreshResponse => {
                    
                    localStorage.setItem("@D.Club:token", tokenRefreshResponse.data.token);
                    localStorage.setItem("@D.Club:refreshtoken", JSON.stringify(tokenRefreshResponse.data.refresh_token));
    
                    originalReq.headers.authorization = `Bearer ${tokenRefreshResponse.data.token}`;
                    api.defaults.headers.authorization = `Bearer ${tokenRefreshResponse.data.token}`;
    
                    return api(originalReq);
                }).catch(() => {
                    signOut();
    
                    return reject(error);
                });
    
                return resolve(res);
            }
            else { 
                reject(error);
            }
        });
    });

    return (
        <BrowserRouter>
            <Route path="/" exact component={Home} />
            <Route path="/TermsAndConditions" exact component={TermsAndConditions} />
            <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
            <Route path="/Account" component={AccountRoute} />
            <Route path="/Manager" component={ManagerRoute} />
            <Route path="/Member" component={MemberRoute} />
            <Route path="/Company" component={CompanyRoute} />
        </BrowserRouter>
    )
}

export default Routes;