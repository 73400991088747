import React, { 
    useState, 
    useContext, 
    createContext, 
    useCallback } 
from "react";
import { AxiosError } from "axios";

import { useAuth } from "../context/auth";

import ModalErrors from "../components/ModalErrors";

interface AxiosErrorsContextData { 
    sendError(err: AxiosError): void;
    fadeModal(value: boolean): void;
}

const AxiosErrorsContext = createContext<AxiosErrorsContextData>({} as AxiosErrorsContextData);

const AxiosErrorsProvider: React.FC = ({children}) => {

    const { signOut } = useAuth();

    const [ visible, setVisible ] = useState<boolean>(false);
    const [ messages, setMessages ] = useState<string[]>([] as string[]);

    const sendError = useCallback((error: AxiosError) => {

        if(error.response == null) {
            setMessages([`${error}`]);
        }
        else {
            switch (error.response?.status) {
                case 401: 
                    setMessages(["Acesso Negado"]);
                    signOut();
                    break;
                case 405: 
                    setMessages(["Método não encontrado"]);
                    break;
                case 500:
                    setMessages(["Houve uma falha no processamento"]);
                    break;
                case 400:
                    setMessages(error.response?.data.errors.Messages);
                    break;
                default:
                    break;
            }        
        }

        setVisible(true);
    }, [signOut]);

    const fadeModal = useCallback(() => {
        setVisible(false);
        setMessages([]);
    }, []);

    return (
        <AxiosErrorsContext.Provider value={{
            sendError,
            fadeModal
        }}>
            {children}
            <ModalErrors 
                messages={messages}
                size="small"
                visible={visible}
                fadeModal={fadeModal}
                handleClose={fadeModal}
            />
        </AxiosErrorsContext.Provider>
    )

}

function useAxiosErrors(): AxiosErrorsContextData {
    const context = useContext(AxiosErrorsContext);

    if(!context) {
        throw new Error("There is no context created");
    }

    return context;
}

export { AxiosErrorsProvider, useAxiosErrors }