import React from "react";

import { 
    Container
} from "./styles";

function ManagerHome() {
    return(
        <Container>
            <header>
                <h1>Painel do Gerente</h1>
            </header>
        </Container>
    );
}

export default ManagerHome;