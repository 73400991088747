import React, {
    useState,
    useEffect,
    useCallback
} from "react";
import * as Fi from "react-icons/fi";

import { get } from "../../../../services/promises";

import { ManagerData } from "../../../../models";

import Button from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import Skeleton from "../../../../components/Skeleton";

import CreateManager from "../../../../components/Manager/Create";
import UpdateManager from "../../../../components/Manager/Update";
import RemoveManager from "../../../../components/Manager/Remove";

import {
    Container,
    Header,
    ManagerListItens,
    ManagerListItem
} from "./styles";

function ManagerList() {

    const [ showPopUpRegister, setShowPopUpRegister ] = useState(false);
    const [ showPopUpRemove, setShowPopUpRemove ] = useState(false);
    const [ showPopUpUpdate, setShowPopUpUpdate ] = useState(false);
    const [ managers, setManagers ] = useState<ManagerData[] | null>(null);
    const [ managerToChange, setManagerToChange ] = useState<ManagerData | null>(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    async function loadManagers() {
        get("manager/manager")
            .then(response => {
                setManagers(response as ManagerData[]);
                setIsLoading(false);
            })
            .catch(errors => console.log(errors));
    }

    useEffect(() => {
        loadManagers();
    }, []);

    /*REGISTER MANAGER*/

    const handleManagerRegistered = useCallback((result: boolean) => {
        setShowPopUpRegister(false);

        loadManagers();
    }, []);

    /*UPDATE MANAGER*/

    const handleSetManagerToUpdate = useCallback((manager: ManagerData) => {
        setShowPopUpUpdate(true);
        setManagerToChange(manager);
    }, []);

    const handleManagerUpdated = useCallback((result: boolean) => {
        setShowPopUpUpdate(false);
        loadManagers();
    }, []);

    useEffect(() => {
        if(!showPopUpRemove) {
            setManagerToChange(null);
        }
    }, [showPopUpRemove]);


    /*REMOVE MANAGER*/

    const handleSetManagerToRemove = useCallback((manager: ManagerData) => {
        setShowPopUpRemove(true);
        setManagerToChange(manager);
    }, []);

    useEffect(() => {
        if(!showPopUpRemove) {
            setManagerToChange(null);
        }
    }, [showPopUpRemove]);

    const handleManagerRemoved = useCallback((response: boolean) => {
        if(response) {
            loadManagers();
            setShowPopUpRemove(false);
        }
    }, []);

    return(
        <>
        <Container>
            <Header>
                <h1>
                    Time Descontinho
                </h1>
                <Button styliest="success" onClick={() => setShowPopUpRegister(true)}>
                    Criar Novo
                </Button>
            </Header>
            <ManagerListItens>
                {
                    isLoading ? <Skeleton /> :
                    managers && managers.length > 0 ?
                        managers.map(manager => (
                            <ManagerListItem key={manager.id}>
                                <label>{manager.name}</label>
                                {manager.email}
                                <menu>
                                    <Fi.FiEdit 
                                        size={20}
                                        onClick={() => handleSetManagerToUpdate(manager)} />
                                    <Fi.FiTrash 
                                        size={20}
                                        onClick={() => handleSetManagerToRemove(manager)}  />
                                </menu>
                            </ManagerListItem>
                        )) :
                        <p>Nenhum gerente encontrado</p>
                }
            </ManagerListItens>  
        </Container>
        <Modal 
            visible={showPopUpRegister} 
            size="small"
            handleClose={setShowPopUpRegister}>
            <CreateManager 
                handleManagerRegistered={handleManagerRegistered}
                setVisibility={setShowPopUpRegister} />
        </Modal>
        <Modal 
            visible={showPopUpRemove} 
            size="small"
            handleClose={setShowPopUpRemove}>
            <RemoveManager
                id={managerToChange?.id}
                name={managerToChange?.name}
                setVisibility={setShowPopUpRemove}
                handleManagerRemoved={handleManagerRemoved}
             />
        </Modal>
        <Modal 
            visible={showPopUpUpdate} 
            size="small"
            handleClose={setShowPopUpUpdate}>
            <UpdateManager
                manager={managerToChange as ManagerData}
                handleManagerUpdated={handleManagerUpdated}
                setVisibility={setShowPopUpUpdate}
            />
        </Modal>
        </>
    );
}

export default ManagerList;