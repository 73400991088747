import React, {
    useRef
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { parse } from "date-fns";

import api from "../../../services/api";
import { useAxiosErrors } from "../../../context/axiosErrors";
import getValidationsErrors from "../../../utils/getValidationsErros";

import { CustomerData } from "../../../models";

import InputMask from "../../InputMask";
import Input from "../../Input";
import Select from "../../Select";
import Button from "../../Button";

import {
    Container
} from "./styles";

interface CustomerCreateProps {
    setVisibility(value: boolean): void;
    handleCustomerRegistered(): void;
}

const CustomerCreate: React.FC<CustomerCreateProps> = ({setVisibility, handleCustomerRegistered}) => {

    const formRef = useRef<FormHandles>(null);
    const { sendError } = useAxiosErrors();

    const handleSubmit: SubmitHandler<CustomerData> = async (data) => {
        
        try {
            const shape = Yup.object().shape({
                name: Yup
                    .string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com Nome"),
                email: Yup
                    .string()
                    .email("E-mail inválido")
                    .required("Entre com o E-mail"),
                phoneNumber: Yup
                    .string()
                    .required("Entre com o telefone"),
                birthDate: Yup 
                    .string()
                    .required("Entre com a data de nascimento"),
                gender: Yup
                    .string()
                    .required("Selecione o sexo")
            });

            await shape.validate(data, {
                abortEarly: false
            });
            
            data.birthDate = parse(
                data.birthDate.toString(),
                'dd/MM/yyyy',
                new Date()
            );

            data.gender = parseInt(data.gender.toString());
            
            await api.post("customer", data);

            handleCustomerRegistered();
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }
    }

    return (
        <Container>
            <h2>Novo Assinante</h2>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <Input 
                    name="password"
                    type="hidden"
                    defaultValue="@DClub.20" />
                <Input 
                    name="passwordConfirmed"
                    type="hidden"
                    defaultValue="@DClub.20" />
                <Input 
                    name="name"
                    placeholder="Nome do Assinante" />
                <Input 
                    name="email"
                    placeholder="E-mail do Assinante" />
                <InputMask
                    name="phoneNumber"
                    mask="(99) 9 9999-9999"
                    placeholder="Telefone do Assinante" />
                <InputMask 
                    name="birthDate"
                    mask="99/99/9999"
                    placeholder="Data de Nascimento" />
                <Select name="gender">
                    <option value="">Sexo do Assinante</option>
                    <option value="1">Masculino</option>
                    <option value="2">Feminino</option>
                </Select>
                <Button type="submit" styliest="success">Finalizar</Button>                    
            </Form>
            <small onClick={() => setVisibility(false)}>Voltar</small>
        </Container>
    );
}

export default CustomerCreate;