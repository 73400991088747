import * as signalR from "@microsoft/signalr";

const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(
        "https://descontinhoclub.azurewebsites.net/customerdiscounthub",
        {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }
    )
    .withAutomaticReconnect()
    .withHubProtocol(new signalR.JsonHubProtocol())
    .configureLogging(signalR.LogLevel.Information)  
    .build();


hubConnection.start().catch(err => console.log(err));

export default hubConnection;