import React, { useEffect, useRef } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import { useField } from "@unform/core";

import { 
    Container, 
} from "./styles";
 
const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

interface Props {
    name: string;
    label?: string;
    handleOnChange(event: any, value: any, maskedValue: any): void;
}
 
const InputMaskIntl = ({ name, label, handleOnChange }: Props) => {

    const { fieldName, registerField, defaultValue, error } = useField(name);
    
    const inputRef = useRef(null);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'props.value'
        });
    }, [fieldName, registerField]);
 
    return(
        <Container>
            { label && <label htmlFor={fieldName}>{label}</label>}

            <IntlCurrencyInput 
                id={fieldName}
                defaultValue={defaultValue}
                currency="BRL" 
                config={currencyConfig}
                onChange={handleOnChange}
            />

            { error && <span>{error}</span>}
        </Container>
    );
}
 
export default InputMaskIntl;