import styled from "styled-components";
import waves_right from "../../assets/images/waves_right.svg";

import Button from "../../components/Button";

export const Container = styled.div`
    
    background: 
        url(${waves_right}) no-repeat center,
        #000;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

`;

export const Header = styled.div`
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 5rem;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;

    img {
        height: 3rem;
    }

    menu {
        display: flex;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 1rem 0rem;
            color: var(--color-white-adjusted);
            border-radius: 30px;
            transition: background .2s;
            font-size: 20px;
            transition: color .3s;

            :hover {
                color: var(--color-tertiary);
            }

            svg {
                margin-right: 10px
            }
        }

        button {
            cursor: pointer;
        }
    }

    @media(max-width: 768px) {
        padding: 20px;
        flex-direction: column;

        img {
            margin-bottom: var(--margin-2x)
        }

        menu a{
            display: none;
        }
    }

`;

export const Content = styled.section`
    
    flex: 1;
    display: grid;
    padding: 50px;
    grid-template-columns: 1fr 40%;
    
    & > div {
        display: flex;
        flex-direction: column;
        padding: 30px;
        justify-content: center;
        text-align: left;
        color: var(--color-white-adjusted);

        h1 {
            font: 700 5rem "Magallanes";
        }

        p {
            font-size: 2.3rem;
            word-wrap: break-word;

            b {
                color: var(--color-tertiary);
            }
        }

        p.black {
            font-weight: 700;
            margin-top: 6rem;
        }

        @media(max-width: 768px) {
            padding: 20px 0px;

            h1 {
                font-size: 3rem;
                margin-bottom: 20px;
            }
        }
    }


    menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: var(--color-black-ligther);
        height: 100%;
        border-radius: 20px;
        padding: 30px;
        box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
        color: var(--color-gray-light);
        text-align: center;
        transition: color .2s;

        :hover {
            color: var(--color-white-adjusted);
        }

        h1 {
            color: var(--color-white-adjusted);
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px
        }

        p {
            font-size: 2rem;
        }

        form {
            width: 100%;
            text-align: left;

            input {
                margin: 10px 0px;
            } 

            input[type=submit] {
                border-radius: 1rem;
                width: 100%;
                border: 0;
                height: 50px;
                background: linear-gradient(
                    120deg,
                    var(--color-primary),
                    var(--color-secundary),
                    var(--color-tertiary)
                );
                color: var(--color-white-adjusted);
                font-size: 20px;
                padding: 0px;
                cursor: pointer;
                margin-bottom: 20px;
                transition: background 1s;

                :hover {
                    background: linear-gradient(
                        240deg,
                        var(--color-primary),
                        var(--color-secundary),
                        var(--color-tertiary)
                    );
                }
            }
        }
    } 

    @media (max-width: 768px) {
    
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 50px;

    }

`;

export const ButtonSuccess = styled(Button)`
    width: 30vw;
    margin: 4rem 0rem;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const CheckSendEmail = styled.div`
    h2 {
        margin-top: 3rem;
    }
`;

export const Frame = styled.div`
    
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0rem;

    iframe {
        width: 80%;
        height: 70vh;
        border: 0;
        border-radius: 20px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, .3);
    }
`;

export const MoreItems = styled.div`
    color: var(--color-white-adjusted);

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        padding: 3rem;
        margin-top: 5rem;
        margin-bottom: 10rem;
        max-width: 100%;

        li { 
            width: 100%;
            padding: 5rem;
            text-align: center;

            img {
                height: 30rem;
            }

            h2 {
                margin: 2rem 0;
            }

            p {
                margin-top: 2rem;
                font-size: 2rem;
                line-height: 3rem;
            }
        }
    }

    @media (max-width: 768px) {

        ul {
            flex-direction: column;

            li {
                img {
                    max-width: 100%;
                }
            }
        }
    }
`;

export const AlertMessage = styled.h1`
    font-size: 5rem;
    margin: 2rem auto;
    width: 70%;
    text-align: center;
    background: -webkit-linear-gradient(
        45deg,
        var(--color-primary), 
        var(--color-secundary), 
        var(--color-tertiary)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: 768px) {
        font-size: 3rem;
    }
`;

export const ImageDescription = styled.section`

    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    margin: 10rem auto;
    max-width: 60%;

    article {
        display: flex;
        align-items: center;
        color: var(--color-white-adjusted);
        font-size: 4rem;
        text-align: right;
    }

    img { 
        height: 40rem;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;

        article {
            text-align: center;
        }
    }
`;

export const Footer = styled.footer`

    background-color: #000;
    padding: var(--padding-inner-max);
    
    color: var(--color-white-adjusted);    
    text-align: center;

    label {
        cursor: pointer;
        transition: color .2s;

        :first-child {
            margin-right: var(--margin);
        }

        :hover {
            color: var(--color-gray-light);
        }
    }

`;