import React from "react";

import DiscountList from "../../../../components/Discount/List";

import {
    Container
} from "./styles";

const ManagerDiscount : React.FC = () => {
    return (
        <Container>
            <header>
                <h1>Descontinhos</h1>
            </header>
            <DiscountList />
        </Container>
    );
}

export default ManagerDiscount;