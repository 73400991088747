import styled from "styled-components";

export const Container = styled.main`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--rgba-white-original);
    margin: 1rem 0;
    padding: 2rem;
    color: var(--color-black-ligther);
    border-radius: .4rem;
    transition: transform .3s, background .3s;
    
    :hover {
        background: var(--color-white-adjusted);
        transform: translateX(1rem);
    }

    label {
        font-weight: 700;
    }

    menu {

        align-items: center;

        svg {
            margin-right: 1rem;
            cursor: pointer;
            transition: transform .3s;

            :hover {
                transform: scale(1.3);
            }
        }
    }

    @media (max-width: 768px) { 
        flex-direction: column;

        menu {
            margin-top: var(--margin-2x);
        }

        > :not(menu) {
            display: flex;
            flex-direction: column;

            > div {
                flex-direction: column;
            }
        }
    }
`;