import React, { useRef, useState } from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../../services/api";
import getValidationsErrors from "../../../utils/getValidationsErros";
import { useErrors } from "../../../context/errors";

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import {
    Container
} from "./styles";

interface FormData {
    email: string;
}

const AccountForgotPassword: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { sendMessages } = useErrors();

    const [ loading, setLoading ] = useState<boolean>(false);

    const handleSubmitForm: SubmitHandler<FormData> = async (data) => {

        setLoading(true);
        
        try {
            const schema = Yup.object().shape({
                email: Yup.string()
                    .email("E-mail inválido")
                    .required("Digite seu e-mail")
            });
            
            await schema.validate(data, {
                abortEarly: false
            });

            try {
                var response = await api.post(
                    'account/forgotpassworddata', { email: data.email }                
                );

                if(response.status === 200) {
                    toast.success("Recuperação de senha enviada para seu e-mail");
                }
                else {
                    toast.error("Não foi possível recuperar sua senha");
                }
            }
            catch(err) {
                sendMessages(err.response.data.errors.Messages);
            }
        } catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
        }

        setLoading(false);

    }

    return (
        <Container>
            <h1>RECUPERAR SENHA</h1>
            <Form ref={formRef} onSubmit={handleSubmitForm}>
                <Input name="email" placeholder="Digite seu e-mail" />
                <Button type="submit" 
                        styliest="success"
                        inProcess={loading}>
                    Recuperar
                </Button>
            </Form>
            <Link to="Logon">Voltar</Link>
        </Container>
    );
}

export default AccountForgotPassword;