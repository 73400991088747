import styled from "styled-components";

export const Container = styled.main`

    text-align: center; 

    small {
        cursor: pointer;
    }

    form {
        input {
            text-align: center;
            margin-top: var(--margin);
        }

        button {
            width: 100%;
            margin-top: var(--margin);
            margin-bottom: var(--margin);
        }
    }

`;