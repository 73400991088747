import React, { 
    useRef, 
    useState 
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { parse } from "date-fns";

import getValidationsErrors from "../../../utils/getValidationsErros";
import { useAxiosErrors } from "../../../context/axiosErrors";
import api from "../../../services/api";
import { MemberData } from "../../../models";

import Input from "../../../components/Input";
import InputMask from "../../../components/InputMask";
import Button from "../../../components/Button";
import Select from "../../../components/Select";

import {
    Container
} from "./styles";

interface MemberCreateProps {
    handleMemberRegistered(email: string, password: string): void;
}

const MemberCreate: React.FC<MemberCreateProps> = ({handleMemberRegistered}) => {

    const [ buttonInProcess, setButtonInProcess] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);
    const { sendError } = useAxiosErrors();

    const handleSubmitForm: SubmitHandler<MemberData> = async (data) => {

        setButtonInProcess(true);

        try {
            const schema = Yup.object().shape({
                name: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com o nome"),
                email: Yup.string()
                    .max(50, "Máximo de 50 caracteres")
                    .email("E-mail inválido")
                    .required("Entre com o e-mail"),
                phoneNumber: Yup.string()
                    .required("Entre com o telefone"),
                gender: Yup.string()
                    .required("Selecione seu Genêro"),
                birthDate: Yup.string()
                    .required("Entre com sua data de nascimento"),
                password: Yup.string()
                    .min(6, "Mínimo de 6 caracteres")
                    .required("Entre com a senha"),
                confirmPassword: Yup.string()
                    .test(
                        "password",
                        "As senhas devem ser iguais",
                        value => {
                            return value === data.password
                        }
                    )
                    .required("Redigite sua senha")
            });
    
            await schema.validate(data, {
                abortEarly: false
            });

            data.gender = parseInt(data.gender.toString());
            data.birthDate = parse(
                data.birthDate.toString(),
                "dd/MM/yyyy",
                new Date()
            );

            await api.post("member/account", data);

            handleMemberRegistered(
                data.email, 
                data.password
            );
        }
        catch(err) {
            console.log(err);
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }
        
        setButtonInProcess(false);

    }

    return (
        <Container>
            <Form 
                ref={formRef} 
                onSubmit={handleSubmitForm}>

                <Input 
                    name="name" 
                    placeholder="Seu Nome Completo" 
                    autoFocus
                />
                
                <Input 
                    name="email" 
                    placeholder="E-mail" 
                />
                
                <InputMask 
                    name="phoneNumber" 
                    mask="(99) 9 9999-9999" 
                    placeholder="Telefone" 
                />

                <InputMask 
                    name="birthDate"
                    mask="99/99/9999"
                    placeholder="Data de Nascimento" 
                />

                <Select name="gender">
                    <option value="">Selecionar Sexo</option>
                    <option value="1">Masculino</option>
                    <option value="2">Feminino</option>
                </Select>
                
                <Input 
                    name="password" 
                    type="password" 
                    placeholder="Senha" 
                />
                
                <Input 
                    name="confirmPassword" 
                    type="password" 
                    placeholder="Redigite sua Senha" 
                />
                
                <Button 
                    styliest="success" 
                    inProcess={buttonInProcess}>
                    Finalizar Cadastro
                </Button>

            </Form>
        </Container>
    );
}

export default MemberCreate;