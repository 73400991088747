import React from "react";

import { AuthProvider } from "./auth";
import { ErrorsProvider } from "./errors";
import { AxiosErrorsProvider } from "./axiosErrors";

const AppContext: React.FC = ({children}) => {
    return (
        <AuthProvider>
            <ErrorsProvider>
                <AxiosErrorsProvider>
                    {children}
                </AxiosErrorsProvider>
            </ErrorsProvider>
        </AuthProvider>
    );
}

export default AppContext;