import React from "react";
import * as Fi from "react-icons/fi";

import {
    Container,
    ContainerContent,
    ButtonClose
} from "./styles";

export type ModalProps = {
    visible: boolean;
    size: "small" | "medium" | "big" | "max";
    handleClose(value: boolean): void;
}

export const Modal : React.FC<ModalProps> = ({visible, size, handleClose, ...rest}) => {

    return (
        <>
        { 
            visible &&

            <Container>
                <ContainerContent size={size}>
                    {rest.children}
                    <ButtonClose onClick={() => handleClose(false)}>
                        <Fi.FiX size={30} />
                    </ButtonClose>
                </ContainerContent>
            </Container>
        }
        </>
    );
};