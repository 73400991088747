import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from "react-toastify";

import { GlobalStyle } from "./assets/styles/global";
import Routes from './routes';
import AppContext from "./context";

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <AppContext>
      <Routes />
    </AppContext>
    <ToastContainer autoClose={2000} />
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
);
