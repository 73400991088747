import React from "react";

import {
    ButtonItem
} from "./styles"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    styliest: "success" | "danger" | "info" | "default";
    inProcess?: boolean;
}

const Button : React.FC<ButtonProps> = ({styliest, inProcess = false, children, ...rest}) => {
    return (
        <ButtonItem {...rest} styliest={styliest} disabled={inProcess}>
            {
                inProcess
                    ? <label>Um Instantinho!</label>
                    : children
            }
        </ButtonItem>
        
    );
}

export default Button;