import styled from "styled-components";

export const Container = styled.div`

`;

export const Content = styled.div`

    display: grid;
    grid-template-areas: left right;
    grid-template-columns: 25% 1fr;
    grid-template-rows: 1fr;

    margin-top: var(--margin-2x);

    .left {
        grid-area: "left";
    }

    .right {
        grid-area: "right";

        > b + p {
            margin-top: 20px;
        }
    }

`;