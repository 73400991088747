import React from "react";

import {
    Container
} from "./styles";

interface BadgeProps {
    styliest: 
        "success" | 
        "info" | 
        "warning" | 
        "danger"
}

const Badge: React.FC<BadgeProps> = ({ styliest, children}) => {
    return (
        <Container styliest={styliest}>
            {children}
        </Container>
    );
}

export default Badge;