import React, { useCallback } from "react";
import { toast } from "react-toastify";

import Button from "../../Button";

import { DiscountData } from "../../../models";
import api from "../../../services/api";
import { useAxiosErrors } from "../../../context/axiosErrors";

import { 
    Container
} from "./styles";

interface DiscountApproveProps {
    discount: DiscountData;
    setVisibility(value: boolean): void;
    handleApproved(): void;
}

const DiscountApprove: React.FC<DiscountApproveProps> = ({discount, setVisibility, handleApproved}) => {

    const { sendError } = useAxiosErrors();

    const handleApprove = useCallback(async () => {
        try {
            await api.put(
                `manager/discount/approve/${discount.id}`
            ).then(() => toast.success("Descontinho aprovado"));

            handleApproved();
        }
        catch(err) {
            sendError(err);
        }
    }, [discount, sendError, handleApproved]);

    return (
        <Container>
            <h2>Aprovar Descontinho</h2>
            <p>Tem certeza que deseja aprovar o descotinho <b>{discount.title}</b>?</p>
            <footer>
                
                <Button 
                    styliest="success"
                    onClick={() => handleApprove()}>
                    Aprovar
                </Button>

                <Button 
                    styliest="info"
                    onClick={() => setVisibility(false)}>
                    Voltar
                </Button>

            </footer>
        </Container>
    );
}

export default DiscountApprove;