import styled from "styled-components";

export const Container = styled.div`

    p{
        margin: 20px auto;
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        button {
            width: 100%;
            
            :first-child {
                margin-right: 10px;
            }
        }
    }

`;