import React, { useState, useCallback } from "react";
import * as Fi from "react-icons/fi";

import ListItem from "../../ListItem";
import Badge from "../../Badge";
import { Modal } from "../../Modal";
import DiscountUpdateByManager from "../UpdateByManager";
import DiscountUpdateByMember from "../UpdateByMember";
import DiscountApprove from "../Approve";

import { DiscountData, DiscountStatus } from "../../../models";

import {
    Container,
    HorizontalContent
} from "./styles";

interface DiscountListItemProps {
    discount: DiscountData;
    inManagerArea: boolean;
    updateList(): void;
}

const DiscountListItem = ({ discount, inManagerArea, updateList }: DiscountListItemProps) => {

    const DiscountUpdate = inManagerArea 
        ? DiscountUpdateByManager 
        : DiscountUpdateByMember;

    const [ showModalUpdate, setShowModalUpdate ] = useState<boolean>(false);
    const [ showModalApprove, setShowModalApprove ] = useState<boolean>(false);

    const handleDiscountUpdated = useCallback(() => { 
        updateList();
    }, [updateList]);

    const handleDiscountApproved = useCallback(() => {

    }, []);

    return (
        <Container>
            <ListItem key={discount.id}>
                <HorizontalContent>
                    <p>{discount.title}</p>

                    {
                        discount.status === DiscountStatus.InCreation
                            ? <Badge styliest="info">Em criação</Badge>
                            : discount.status === DiscountStatus.SendToReview
                                ? <Badge styliest="warning">Aguardando aprovação</Badge>
                                : discount.status === DiscountStatus.Aproved
                                    ? <Badge styliest="success">Aprovado</Badge>
                                    : <Badge styliest="danger">Reprovado</Badge>
                                
                    }
                </HorizontalContent>
                <menu>
                    <Fi.FiEdit 
                        size={20}
                        onClick={() => setShowModalUpdate(true)}
                    />
                    {
                        (
                            inManagerArea &&
                            discount.status === DiscountStatus.SendToReview
                        ) &&
                        <>
                            <Fi.FiCheck 
                                size={20}
                                onClick={() => setShowModalApprove(true)} />
                            <Fi.FiX size={20} />
                        </>
                    }
                    <Fi.FiTrash size={20} />
                </menu>
            </ListItem>
            <Modal
                visible={showModalUpdate}
                size="max"
                handleClose={setShowModalUpdate}
            >
                {
                    <DiscountUpdate
                        discount={discount} 
                        handleUpdated={handleDiscountUpdated} 
                        setVisibility={setShowModalUpdate} />                        
                }
            </Modal>
            {
                inManagerArea &&
                <Modal
                    visible={showModalApprove}
                    size="small"
                    handleClose={setShowModalApprove}
                >
                    <DiscountApprove
                        discount={discount}
                        setVisibility={setShowModalApprove}
                        handleApproved={handleDiscountApproved}
                    />
                </Modal>
            }
        </Container>
    );
}

export default DiscountListItem;