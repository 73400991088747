import styled from "styled-components";

export const Container = styled.main`

`;

export const HorizontalContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`