import React, {
    useState,
    useRef,
    useCallback, 
    useEffect
} from "react";
import { 
    FiPlus,
    FiTrash 
} from "react-icons/fi";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";

import Select from "../../components/Select";
import Button from "../../components/Button";
import { Modal } from "../../components/Modal";

import api from "../../services/api";
import { useAxiosErrors } from "../../context/axiosErrors";
import { DiscountDayData, DiscountDayPeriodData } from "../../models";
import getValidationsErrors from "../../utils/getValidationsErros";

import {
    Container,
    DiscountDayName,
    DiscountDayPeriods,
    FormDiscountDayPeriod,
    DiscountDayPeriodList
} from "./styles";

interface DiscountDayProps {
    dayOfWeek: number,
    discountId: string,
    inManagerArea: boolean;
}

const DiscountDay = ({dayOfWeek, discountId, inManagerArea }: DiscountDayProps) => {

    const area = inManagerArea ? "manager" : "member";

    const [ discountDay, setDiscountDay ] = useState<DiscountDayData>({} as DiscountDayData);
    const [ selected, setSelected ] = useState<boolean>(false);
    const [ showModalAdd , setShowModalAdd ] = useState<boolean>(false);
    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);
    const [ discountDayPeriods, setDiscountDayPeriods ] = useState<DiscountDayPeriodData[]>([]);

    const formAddDiscountDayPeriodRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadDiscountDay() {        
        await api
            .get(`${area}/discountday/${discountId}/${dayOfWeek}`)
            .then(response => {
                setDiscountDay(response.data);
                
                if(response.data) {
                    setSelected(true);
                }
            })
            .catch(err => sendError(err));
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadDiscountDayPeriods()
    {
        await api.get(`${area}/discountdayperiod/bydiscountday/${discountDay.id}`)
            .then(response => setDiscountDayPeriods(response.data))
            .catch(err => sendError(err));
    }
    
    useEffect(() => {
        loadDiscountDay();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    useEffect(() => {
        if(discountDay.id) {
            loadDiscountDayPeriods();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountDay]);

    const handleAddDiscountDay = useCallback(async () => {
        try {
            await api.post(
                `${area}/discountday`,
                {
                    discountId,
                    dayOfWeek
                }
            );

            loadDiscountDay();            
            setSelected(true);
        }
        catch(err) {
            sendError(err);
        }
    }, [discountId, area, dayOfWeek, loadDiscountDay, sendError]);

    const handleRemoveDiscountDay = useCallback(async () => {
        try {
            await api.delete(
                `${area}/discountday/${discountDay?.id}`
            );

            setSelected(false);
            setDiscountDayPeriods([]);
        }
        catch(err) {
            sendError(err);
        }
    }, [discountDay, area, sendError]);

    const handleAddDiscountDayPeriodSubmitForm: SubmitHandler<DiscountDayPeriodData> = async data => {

        setButtonInProcess(true);

        try {

            const schema = Yup.object().shape({
                hourStart: Yup.number()
                    .required("Obrigatório"),
                hourFinish: Yup.number()
                    .required("Obrigatório")
            });
    
            await schema.validate(data, {
                abortEarly: false
            });

            data.discountDayId = discountDay.id;
            data.hourStart = parseInt(data.hourStart.toString());
            data.hourFinish = parseInt(data.hourFinish.toString());

            await api
                .post(`${area}/discountdayperiod`, data);

            setShowModalAdd(false);     
            loadDiscountDayPeriods();       
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formAddDiscountDayPeriodRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setButtonInProcess(false);

    }

    const handleRemoveDiscountDayPeriod = useCallback(async id => {
        try {
            await api.delete(`${area}/discountdayperiod/${id}`);

            setDiscountDayPeriods(
                discountDayPeriods.filter(d => d.id !== id )
            );
        }
        catch(err) {
            sendError(err);
        }
    }, [discountDayPeriods, area, sendError]);

    return (
        <Container>
            <DiscountDayName                
                selected={selected}
                onClick={() => selected ? handleRemoveDiscountDay() : handleAddDiscountDay() }
            >
                {
                    dayOfWeek === 0
                        ? "DOM"
                        : dayOfWeek === 1
                            ? "SEG"
                            : dayOfWeek === 2
                                ? "TER"
                                : dayOfWeek === 3
                                    ? "QUA"
                                    : dayOfWeek === 4
                                        ? "QUI"
                                        : dayOfWeek === 5
                                            ? "SEX"
                                            : "SAB"
                }
            </DiscountDayName>
            {
                selected &&
                <DiscountDayPeriods>
                    <small onClick={() => setShowModalAdd(true)}>
                        <FiPlus size={15} /> Horário
                    </small>
                </DiscountDayPeriods>
            }
            {
                discountDayPeriods &&    
                <DiscountDayPeriodList>
                    {
                        discountDayPeriods.map(discountDayPeriod => (
                            <li key={discountDayPeriod.id}>
                                <small>    
                                    De {`${discountDayPeriod.hourStart}:00`}<br />
                                    às {`${discountDayPeriod.hourFinish}:59`}
                                </small>
                                <br />
                                <FiTrash 
                                    size={15} 
                                    onClick={() => handleRemoveDiscountDayPeriod(discountDayPeriod.id)} 
                                />
                            </li>
                        ))
                    }
                </DiscountDayPeriodList>
            }
            <Modal
                visible={showModalAdd}
                size="small"
                handleClose={setShowModalAdd}
            >
                <FormDiscountDayPeriod 
                    ref={formAddDiscountDayPeriodRef} 
                    onSubmit={handleAddDiscountDayPeriodSubmitForm}>    
                    <h2>Adicionar Horário</h2>
                    <section>
                        <Select label="Hora de início" name="hourStart">
                            <option value={0}>00:00</option>
                            <option value={1}>01:00</option>
                            <option value={2}>02:00</option>
                            <option value={3}>03:00</option>
                            <option value={4}>04:00</option>
                            <option value={5}>05:00</option>
                            <option value={6}>06:00</option>
                            <option value={7}>07:00</option>
                            <option value={8}>08:00</option>
                            <option value={9}>09:00</option>
                            <option value={10}>10:00</option>
                            <option value={11}>11:00</option>
                            <option value={12}>12:00</option>
                            <option value={13}>13:00</option>
                            <option value={14}>14:00</option>
                            <option value={15}>15:00</option>
                            <option value={16}>16:00</option>
                            <option value={17}>17:00</option>
                            <option value={18}>18:00</option>
                            <option value={19}>19:00</option>
                            <option value={20}>20:00</option>
                            <option value={21}>21:00</option>
                            <option value={22}>22:00</option>
                            <option value={23}>23:00</option>
                        </Select>
                        <Select label="Hora de término" name="hourFinish">
                            <option value={0}>00:59</option>
                            <option value={1}>01:59</option>
                            <option value={2}>02:59</option>
                            <option value={3}>03:59</option>
                            <option value={4}>04:59</option>
                            <option value={5}>05:59</option>
                            <option value={6}>06:59</option>
                            <option value={7}>07:59</option>
                            <option value={8}>08:59</option>
                            <option value={9}>09:59</option>
                            <option value={10}>10:59</option>
                            <option value={11}>11:59</option>
                            <option value={12}>12:59</option>
                            <option value={13}>13:59</option>
                            <option value={14}>14:59</option>
                            <option value={15}>15:59</option>
                            <option value={16}>16:59</option>
                            <option value={17}>17:59</option>
                            <option value={18}>18:59</option>
                            <option value={19}>19:59</option>
                            <option value={20}>20:59</option>
                            <option value={21}>21:59</option>
                            <option value={22}>22:59</option>
                            <option value={23}>23:59</option>
                        </Select>
                    </section>
                    <Button 
                        type="submit" 
                        styliest="success"
                        inProcess={buttonInProcess}>
                        Adicionar
                    </Button>
                    <small onClick={() => setShowModalAdd(false)}>Voltar</small>
                </FormDiscountDayPeriod>
            </Modal>
        </Container>
    );
}

export default DiscountDay;