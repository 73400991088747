import React, { useState, useEffect } from "react";

import api from "../../../services/api";

import Header from "../../Header";
import List from "../../List";
import ListItem from "../../ListItem";
import Skeleton from "../../Skeleton";

import { 
    CustomerData,
    CustomerDiscountData
} from "../../../models";

import {
    Container,
    Content
} from "./styles";

interface CustomerDetailsProps {
    customer: CustomerData;
}

const CustomerDetails = ({ customer }: CustomerDetailsProps) => {

    const [ customerDiscounts, setCustomerDiscounts ] = useState<CustomerDiscountData[]>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    async function loadCustomerDiscounts(): Promise<void> {
        await api.get(`manager/customerDiscount/allbycustomer/${customer.id}`).then(response => {
            setCustomerDiscounts(response.data.sort((a: CustomerDiscountData, b: CustomerDiscountData) => {
                return new Date(b.created).getTime() - new Date(a.created).getTime()
            }) as CustomerDiscountData[]);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadCustomerDiscounts();
        //eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Header>
                <h2>{customer.name}</h2>
            </Header>
            <Content>
                <div className="left">
                    <p><b>Cpf:</b> {customer.cpf ?? "Não informado"}</p>
                    <p><b>E-mail:</b> {customer.email}</p>
                    <p><b>Telefone:</b> {customer.phoneNumber}</p>
                    <p><b>Sexo:</b> {customer.gender === 1 ? "Masculino" : "Feminino"}</p>
                </div>
                <div className="right">
                    <b>Descontinhos Gerados</b>
                    { 
                        isLoading ? <Skeleton /> :
                        customerDiscounts === null || customerDiscounts?.length === 0
                            ? <p>Usuário ainda não gerou descontinhos</p> 
                            : <>
                                <List>
                                    { customerDiscounts?.map((customerDiscount: CustomerDiscountData) => (
                                        <ListItem key={customerDiscount.id}>
                                            <div>
                                                <b>{customerDiscount.discountTitle}</b>
                                                <p>{customerDiscount.discountCompanyLegalPersonCorporateName}</p>
                                            </div>
                                            {customerDiscount.code}
                                            <div>
                                                <b>{customerDiscount.status === 0 ? "Ativo" : customerDiscount.status === 1 ? "Expirado" : "Utilizado"}</b>
                                                <p>{new Date(customerDiscount.created).toLocaleString()}</p>
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                    }
                </div>
            </Content>
        </Container>
    );
}

export default CustomerDetails;