import React, { 
    useRef,
    useState 
} from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { parse } from "date-fns";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../../services/api";
import getValidationsErrors from "../../../utils/getValidationsErros";
import { useAuth } from "../../../context/auth";
import { useAxiosErrors } from "../../../context/axiosErrors";

import { MemberData } from "../../../models";

import Input from "../../Input";
import InputMask from "../../InputMask";
import Button from "../../Button";
import Select from "../../Select";

import {
    Container
} from "./styles";

interface MemberUpdateProps 
{
    member: MemberData;
    handleMemberUpdated(result: boolean): any;
    setVisibility(value: boolean): any;
    isAccountMember?: boolean;
}

const MemberUpdate : React.FC<MemberUpdateProps> = ({member, handleMemberUpdated, setVisibility, isAccountMember = false}) => {

    const [ updateInProcess, setUdpateInProcess ] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();
    const { updateMember } = useAuth();

    const handleSubmit: SubmitHandler<MemberData> = async (data) => {

        setUdpateInProcess(true);

        try {
            const schema = Yup.object().shape({
                cpf: Yup.string()
                    .required("Entre com seu CPF"),
                name: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com seu nome"),
                email: Yup.string()
                    .max(50, "Máximo de 50 caracteres")
                    .email("E-mail inválido")
                    .required("Entre com seu e-mail"),
                phoneNumber: Yup.string()
                    .required("Entre com seu telefone"),
                gender: Yup.string()
                    .required("Selecione seu genêro"),
                birthDate: Yup.string()
                    .required("Entre com sua data de nascimento")
            });

            await schema.validate(data, {
                abortEarly: false
            });

            data.gender = parseInt(data.gender.toString());
            data.birthDate = parse(
                data.birthDate.toString(),
                "dd/MM/yyyy",
                new Date()
            );

            if(isAccountMember) {                
                await api.put('accountmember', data);  
            }
            else {
                await api.put(`member/${data.id}`, {
                    cpf: data.cpf,
                    name: data.name,
                    email: data.email,
                    phoneNumber: data.phoneNumber
                });    
            }   
            
            toast.success("Perfil atualizado");

            updateMember();
            handleMemberUpdated(true);
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setUdpateInProcess(false);
    }

    return (
        <Container>
            <h2>Atualizar seu Perfil</h2>

            <Form ref={formRef} initialData={member} onSubmit={handleSubmit}>
                <input 
                    type="hidden" 
                    name="id" />
                <InputMask
                    type="text" 
                    name="cpf" 
                    placeholder="CPF" 
                    mask={"999.999.999-99"}
                />
                <Input 
                    type="text" 
                    name="name" 
                    placeholder="Nome" 
                    maxLength={100}
                />
                <Input 
                    type="email" 
                    name="email" 
                    placeholder="E-mail" 
                />
                <Input 
                    type="text" 
                    name="phoneNumber" 
                    placeholder="Telefone" 
                />
                <Select name="gender">
                    <option value="1">Masculino</option>
                    <option value="2">Feminino</option>
                </Select>
                <InputMask
                    name="birthDate"
                    placeholder="Data de Nascimento"
                    mask="99/99/9999"
                    defaultValue={member.birthDate && new Date(member.birthDate).toLocaleDateString('pt-br')}
                />
                <Button 
                    styliest="success" 
                    inProcess={updateInProcess}
                    type="submit">
                    Finalizar
                </Button>
            </Form>
            
            <div onClick={() => setVisibility(false)}>
                Voltar
            </div>
        </Container>
    )
}

export default MemberUpdate;   