import styled from "styled-components";

export const Container = styled.main`

    text-align: center;

    h3 { 
        margin-bottom: var(--margin);
    }

    input { 
        margin-top: var(--margin);
        text-align: center;
    }

    button {
        margin-bottom: var(--margin);
        width: 100%;
    }
    
    small { 
        cursor: pointer;
    }

`;