import styled from "styled-components";

export const Container = styled.main`

    background-color: #000;
    color: var(--color-white-adjusted);

    padding: 6rem;

    img {
        width: 30vw;
        margin-bottom: var(--margin-2x);
        cursor: pointer;
    }

    h1 {
        margin: var(--margin) 0;
        color: var(--color-gray-light);
    }

    b { 
        margin: var(--margin-2x) 0px;
        display: inline-block;
    }

    p { 
        margin-bottom: var(--margin);
    }

    ul {
        padding-left: var(--margin-2x);
        margin: var(--margin-2x) 0px;
    }

`;