import React, { 
    useState, 
    useContext, 
    createContext, 
    useCallback } 
from "react";

import ModalErrors from "../components/ModalErrors";

interface ErrorsContextData { 
    sendMessages(messages: string[]): void;
    fadeModal(): void;
}

const ErrorsContext = createContext<ErrorsContextData>({} as ErrorsContextData);

const ErrorsProvider: React.FC = ({children}) => {

    const [ visible, setVisible ] = useState<boolean>(false);
    const [ messages, setMessages ] = useState<string[]>([]);

    const sendMessages = useCallback((messages: string[]) => {
        setVisible(true);
        setMessages(messages);
    }, []);

    const fadeModal = useCallback(() => {
        setVisible(false);
        setMessages([]);
    }, []);

    return (
        <ErrorsContext.Provider value={{
            sendMessages,
            fadeModal
        }}>
            {children}
            <ModalErrors 
                messages={messages}
                size="small"
                visible={visible}
                fadeModal={fadeModal}
                handleClose={fadeModal}
            />
        </ErrorsContext.Provider>
    )

}

function useErrors(): ErrorsContextData {
    const context = useContext(ErrorsContext);

    if(!context) {
        throw new Error("There is no context created");
    }

    return context;
}

export { ErrorsProvider, useErrors }