import React, {
    useState,
    useEffect
} from "react";

import api from "../../../services/api";
import { useAxiosErrors } from "../../../context/axiosErrors";
import { DiscountData } from "../../../models";

import List from "../../List";
import DiscountListItem from "../ListItem"

import {
    Container
} from "./styles";

const DiscountList : React.FC = () => {

    const [ discounts, setDiscounts ] = useState<DiscountData[] | null>(null);
    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadDiscounts(): Promise<void> {
        await api
            .get("discount")
            .then(response => setDiscounts(response.data))
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadDiscounts();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            {
                discounts ?
                <List>
                    {discounts.map(discount => (
                        <DiscountListItem
                            key={discount.id}
                            discount={discount}
                            inManagerArea={true}
                            updateList={loadDiscounts}
                        />
                    ))}
                </List> :
                <p>Nenhum descontinho encontrado</p>
            }
        </Container>
    );
}

export default DiscountList;