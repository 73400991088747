import React, {
    useState,
    useCallback,
    useEffect,
    useRef
} from "react";
import * as Fi from "react-icons/fi";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";
import { get } from "../../../services/promises";

import { useAxiosErrors } from "../../../context/axiosErrors";
import { CategoryData } from "../../../models";

import CategoryCreate from "../../Category/Create";
import CategoryListItem from "../../Category/ListItem";
import List from "../../List";
import InputFile from "../../InputFile";
import { Modal } from "../../Modal";
import Avatar from "../../Avatar";

import {
    Container
} from "./styles";

interface CategoryDetailsProps {
    category: CategoryData;
    setVisibility(value: boolean) : void;
}

interface CategoryImageData {
    file: File;
}

const CategoryDetails : React.FC<CategoryDetailsProps> = ({ category, setVisibility }) => {

    const [ categories, setCategories ] = useState<CategoryData[] | null>(null);
    const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ imageInProcess, setImageInProcess ] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line
    async function loadCategories() {
        get(`manager/category/${category.id}`)
            .then(response => {
                setCategories(response as CategoryData[]);
                setIsLoading(false);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadCategories(); 
        //eslint-disable-next-line
    }, []);

    const handleCategoryRegistered = useCallback(() => {
        loadCategories();
        setShowCreateModal(false);
    }, [loadCategories]);

    const handleSubmitFormUpdateImage: SubmitHandler<CategoryImageData> = async (data) => {
        setImageInProcess(true);

        const file = data.file;

        const formData = new FormData();
        formData.append("file", file);
        
        await api.put(
            `manager/category/${category.id}/updateImage`, 
            formData
        );
        
        setImageInProcess(false);
    }

    return (
        <Container>
            <header>
                <div>
                    <Avatar 
                        image={category.imageUriHigh} 
                        inProcess={imageInProcess}
                        isSmall={true}
                    />
                    <h2>
                        {category.title}
                    </h2>
                </div>
                <small onClick={() => setVisibility(false)}>Voltar</small>
            </header>
            <section>
                <menu>
                    <small>
                        <Form 
                            ref={formRef} 
                            onSubmit={handleSubmitFormUpdateImage}>
                            <InputFile 
                                name="file" 
                                label="Alterar Capa"
                                onChange={() => formRef.current?.submitForm()}
                            />
                        </Form>
                    </small>
                </menu>
                <div>
                    <header>
                        <b>Subcategorias</b>
                        <small onClick={() => setShowCreateModal(true)}>
                            <Fi.FiPlus size={20} />
                            Adicionar
                        </small>
                    </header>
                    <List>                            
                        {
                            isLoading ? <p>Carregando...</p> : 
                            categories && categories.length > 0 ?
                            categories.map(category => (
                                <CategoryListItem 
                                    key={category.id}
                                    category={category}
                                    handleCategoryUpdated={() => loadCategories()} />
                            )) :
                            <p>Não há subcategorias registradas</p>
                        }
                    </List>
                </div>
            </section>
            <Modal 
                visible={showCreateModal} 
                size="small"
                handleClose={setShowCreateModal}>
                <CategoryCreate 
                    categoryId={category.id}
                    setVisibility={setShowCreateModal}
                    handleCategoryRegistered={handleCategoryRegistered}
                />
            </Modal>
        </Container>
    );
}

export default CategoryDetails;