import styled from "styled-components";

export const Container = styled.main`

    & > header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        b {
            cursor: pointer;
        }
    }

    section {
        display: flex;
        flex-direction: row;
        margin-top: var(--margin-2x);
        gap: var(--margin-2x);

        nav {
            min-width: 15%;
            text-align: center;
            
            p {
                margin-top: var(--margin-2x);
            }
        }

        article {
            width: 100%;

            menu {

                margin-top: var(--margin-2x);

                ul {
                    display: flex;
                    flex-direction: row;
                    list-style: none;
                    gap: var(--margin);

                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        background: var(--color-white-adjusted);
                        width: auto;
                        padding: var(--padding-inner);
                        border: 2px var(--color-white-adjusted) solid;
                        border-radius: var(--border-radius);
                        color: #292c3a;
                        cursor: pointer;
                        transition: 
                            background .2s,
                            color .2s;

                        :hover {
                            background-color: transparent;
                            color: var(--color-white-adjusted);
                        }

                        svg {
                            margin-right: var(--margin);
                        }
                    }
                }
            }
        }
    }
    
    @media (max-width: 768px) {
        section {
            flex-direction: column;
        }
    }

`;