import styled from "styled-components";

export const Container = styled.main`

    color: var(--color-white-adjusted);
    text-align: center;

    h1 { 
        margin-bottom: 2rem;
        color: var(--color-white-adjusted);
    }

    ul {
        display: flex;
        list-style: none;

        li {
            background: var(--rgba-black-ligther);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 4rem;
            margin: 1rem;
            border-radius: 1rem;
            cursor: pointer;
            transition: transform .2s;
            min-width: 20rem;
            max-width: 100%;

            :hover{
                transform: scale(1.2);
                box-shadow: 0px 0px 20px var(--rgba-black-ligther)
            }

            svg {
                margin-bottom: 20px;
            }
        }
    }

    @media (max-width: 768px) {
        h1 {
            width: 100%;     
            overflow-wrap: break-word;
        }

        ul {
            flex-wrap: wrap;
            flex: 1;
            
            li {
                width: 100%;
                word-wrap: break-word;
            }
        }
    }
`;