import React from "react";
import { useHistory } from "react-router-dom";

import DrawRegisterCompany from "../../../assets/images/draw_register_company.svg";
import DrawRegisterCustomer from "../../../assets/images/draw_register_customer.svg";

import { 
    Container 
} from "./styles";

const AccountRegister: React.FC = () => {

    const navigation = useHistory();

    return (
        <Container>
            <h2>Por onde quer Começar?</h2>
            <menu>
                <section onClick={() => navigation.push("RegisterMember")}>
                    <img src={DrawRegisterCompany} alt="Cadastrando Estabelecimento" />
                    <p>Cadastrando meu Estabelecimento</p>
                </section>
                <section>
                    <img src={DrawRegisterCustomer} alt="Utilizando o Descontinho" />
                    <p>Utilizando o Descontinho</p>
                </section>
            </menu>
        </Container>
    );
}

export default AccountRegister;