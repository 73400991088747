import React, { useState, useCallback } from "react";
import * as Fi from "react-icons/fi";

import ListItem from "../../ListItem";
import { Modal } from "../../Modal";
import CategoryUpdate from "../Update";
import CategoryDetails from "../Details";
import CategoryRemove from "../Remove";

import { CategoryData } from "../../../models";

import {
    Container
} from "./styles";

interface CategoryListItemProps {
    category: CategoryData;
    handleCategoryUpdated(): void;
}

const CategoryListItem = ({ category, handleCategoryUpdated }: CategoryListItemProps ) => {
    
    const [ showUpdateModal, setShowUpdateModal ] = useState<boolean>(false);
    const [ showDetailsModal, setShowDetailsModal ] = useState<boolean>(false);
    const [ showRemoveModal, setShowRemoveModal ] = useState<boolean>(false);

    const handleCategoryIsUpdated = useCallback(() => {
        handleCategoryUpdated();
        setShowUpdateModal(false);
    }, [handleCategoryUpdated]); 

    return (
        <Container>
            <ListItem>
                <label>{category.title}</label>
                <menu>
                    {
                        !category.categoryId &&
                        <Fi.FiGrid 
                            size={20}
                            onClick={() => setShowDetailsModal(true)} 
                        />
                    }
                    <Fi.FiEdit 
                        size={20} 
                        onClick={() => setShowUpdateModal(true)} />
                    <Fi.FiTrash 
                        size={20}
                        onClick={() => setShowRemoveModal(true)} />
                </menu>
            </ListItem>
            <Modal 
                visible={showUpdateModal} 
                size="small"
                handleClose={setShowUpdateModal}>
                <CategoryUpdate 
                    category={category}
                    setVisibility={setShowUpdateModal}
                    handleCategoryUpdated={handleCategoryIsUpdated}
                />
            </Modal>
            <Modal 
                visible={showDetailsModal} 
                size="medium"
                handleClose={setShowDetailsModal}>
                <CategoryDetails
                    category={category}
                    setVisibility={setShowDetailsModal}
                />
            </Modal>
            <Modal 
                visible={showRemoveModal} 
                size="small"
                handleClose={setShowRemoveModal}>
                <CategoryRemove 
                    category={category}
                    setVisibility={setShowRemoveModal} />
            </Modal>
        </Container>
    );
}

export default CategoryListItem;