import React, { useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationsErros";

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import { useAuth } from "../../../context/auth";
import { useErrors } from "../../../context/errors";

import logo from "../../../assets/images/logo_lighter.svg";

import {
    Container
} from "./styles";

interface FormData {
    email: string;
    password: string;
}

const AccountLogon: React.FC = () => {

    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);

    const history = useHistory();

    const { signIn } = useAuth();
    const { sendMessages } = useErrors();

    const handleLogon: SubmitHandler<FormData> = async (data) => {

        setButtonInProcess(true);

        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string()
                    .email("E-mail inválido")
                    .required("Digite seu e-mail"),
                password: Yup.string()
                    .min(6, "Minimo de 6 caracteres")
                    .required("Digite sua senha")
            });

            await schema.validate(data, {
                abortEarly: false
            });

            try {
                await signIn({
                    email: data.email, 
                    password: data.password
                });

                history.push("SelectArea");
            }
            catch(err) {
                sendMessages(err.response.data.errors.Messages);
            }


        } catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
        }

        setButtonInProcess(false);
    };

    return (
        <Container>
            <section>
                <img src={logo} alt="Descontinho Club" />
                <Form ref={formRef} onSubmit={handleLogon}>
                    
                    <Input 
                        name="email" 
                        placeholder="E-mail"
                        autoComplete="off" 
                        autoFocus />

                    <Input 
                        name="password" 
                        type="password" 
                        placeholder="Senha" />
                    
                    <Button 
                        type="submit" 
                        styliest="success" 
                        inProcess={buttonInProcess}>
                        Acessar
                    </Button>

                </Form>
                <Link to="ForgotPassword">Recuperar Senha</Link>
            </section>
            <footer>
                <Button 
                    styliest="default"
                    onClick={() => history.push("RegisterMember")}
                >
                    Criar Conta
                </Button>
            </footer>
        </Container>
    );
}

export default AccountLogon;