import React, {
    useCallback,
    useEffect,
    useState,
    useRef
} from "react";
import * as Fi from "react-icons/fi";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";

import { get } from "../../../../services/promises";

import { useAxiosErrors } from "../../../../context/axiosErrors";

import { Modal } from "../../../../components/Modal";
import Input from "../../../../components/Input";
import List from "../../../../components/List";
import ListItem from "../../../../components/ListItem";
import CustomerCreate from "../../../../components/Customer/Create";
import CustomerDetails from "../../../../components/Customer/Details";
import Skeleton from "../../../../components/Skeleton";

import { CustomerData } from "../../../../models";

import {
    Container
} from "./styles";

interface CustomerFilterData {
    filter: string;
}

const Customer : React.FC = () => {

    const formRef = useRef<FormHandles>(null);

    const [ customers, setCustomers ] = useState<CustomerData[] | null>(null);
    const [ customerSelected, setCustomerSelected ] = useState<CustomerData | null>(null);
    const [ showModalCreate, setShowModalCreate ] = useState<boolean>(false);
    const [ showModalDetails, setShowModalDetails ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadCustomers() {
        get("manager/customer")
            .then(response => {
                setCustomers(response as CustomerData[]);
                setIsLoading(false);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadCustomers();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(customerSelected != null) {
            setShowModalDetails(true);
        }
    }, [customerSelected]);

    const handleFilter: SubmitHandler<CustomerFilterData> = (data) => {
        
    } 

    const handleRegistered = useCallback(() => {
        setShowModalCreate(false);
        loadCustomers();
    }, [loadCustomers]);

    return (
        <Container>
            <header>
                <h1>Assinantes</h1>
                <Form ref={formRef} onSubmit={handleFilter}>
                    <Input 
                        name="filter" 
                        placeholder="Nome ou e-mail do Assinante" 
                        autoComplete="off"
                    />
                </Form>
            </header>
            <List>
                {
                    isLoading ? <Skeleton /> : 
                    customers && customers.length > 0 ?
                        customers.map(customer => (
                            <ListItem key={customer.id}>
                                <label onClick={() => setCustomerSelected(customer)}>
                                    {customer.name}
                                </label>
                                {customer.email}
                                <menu>
                                    <Fi.FiEye 
                                        size={20}
                                        onClick={() => setCustomerSelected(customer)} />
                                </menu>
                            </ListItem>
                        )) :
                        <p>Nenhum assinante encontrado</p>
                }
            </List>
            <Modal 
                visible={showModalCreate} 
                size="medium"
                handleClose={setShowModalCreate}>
                <CustomerCreate 
                    setVisibility={setShowModalCreate}
                    handleCustomerRegistered={handleRegistered} />
            </Modal>
            <Modal 
                visible={showModalDetails} 
                size="big"
                handleClose={setShowModalDetails}>
                <CustomerDetails 
                    customer={customerSelected as CustomerData} />
            </Modal>
        </Container>
    );
}

export default Customer;