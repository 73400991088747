import React, { useCallback, useState } from "react";

import api from "../../../services/api";
import { useAxiosErrors } from "../../../context/axiosErrors";

import Header from "../../Header";
import Button from "../../Button";

import {
    Container
} from "./styles";

import { 
    DiscountData
} from "../../../models";

interface DiscountRemoveProps {
    discount: DiscountData;
    setVisibility(value: boolean): void;
    handleRemoved(): void;
}

const DiscountRemove = ({ discount, setVisibility, handleRemoved}: DiscountRemoveProps) => {

    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);
    
    const { sendError } = useAxiosErrors();

    const handleRemoveDiscount = useCallback(async () => {
        setButtonInProcess(true);

        await api.delete(`member/discount/${discount.id}`)
            .then(() => {
                handleRemoved();
            })
            .catch(err => {
                console.log(err);
                sendError(err);
            });

        setButtonInProcess(false);
    }, [discount, handleRemoved, sendError]);

    return (
        <Container>
            <Header>
                <h2>Remover Descontinho</h2>
            </Header>
            <p>Tem certeza que seja remover o descontinho <b>{discount.title}</b></p>
            <footer>
                <Button 
                    styliest="danger"
                    onClick={handleRemoveDiscount}
                    inProcess={buttonInProcess}>
                    Sim
                </Button>
                <Button 
                    styliest="info" 
                    onClick={() => setVisibility(false)}>
                    Não
                </Button>
            </footer>
        </Container>
    );
}

export default DiscountRemove;