import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/images/logo_full_landscape_text_white.svg';

import { 
    Container
} from './styles';

const PrivacyPolicy = () => {
    var history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <img src={Logo} onClick={() => history.goBack()} alt="Descontinho Club" />
            <h1>POLÍTICA DE PRIVACIDADE</h1>

            <p>A Política de Privacidade foi elaborada para reafirmar nosso compromisso com a segurança e a privacidade das informações que coletamos de nossos usuários no site www.descontinho.club e aplicativo móvel Descontinho Club. O Descontinho Club sempre atualizará as suas políticas de privacidade para aprimorar os seus serviços e melhor atender os usuários cadastrados. As diretrizes da Lei Geral de Proteção de Dados (Lei nº 17.709/2018), que dispõe sobre o tratamento de dados pessoais, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural, são ordenadoras da Política de Privacidade do Descontinho Club. </p>
            <p>Esta política está sujeita a constantes melhorias e aprimoramentos. Caso o Descontinho Club, em algum momento, promova mudança substancial na maneira de usar as informações pessoais coletadas, as novas condições de privacidade e segurança da informação serão informadas ao público em geral, mediante anúncio em destaque em nossa página principal; e aos usuários cadastrados, mediante comunicação eletrônica.</p>

            <ul>
                <li>Quando você realiza seu cadastro no aplicativo móvel Descontinho Club, coletamos as informações pessoais que você nos fornece, tais como seu nome, e-mail, telefone, data de nascimento e endereço. Todos esses dados serão coletados através da sua prévia autorização, não violando sua intimidade de forma alguma e, esporadicamente, fazendo verificações para checar a sua real identidade, visando preservar a segurança das suas informações.</li>
                <li>Se pedirmos suas informações pessoais por uma razão secundária, como marketing, vamos pedir seu consentimento, ou te dar a oportunidade de dizer não.</li>
                <li>Depois de fornecer seus dados, você pode retirar o seu consentimento quando quiser através do contato@descontinho.club.</li>
                <li>Podemos divulgar suas informações pessoais se formos obrigados por lei a fazê-lo ou se você violar nossos Termos de Uso.</li>
                <li>No geral, nossos fornecedores irão coletar, usar e divulgar suas informações apenas na medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.</li>
                <li>Certos prestadores de serviços terceirizados, tais como gateways de pagamento e outros processadores de transações de pagamento, têm suas próprias políticas de privacidade em relação à informação que somos obrigados a fornecer para eles sobre transações relacionadas a compras. Para esses fornecedores, recomendamos que você leia suas políticas de privacidade para que possa entender de que maneira suas informações pessoais serão usadas.</li>
                <li>Uma vez que sair do nosso site ou aplicativo e for redirecionado para um aplicativo ou site de terceiros, você não será mais regido por essa Política de privacidade ou pelos Termos de serviço do nosso site.</li>
                <li>Quando o usuário clicar em links de terceiros, eles podem lhe direcionar para fora da nossa plataforma. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as políticas de privacidade deles.</li>
                <li>Para proteger suas informações pessoais, tomamos precauções e seguimos as melhores práticas da indústria para nos certificar que elas não sejam perdidas, usurpadas, acessadas, divulgadas, alteradas ou destruídas.</li>
                <li>Se você nos fornecer as suas informações de cartão de crédito, elas serão criptografadas usando a tecnologia "secure socket layer" (SSL) e armazenadas com criptografia AES-256. Embora nenhum método de transmissão pela Internet ou armazenamento eletrônico seja 100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos medidas adicionais aceitas pelos padrões da indústria.</li>
                <li>Reservamos o direito de modificar essa política de privacidade a qualquer momento. Portanto, por favor, leia-a com frequência. As alterações e esclarecimentos surtem efeito imediatamente após serem publicadas no site. </li>
            </ul>
        </Container>
    );
}

export default PrivacyPolicy;