import { ValidationError } from "yup";

interface ValidationErrors {
    [Key: string]: string;
}

function getValidationsErrors (err: ValidationError) : ValidationErrors {
    const validationErrors: ValidationErrors = {};
                
    err.inner.forEach(error  => {            
        validationErrors[error.path] = error.message;
    });

    return validationErrors;
}

export default getValidationsErrors;