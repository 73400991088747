import styled, { css } from "styled-components";

interface ButtonItemStype {
    styliest: 
        "success" | 
        "danger" | 
        "info" | 
        "warning" |
        "default";
}

export const ButtonItem = styled.button<ButtonItemStype>`

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 2rem;
    border-radius: var(--border-radius);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: opacity .2s;
    color: var(--color-white-adjusted);
    border: none;

    ${
        props =>
            props.styliest === "default" ?
                css`
                    background: transparent;
                    border: solid 0.3rem var(--color-white-adjusted);
                ` :
            props.styliest === "success" ?
                css`                
                    background: var(--color-success);
                    color: var(--color-black-ligther);
                ` :
            props.styliest === "danger" ?
                css`                
                    background: var(--color-danger);
                ` :
            props.styliest === "warning" ?
                css`                
                    background: var(--color-warning);
                ` :
                css`
                    background: var(--color-info);
                `
    }

    :hover {
        opacity: .9;    
    }

    :disabled {
        opacity: .8;
    }
`;