import styled from "styled-components";

export const Container = styled.div`

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--margin-2x);
    }

`;

export const HorizontalContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

export const ContainerCustomerWithoutImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: -4rem;

    img {
        width: 30vw;
        margin-bottom: var(--margin-2x);
    }

    p {
        cursor: pointer;
    }
`;