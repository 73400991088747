import styled from "styled-components";

export const Container = styled.main`

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    section {
        margin-top: var(--margin-2x);
    }

`;