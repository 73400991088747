import styled from "styled-components";

export const Container = styled.main`

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: var(--margin-2x);
        width: 100%;

        menu {
            display: flex;
            flex-direction: row;
            align-items: center;

            small {
                margin-right: var(--margin-2x);
                cursor: pointer;    
            }
        }
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: var(--padding);

        margin-top: var(--margin-2x);

        button {
            width: 100%;

            :first-child {
                margin-right: .2rem;
            }

            :last-child {
                margin-left: .2rem;
            }
        }
    }

`;