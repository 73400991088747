import React, {
    useEffect, 
    useState,
    useCallback
} from "react";
import * as Fi from "react-icons/fi";

import api from "../../../services/api";

import { CompanyData } from "../../../models";
import { useAxiosErrors } from "../../../context/axiosErrors";

import { Modal } from "../../Modal";

import DiscountCreate from "../../Discount/Create";
import DiscountList from "../../Discount/ListByCompany";

import { 
    Container
} from "./styles";

interface CompanyDetailsProps {
    companyId?: number;
    companyMain?: CompanyData;
    inManagerArea: boolean;
    setVisibility(value: boolean): void;
}

const CompanyDetails = ({ companyId, companyMain, inManagerArea, setVisibility } : CompanyDetailsProps) => {

    const [ company, setCompany ] = useState<CompanyData>(companyMain as CompanyData);
    const [ showModalDiscountCreate, setShowModalDiscountCreate ] = useState<boolean>(false);
    
    const { sendError } = useAxiosErrors();

    async function loadCompany(): Promise<void> {
        var guidCompanyId = companyMain 
            ? company.id 
            : companyId;

        await api
            .get(`manager/company/${guidCompanyId}`)
            .then(response => setCompany(response.data))
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadCompany();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDiscountRegistered = useCallback(() => {
        setShowModalDiscountCreate(false);
    }, []);

    return (
        <Container>
            <header>
                <h2>{company.name}</h2>
                <small onClick={() => setVisibility(false)}>Voltar</small>
            </header>
            <section>
                <div>
                    <small>
                        <b>Razão Social:</b>
                        <p>{company.corporateName}</p>
                        <b>CNPJ:</b>
                        <p>{company.cnpj}</p>
                        <b>E-mail:</b>
                        <p>{company.email}</p>
                        <b>Telefone:</b>
                        <p>{company.phoneNumber}</p>
                    </small>
                </div>
                <div>
                    <header>
                        <b>Descontinhos do Estabelecimento</b>
                        <label onClick={() => setShowModalDiscountCreate(true)}>
                            <Fi.FiPlus /> Novo Descontinho
                        </label>
                    </header>
                    <DiscountList 
                        companyId={company.id}
                        inManagerArea
                    />
                </div>
            </section>
            <Modal
                visible={showModalDiscountCreate}
                size="big"
                handleClose={setShowModalDiscountCreate}
            >
                <DiscountCreate
                    company={company}
                    setVisibility={setShowModalDiscountCreate}
                    handleRegistered={handleDiscountRegistered}
                />
            </Modal>
        </Container>
    );
}

export default CompanyDetails;