import React, {
    useState,
    useEffect, 
    useCallback
} from "react";
import * as Fi from "react-icons/fi";

import { get } from "../../../../services/promises";

import { useAxiosErrors } from "../../../../context/axiosErrors";

import Button from "../../../../components/Button";
import List from "../../../../components/List";
import ListItem from "../../../../components/ListItem";
import { Modal } from "../../../../components/Modal";
import Skeleton from "../../../../components/Skeleton";

import CompanyCreate from "../../../../components/Company/Create";
import CompanyDetails from "../../../../components/Company/Details";

import { CompanyData } from "../../../../models";

import {
    Container
} from "./styles";

const ManagerCompany: React.FC = () => {

    const [ companies, setCompanies ] = useState<CompanyData[] | null>(null);
    const [ companySelected, setCompanySelected ] = useState<CompanyData>({} as CompanyData);
    const [ showModalCreate, setShowModalCreate ] = useState<boolean>(false);
    const [ showModalDetails, setshowModalDetails ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadCompanies() {
        get("manager/company")
            .then(response => {
                setCompanies(response as CompanyData[]);
                setIsLoading(false);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadCompanies();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!!companySelected.id) {
            setshowModalDetails(true);
        }
    }, [companySelected]);

    useEffect(() => {
        if(!showModalDetails) {
            setCompanySelected({} as CompanyData);
        }
    }, [showModalDetails]);

    const handleRegistered = useCallback(() => {
        loadCompanies();
        setShowModalCreate(false);
    }, [loadCompanies])

    return (
        <Container>
            <header>
                <h1>Estabelecimentos</h1>
                <Button styliest="success" onClick={() => setShowModalCreate(true)}>
                    <Fi.FiPlus size={20} /> Novo Estabelecimentos
                </Button>
            </header>
            <section>
                { 
                    isLoading ? <Skeleton /> :
                    companies && companies.length > 0 ?
                    <List> {
                        companies.map(company => (
                            <ListItem key={company.id}>
                                <label>{company.name}</label>
                                {company.cnpj}
                                <menu>
                                    <Fi.FiGrid 
                                        size={20}
                                        onClick={() => setCompanySelected(company)} 
                                    />
                                    <Fi.FiEdit size={20} />
                                    <Fi.FiTrash size={20} />
                                </menu>
                            </ListItem>
                        ))
                    }
                    </List> :
                    <p>Nenhum estabelecimento encontrado</p>
                }
            </section>
            <Modal 
                visible={showModalCreate} 
                size="big"
                handleClose={setShowModalCreate}>
                <CompanyCreate 
                    setVisibility={setShowModalCreate}
                    handleCompanyRegistered={handleRegistered}
                />
            </Modal>
            <Modal 
                visible={showModalDetails} 
                size="max"
                handleClose={setshowModalDetails}>
                <CompanyDetails
                    companyMain={companySelected}
                    setVisibility={setshowModalDetails}
                    inManagerArea={true}
                />
            </Modal>
        </Container>
    );
}

export default ManagerCompany;