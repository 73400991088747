import React from "react";
import * as Fi from "react-icons/fi";

import { Modal, ModalProps } from "../Modal";

import { 
    Container
} from "./styles";

interface ModalErrorsProps extends ModalProps {
    messages: string[];
    fadeModal(value: boolean): void;
}

const ModalErrors: React.FC<ModalErrorsProps> = ({messages, visible, size, fadeModal}) => {

    return (
        <Modal 
            visible={visible} 
            size={size}
            handleClose={() => fadeModal(true)}>
            <Container>
                <header>
                    <Fi.FiXCircle size={40} />
                    <h1>Atenção</h1>
                </header>
                {messages && messages.map(message => (
                    <p key={message}>{message}</p>
                ))}
                <footer onClick={() => fadeModal(true)}>
                    <label>Voltar</label>
                </footer>
            </Container>
        </Modal>    
    );
}

export default ModalErrors;