import React from "react";
import { Route, RouteComponentProps, Redirect, useHistory } from "react-router-dom";
import * as Fi from "react-icons/fi";

import { useAuth } from "../../context/auth";

import Home from "./pages/Home";
import Manager from "./pages/Manager";
import Customer from "./pages/Customer";
import Company from "./pages/Company";
import Discount from "./pages/Discount";
import Sponser from "./pages/Sponser";
import Profile from "./pages/Profile";

import ConfigRoutes from "./pages/Config/routes";

import logo from "../../assets/images/logo_lighter.svg";

import Avatar from "../../components/Avatar";

import {
    Container,
    Menu,
    Content
} from "./assets/styles/global";

const ManagerRoute : React.FC<RouteComponentProps> = (props) => {

    const history = useHistory();

    const { manager } = useAuth();
    
    const pathname = props.location.pathname;

    return (
        manager != null ? (
            <Container>
                <Menu>
                    <menu>
                        <ul>
                            <li onClick={() => history.push("/account/selectarea")}>
                                <img src={logo} alt="Descontinho Club" />
                            </li>
                            <li
                                style={
                                    pathname === "/manager"
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager")
                                }
                            >
                                <Fi.FiPieChart size={25} />
                            </li>
                            <li
                                style={
                                    pathname.includes("/manager/customer")
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager/customer")
                                }
                            >
                                <Fi.FiUsers size={25} />
                            </li>
                            <li
                                style={
                                    pathname.includes("/manager/company")
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager/company")
                                }
                            >
                                <Fi.FiShoppingCart size={25} />
                            </li>
                            <li
                                style={
                                    pathname === "/manager/discount"
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager/discount")
                                }
                            >
                                <Fi.FiDollarSign size={25} />
                            </li>
                            <li
                                style={
                                    pathname.includes("/manager/sponser")
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager/sponser")
                                }
                            >
                                <Fi.FiTag size={25} />
                            </li>
                            <li 
                                style={
                                    pathname.includes("/manager/manager")
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager/manager")
                                }
                            >
                                <Fi.FiUser size={25} />
                            </li>
                            <li
                                style={
                                    pathname.includes("/manager/config")
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/manager/config")
                                }>
                                <Fi.FiSettings size={25} />
                            </li>
                        </ul>
                    </menu>
                    <article
                        style={
                            pathname.includes("/manager/profile")
                                ? {backgroundColor: '#292c3a'}
                                : {}
                        } 
                        onClick={() => 
                            history.push("/manager/profile")
                        }
                    >
                        <Avatar 
                            image={manager.imageUriHigh} />
                    </article>
                </Menu>
                <Content>
                    <Route path={`${props.match.path}`} exact component={Home} />
                    <Route path={`${props.match.path}/Discount`} component={Discount} />
                    <Route path={`${props.match.path}/Manager`} component={Manager} />
                    <Route path={`${props.match.path}/Sponser`} component={Sponser} />
                    <Route path={`${props.match.path}/Customer`} component={Customer} />
                    <Route path={`${props.match.path}/Company`} component={Company} />
                    <Route path={`${props.match.path}/Profile`} component={Profile} />
                    <Route path={`${props.match.path}/Config`} component={ConfigRoutes} />
                </Content>
            </Container>       
        ) : (
            <Redirect to="/account/logon" />
        )
    );
}

export default ManagerRoute;