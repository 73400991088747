import React, {
    useRef,
    useState
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import getValidationsErrors from "../../../utils/getValidationsErros";
import api from "../../../services/api";

import { 
    Container
} from "./styles";

import { CategoryData } from "../../../models";

import Button from "../../Button";
import Input from "../../Input";

import { useAxiosErrors } from "../../../context/axiosErrors";

interface CategoryUpdateProps {
    category: CategoryData;
    setVisibility(value: boolean): void;
    handleCategoryUpdated(): void;
}

const CategoryUpdate: React.FC<CategoryUpdateProps> = ({category, setVisibility, handleCategoryUpdated}) => {

    const formRef = useRef<FormHandles>(null);
    const { sendError } = useAxiosErrors();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleSubmitForm: SubmitHandler<CategoryData> = async (data) => {

        setIsLoading(true);

        try { 

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                title: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com o título")
            });

            await schema.validate(data, {
                abortEarly: false
            });

            await api.put("manager/category", data);

            handleCategoryUpdated();
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setIsLoading(false);
    }

    return (
        <Container>
            <Form ref={formRef} initialData={category} onSubmit={handleSubmitForm}>
                <h2>Atualizar Categoria</h2>
                <Input name="id" type="hidden" />
                <Input name="categoryid" type="hidden" />
                <Input name="created" type="hidden" />
                <Input name="title" maxLength={100} />
                <Button type="submit" styliest="success" inProcess={isLoading}>
                    Salvar Alterações
                </Button>
            </Form>      
            <small onClick={() => setVisibility(false)}>Voltar</small>      
        </Container>
    );
}

export default CategoryUpdate;