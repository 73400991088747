import styled from "styled-components";

export const Container = styled.section`
    height: 100%;
    width: 100%;
    text-align: center;

    menu {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        button:first-child {
            margin-right: 1rem;
        }
    }
`;