type EnumLiteralsOf<T extends object> = T[keyof T];

export type Gender = EnumLiteralsOf<typeof Gender>;
export const Gender = Object.freeze({
    Male: 1,
    Female: 2
});

export type DiscountStatus = EnumLiteralsOf<typeof Gender>;
export const DiscountStatus = Object.freeze({
    InCreation: 0,
    SendToReview: 1,
    Aproved: 2,
    Reproved: 3
});

export type DiscountPeriodUseType = EnumLiteralsOf<typeof DiscountPeriodUseType>;
export const DiscountPeriodUseType = Object.freeze({
    Unlimited: 0,
    PerDay: 1,
    PerWeek: 2,
    PerMonth: 3,
    PerDiscount: 4,
});

export type ManagerData = {
    id: string;
    naturalPersonId: string;
    imageUriLow: string;
    imageUriMedium: string;
    imageUriHigh: string;
    name: string;
    email: string;
    phoneNumber: string;
    cpf: string;
    birthDate: Date;
    gender: Gender;
    created: Date;
    updated: Date;
    removed: boolean;
}

export type CategoryData = {
    id: string;
    categoryId: string;
    title: string;
    imageUriLow: string;
    imageUriMedium: string;
    imageUriHigh: string;
    created: Date;
    updated: Date;
    removed: boolean;
}

export type TagData = {
    id: string;
    categoryId: string;
    title: string;
    created: Date;
    updated: Date;
    removed: boolean;
}

export type CustomerData = {
    id: string;
    naturalPersonId: string;
    name: string;
    email: string;
    phoneNumber: string;
    cpf: string;
    birthDate: Date;
    gender: Gender;
    imageUriLow: string;
    imageUriMedium: string;
    imageUriHigh: string;
    created: Date;
    updated: Date;
    removed: boolean;
    password: string;
}

export type MemberData = {
    id: string;
    naturalPersonId: string;
    name: string;
    email: string;
    phoneNumber: string;
    cpf: string;
    birthDate: Date;
    gender: Gender;
    imageUriLow: string;
    imageUriMedium: string;
    imageUriHigh: string;
    created: Date;
    updated: Date;
    removed: boolean;
    password: string;
}

export type CompanyData = {
    id: string;
    legalPersonId: string;
    name: string;
    email: string;
    phoneNumber: string;
    cnpj: string;
    corporateName: string;
    imageUriLow: string;
    imageUriMedium: string;
    imageUriHigh: string;
    created: Date;
    updated: Date;
    removed: boolean;
}

export type DiscountData = {
    id: string;
    companyId: string;
    ompanyName: string;
    companyImageUri: string;
    companyAddress: string;
    companyPhoneNumber: string;
    title: string;
    description: string;
    price: number;
    discountPercent: number;
    start: Date;
    finish: Date;
    hasLimitUse: boolean;
    useLimit: number;
    periodUseType: DiscountPeriodUseType;
    status: DiscountStatus;
    imageUriLow: string;
    imageUriMedium: string;
    imageUriHigh: string;
    created: Date;
    updated: Date;
    removed: boolean;
    days?: DiscountDayData[];
}

export type DiscountDayData = {
    id: string;
    discountId: string;
    dayOfWeek: number;
    created: Date;
    updated: Date;
    removed: boolean;
    periods?: DiscountDayPeriodData[];
}

export type DiscountDayPeriodData = {
    id: string;
    discountDayId: string;
    hourStart: number;
    hourFinish: number;
    created: Date;
    updated: Date;
    remove: boolean;
}

export type DiscountCategoryData = {
    id: string;
    discountId: string;
    categoryId: string;
}

export type DiscountCategoryTagData = {
    id: string;
    discountCategoryId: string;
    tagId: string;
}

export type CompanyMemberData = {
    id: string;
    companyId: string;
    memberId: string;
    company?: CompanyData;
}

export type CustomerDiscountStatus = EnumLiteralsOf<typeof Gender>;
export const CustomerDiscountStatus = Object.freeze({
    Enabled: 0,
    Expired: 1,
    Used: 2
});

export type CustomerDiscountData = {
    id: string;
    code: string;
    customerId: string;
    customerName: string;
    customerEmail: string;
    customerImageUri: string;
    discountId: string;
    discountTitle: string;
    discountPrice: string;
    discountPercent: string;
    discountImageUri: string;
    discountCompanyLegalPersonCorporateName: string;
    status: CustomerDiscountStatus;
    created: Date;
    udpated: Date;
    removed: boolean;
}