import React, { useEffect } from "react";
import { RouteComponentProps, Route, useHistory } from "react-router-dom";

import { useAuth } from "../../context/auth";

import AccountLogon from "./Logon";
import AccountSelectArea from "./SelectArea";
import AccountForgotPassword from "./ForgotPassword";
import AccountResetPassword from "./ResetPassword";
import AccountRegister from "./Register";
import AccountRegisterMember from "./RegisterMember";

import {
    Container
} from "./styles";

const AccountRoutes: React.FC<RouteComponentProps> = (props) => {

    const { token, isLoading } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if(!!token) {
            history.push("SelectArea");
        }
        //eslint-disable-next-line
    }, []);

    return (

        isLoading 
            ? <>Carregando</>
            : <Container>
            <section>
                <Route path={`${props.match.path}/Logon`} component={AccountLogon} />
                <Route path={`${props.match.path}/ForgotPassword`} component={AccountForgotPassword} />
                <Route path={`${props.match.path}/ResetPassword/:code`} component={AccountResetPassword} />
                <Route path={`${props.match.path}/SelectArea`} component={AccountSelectArea} />
                <Route path={`${props.match.path}/Register`} component={AccountRegister} />
                <Route path={`${props.match.path}/RegisterMember`} component={AccountRegisterMember} />
            </section> 
        </Container>  
        
    );
}

export default AccountRoutes;