import styled from "styled-components";

export const Container = styled.main`

    position: relative;
    text-align: center;
    max-width: 30vw !important;  

    h2 { 
        margin-top: 5rem;
    }
        
    img {
        width: 40%;
    }

    section {
        display: flex;
        flex-direction: row;
        margin-top: 5rem;
        gap: 5rem;
        width: 100%;
    }        


    @media (max-width: 768px) {
        max-width: 100% !important;  
    }

`;
