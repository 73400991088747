import React, { useRef } from "react";
import * as Fi from "react-icons/fi";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { ManagerData } from "../../../models";

import Input from "../../Input";
import Button from "../../Button";

import {
    Container
} from "./styles";

interface ManagerUpdateProps 
{
    manager: ManagerData;
    handleManagerUpdated(result: boolean): any;
    setVisibility(value: boolean): any;
}

type ManagerUpdateType = {
    id: string;
    cpf: string;
    name: string;
    email: string;
    phoneNumber: string;
}

const ManagerUpdate : React.FC<ManagerUpdateProps> = (props) => {

    const formRef = useRef<FormHandles>(null);

    const handleSubmit: SubmitHandler<ManagerUpdateType> = async (data) => {
        await api.put(`manager/${data.id}`, {
            cpf: data.cpf,
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber
        }).then(response => {
            props.handleManagerUpdated(true);
        }).catch(errors => 
            console.log(errors.response.data.errors)
        );
    }

    return (
        <Container>
            <h2>Atualizar Gerente</h2>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <Input 
                    type="hidden" 
                    name="id" 
                    defaultValue={props.manager.id} />
                <Input 
                    type="text" 
                    name="cpf" 
                    placeholder="CPF do Gerente" 
                    defaultValue={props.manager.cpf}
                />
                <Input 
                    type="text" 
                    name="name" 
                    placeholder="Nome do Gerente" 
                    defaultValue={props.manager.name}
                />
                <Input 
                    type="email" 
                    name="email" 
                    placeholder="E-mail do Gerente" 
                    defaultValue={props.manager.email}
                />
                <Input 
                    type="text" 
                    name="phoneNumber" 
                    placeholder="Telefone do Gerente" 
                    defaultValue={props.manager.phoneNumber}
                />
                <Button 
                    styliest="success" 
                    type="submit">
                    Salvar Alterações
                </Button>
            </Form>
            
            <div onClick={() => props.setVisibility(false)}>
                <Fi.FiArrowLeft size={20} /> Voltar
            </div>
        </Container>
    )
}

export default ManagerUpdate;   