import React, { 
    createContext, 
    useState, 
    useCallback, 
    useContext, 
    useEffect 
} from "react";

import api from "../services/api";
import { get } from "../services/promises";

import { 
    ManagerData, 
    MemberData,
    CompanyData
} from "../models";

interface AuthContextData {
    token: string | null;
    manager: ManagerData | null;
    member: MemberData | null;
    company?: CompanyData;
    isLoading: boolean;
    signIn(credentials: SignInCredentialsData): Promise<void>;
    signOut(): void;
    setCompany(company?: CompanyData): void;
    updateMember(): void;
    updateCompany(): void;
}

interface AuthState {
    token: string | null;
    manager: ManagerData | null;
    member: MemberData | null;
    company?: CompanyData;
}

interface SignInCredentialsData {
    email: string;
    password: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({children}) => {

    const [ data, setData ] = useState<AuthState>({} as AuthState);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect(() => {

        const token = localStorage.getItem("@D.Club:token");
        const manager = localStorage.getItem("@D.Club:manager");
        const member = localStorage.getItem("@D.Club:member");

        if(token) {
            api.defaults.headers.authorization = `Bearer ${token}`;
        }

        setData({
            token,
            manager: manager && JSON.parse(manager),
            member: member && JSON.parse(member)
        });

        setIsLoading(false);

    }, []);

    const signIn = useCallback(async ({email, password}) => {
        
        var token = "";
        var refreshToken = "";
        let manager = {} as ManagerData;
        let member = {} as MemberData;

        let isManager = false;
        let isMember = false;

        await api.post("account/newlogin", { email, password }).then(response => {            
            token = response.data.token;
            refreshToken = response.data.refresh_token;

            localStorage.setItem("@D.Club:token", token);
            localStorage.setItem("@D.Club:refreshtoken", JSON.stringify(refreshToken));

            api.defaults.headers.authorization = `Bearer ${token}`;
        });

        await get("manager/account").then(response => {
            manager = response as ManagerData;  
            
            if(manager) {
                localStorage.setItem(
                    "@D.Club:manager", 
                    JSON.stringify(manager)
                );

                isManager = true;
            }
        }).catch(() => {
            return;
        });

        await get("member/account").then(response => {
            member = response as MemberData;    

            if(member) {
                localStorage.setItem(
                    "@D.Club:member", 
                    JSON.stringify(member)
                );

                isMember = true;
            }
        }).catch(() => {
            return;
        });

        setData({ 
            token,
            manager: isManager ? manager : null,
            member: isMember ? member : null
        });
        //eslint-disable-next-line
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem("@D.Club:token");
        localStorage.removeItem("@D.Club:refreshtoken");
        localStorage.removeItem("@D.Club:manager");
        localStorage.removeItem("@D.Club:member");

        setData({} as AuthState);
    }, []);

    const updateMember = useCallback(async () => {
        await api.get("member/account").then(response => {
            const member = response.data as MemberData;  

            if(member) {
                localStorage.setItem(
                    "@D.Club:member", 
                    JSON.stringify(member)
                );

                setData({
                    ...data,
                    member: member,
                });
            }
        });
    }, [data, setData]);

    const setCompany = useCallback(async (company: CompanyData) => {
        setData({
            ...data,
            company: company,
        });
    }, [data, setData]);

    const updateCompany = useCallback(async () => {
        await api
            .get(`member/company/${data.company?.id}`)
            .then(response => {
                const company = response.data as CompanyData;

                if(company) {
                    setData({
                        ...data,
                        company: company
                    });
                }
            });
    }, [data, setData]);

    return (
        <AuthContext.Provider value={{
            token: data.token,
            manager: data.manager,
            member: data.member,
            company: data.company,
            isLoading,
            signIn,
            signOut,
            setCompany,
            updateMember,
            updateCompany
        }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if(!context) {
        throw new Error("There is no context created");
    }

    return context;
}

export { AuthProvider, useAuth }