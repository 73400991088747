import styled from "styled-components";
import { Form as FormUnform } from "@unform/web";

import InputComponent from "../../Input";

export const Container = styled.main`

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: var(--margin-2x);
        width: 100%;

        menu {
            display: flex;
            flex-direction: row;
            align-items: center;

            small {
                margin-right: var(--margin-2x);
                cursor: pointer;    
            }
        }
    }

`;

export const Form = styled(FormUnform)`

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--margin-2x);
    width: 100%;

    menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--margin-2x);
        margin-top: var(--margin);
    }

    input, textarea { 
        margin-bottom: var(--margin);
    }

    & > div {
        
        :first-child {
            margin-top: var(--margin);
        }

    }


    /* .discountDayPeriod {
            border-radius: var(--border-radius);
            padding: var(--padding-inner-max);
            border: solid 1px var(--color-tertiary);
        } */

    ul {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: var(--margin);
        list-style: none;
        margin: var(--margin) auto;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 1px var(--color-tertiary);
            border-radius: var(--border-radius);
            padding: var(--padding-inner);
            cursor: pointer;
            transition: background .2s;

            :hover {
                background: var(--color-tertiary);
            }
        }

        li.itemChecked {
            background: var(--color-tertiary);
        }
    }

    footer 
    {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: var(--margin-2x);

        p {
            margin-bottom: var(--margin);
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

`;

export const Input = styled(InputComponent)`
`;