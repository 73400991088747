import styled from "styled-components";

export const Container = styled.div`
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h2 {
        font-weight: 300;
    }

    div {
        display: flex;
        align-items: center;
        cursor: pointer;

        :hover {
            svg {
                transform: rotate(360deg);
            }
        }

        svg {
            margin-right: .5rem;
            transition: transform .5s;
        }
    }
`;

export const ManagerListItens = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: var(--margin-2x);
`;

export const ManagerListItem = styled.article`
    display: flex;
    justify-content: space-between;
    background: var(--rgba-white-original);
    margin: 1rem 0;
    padding: 2rem;
    color: var(--color-black-ligther);
    border-radius: .4rem;
    transition: transform .3s, background .3s;
    
    :hover {
        background: var(--color-white-adjusted);
        transform: translateX(1rem);
    }

    label {
        font-weight: 700;
    }

    menu {
        svg {
            margin-right: 1rem;
            cursor: pointer;
            transition: transform .3s;

            :hover {
                transform: scale(1.3);
            }
        }
    }
`;
