import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import {
    Container
} from "./styles";

const BackButton: React.FC = () => {

    const history = useHistory();

    return (
        <Container onClick={() => history.goBack()}>
            <FiChevronLeft size={18} />
        </Container>
    );
}

export default BackButton;