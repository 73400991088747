import styled from "styled-components";

export const Container = styled.main`
    footer {
        display: flex;
        flex-direction: space-between;
        gap: var(--margin-2x);

        margin-top: var(--margin-2x);

        button {
            flex: 1;
        }
    }
`;