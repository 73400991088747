import React, {
    useState,
    useCallback
} from "react";

import { useAuth } from "../../../../context/auth";

import { Modal } from "../../../../components/Modal";
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import AsideMenuItem from "../../../../components/AsideMenuItem";
import MemberUpdate from "../../../../components/Member/Update";
import MemberDetails from "../../../../components/Member/Details";
import MemberChangePassword from "../../../../components/Member/ChangePassword";

import { MemberData } from "../../../../models";

import {
    Container 
} from "./styles";

const MemberProfile: React.FC = () => {

    const [ showModalUpdate, setShowModalUpdate ] = useState<boolean>(false);
    const [ showModalChangePassword, setShowModalChangePassword ] = useState<boolean>(false);

    const { signOut, member } = useAuth();

    const handleMemberUpdated = useCallback(() => {
        setShowModalUpdate(false);
    }, []);

    return (
        <Container>
            <Header>
                <h1>Seu Perfil</h1>
                <b onClick={() => signOut()}>Sair do Ambiente</b>
            </Header>
            <section>
                <AsideMenu>
                    <AsideMenuItem onClick={() => setShowModalUpdate(true)}>
                        Editar Perfil
                    </AsideMenuItem>
                    <AsideMenuItem onClick={() => setShowModalChangePassword(true)}> 
                        Alterar Senha
                    </AsideMenuItem>
                </AsideMenu>
                <article>
                    <MemberDetails member={member as MemberData} />
                </article>
            </section>

            <Modal
                visible={showModalUpdate}
                size="small"
                handleClose={setShowModalUpdate}>
                <MemberUpdate
                    setVisibility={setShowModalUpdate}
                    member={member as MemberData}
                    handleMemberUpdated={handleMemberUpdated}
                    isAccountMember={true}
                />
            </Modal>

            <Modal
                visible={showModalChangePassword}
                size="small"
                handleClose={setShowModalChangePassword}>
                <MemberChangePassword
                    setVisibility={setShowModalChangePassword}
                />
            </Modal>
        </Container>
    );
}

export default MemberProfile;