import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { 
    Container
} from "./styles";

interface Props {
    name: string;
    label?: string;
}

type SelectProps = JSX.IntrinsicElements['select'] & Props;

const Select: React.FC<SelectProps> = ({ name, label, children, ...rest }) => {
    
    const selectInput = useRef<HTMLSelectElement>(null);
    const { fieldName, registerField, error, defaultValue } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectInput.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <Container>
            { label && <label htmlFor={fieldName}>{label}</label>}

            <select 
                id={fieldName}
                ref={selectInput}
                defaultValue={defaultValue}
                {...rest}
            >
                {children}
            </select>

            { error && <span>{error}</span>}
        </Container>
    );
};

export default Select;