import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/images/logo_full_landscape_text_white.svg';

import { 
    Container
} from './styles';

const TermsAndConditions = () => {

    var history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <img src={Logo} onClick={() => history.goBack()} alt="Descontinho Club" />
            <h1>TERMOS E CONDIÇÕES DE USO</h1>

            <b>Objeto</b>
            <p>O Presente instrumento estabelece os termos e condições gerais de uso dos serviços oferecidos pelo clube de descontos e vantagens denominado DESCONTINHO CLUB, entre o usuário e a empresa fornecedora, pelo site www.descontinho.club ou pelo aplicativo móvel.</p>
            <p>O DESCONTINHO CLUB, por meio de sua assinatura, permite que os usuários tenham acesso a promoções, descontos e vantagens junto aos parceiros credenciados, a partir da validação gerada pelo Aplicativo.</p>
            <p>O teor destes Termos de Uso pode ser consultado, impresso ou armazenado em um arquivo de dados, em qualquer momento, pelo site http://www.descontinho.club/.</p>

            <b>Adesão/Assinatura</b>
            <p>Todos que desejarem utilizar os serviços oferecidos pelo DESCONTINHO CLUB deverão, inicialmente, ler integralmente estes “Termos de Uso”, certificar-se de haver compreendido e aceitar todas as condições estabelecidas aqui. Em seguida, o Usuário deverá efetuar o seu cadastro pelo Site ou Aplicativo, preenchendo o formulário de inscrição com todos os dados solicitados e indicando um cartão de crédito válido para pagamento.</p>
            <p>Os sistemas de cadastro e de pagamento integrados ao site do DESCONTINHO CLUB se reservam o direito de recusar ou suspender temporária ou definitivamente, pedidos de compra/assinatura mesmo que o cadastro inicial do usuário já tenha sido aceito, decisão esta que independerá de motivos ou será geradora de qualquer direito ao usuário.</p>
            <p>A Assinatura do DESCONTINHO CLUB será efetuada de acordo com o plano escolhido. Para utilizar as promoções, o usuário precisará apresentar o código de validação gerado dentro do aplicativo.</p>
            <p>O Usuário está ciente, ainda, de que o DESCONTINHO CLUB se reserva o direito de exigir qualquer comprovante e/ou dados adicionais do Usuário, a fim de conferir as informações pessoais submetidas no ato do cadastro, bem como, o DESCONTINHO CLUB poderá recusar, suspender temporária ou definitivamente, o registro de qualquer Usuário cujos dados não tenham sido confirmados, mesmo que o cadastro inicial deste já tenha sido aceito, decisão esta que independerá de motivos e nem conferirá quaisquer direitos aos Usuários.</p>

            <b>Aceitação das Condições do Contrato</b>
            <p>Ao realizar o cadastro, o usuário declara que leu, compreendeu e aceitou o presente Contrato em todos seus termos e condições, de forma livre e independente de qualquer dolo, coação, fraude ou reserva mental quanto a qualquer dos termos ou condições e que sua intenção de participar do clube não está relacionada com alguma outra manifestação, garantia, ou declaração que não sejam as estabelecidas neste Contrato.</p>

            <b>Uso do Serviço</b>
            <p>Todas as ofertas oferecidas pelos parceiros do DESCONTINHO CLUB somente poderão ser obtidas pelos usuários através da apresentação do código de validação, válido e ativo. Para evitar constrangimento e possíveis abalos indesejáveis, o usuário deverá sempre verificar se sua assinatura está dentro da validade (confirmação de pagamento).</p>
            <p>A assinatura de membro do DESCONTINHO CLUB só poderá ser usada pelo seu portador único, sendo absolutamente vedada sua réplica física ou eletrônica a terceiros.</p>

            <b>Limitação de Responsabilidade</b>
            <p>As informações contidas no material, no site e aplicativo servem apenas e tão somente para fins ilustrativos e de informação. O DESCONTINHO CLUB preza sempre por sua veracidade, mas não pode oferecer nenhuma garantia quanto à exatidão ou adequação a propósito de qualquer material do site e aplicativo, ou ainda à confiabilidade do acesso, não se responsabilizando por eventuais danos decorrentes de conexão com o uso do site, aplicativo ou dos serviços dos parceiros. </p>
            <p>O site e/ou o aplicativo, e alguma de suas funcionalidades podem, eventualmente, não estar disponíveis devido a problemas técnicos ou falhas de Internet nos links ou ferramentas, não sendo lícito aos usuários imputar responsabilidade, exigir indenização de qualquer natureza em virtude da impossibilidade de acesso ao site e aplicativo, bem como por danos indiretos que surjam em conexão com o presente Contrato.</p>
            <p>É de responsabilidade exclusiva do usuário do serviço e assinante verificar qual a modalidade de descontos, datas, horários e toda e quaisquer especificidades oferecidas pelo parceiro do DESCONTINHO CLUB, não tendo o DESCONTINHO CLUB qualquer responsabilidade pela eventual negativa da promoção (ainda que injustificada), uso inadequado da assinatura pelo usuário ou qualquer ato praticado pelos parceiros, tendo o usuário relação jurídica direta com estes na qualidade de consumidor dos serviços que lhe serão prestados com os descontos negociados pelo DESCONTINHO CLUB.</p>

            <b>Pagamento</b>
            <p>Os pagamentos serão realizados pelo usuário, a partir da data de adesão, conforme o plano escolhido, através das condições e dos meios de pagamento disponíveis no site ou aplicativo.</p>
            <p>Os pagamentos serão efetuados através de uma empresa responsável pela gestão dos pagamentos. O DESCONTINHO CLUB não poderá intervir nos resultados da relação do Usuário com a empresa responsável pela gestão de pagamentos, uma vez que a mesma administra suas operações de maneira independente.</p>
            <p>O DESCONTINHO CLUB não será responsável, inclusive, pela disponibilidade ou não dos serviços prestados pela empresa gestora de pagamentos e não poderá garantir, de forma nenhuma, que estes serviços serão livres de erros, interrupções, mal funcionamento, atrasos ou outras imperfeições.</p>
            <p>Os valores das Assinaturas, assim como as formas de pagamento, estão disponíveis na Plataforma e poderão sofrer alterações a qualquer momento, a exclusivo critério do DESCONTINHO CLUB, bem como em casos de campanhas promocionais realizadas em conjunto com parceiros ou promoções disponibilizadas diretamente aos assinantes.</p>
            <p>Ao fazer uma assinatura dos serviços do Descontinho Club que exigem o pagamento de assinatura para permitir o acesso, você concorda que ela será renovada automaticamente e, a menos que cancele sua assinatura, você nos autoriza a debitar sua forma de pagamento pelo prazo referente à renovação. O período da renovação automática será o mesmo de sua assinatura inicial, exceto se você tiver recebido outras informações no momento da venda. </p>
            <p>A taxa de renovação não será maior que a taxa referente ao período de assinatura imediatamente anterior, excluindo-se preços promocionais e descontos, exceto se avisarmos você sobre uma alteração na taxa antes de sua renovação automática. Ocasionalmente, podemos oferecer uma assinatura gratuita para avaliação de um Serviço do DESCONTINHO CLUB. </p>
            <p>Se você se inscrever em uma assinatura gratuita para avaliação, sua conta começará a ser debitada quando tal assinatura expirar, a menos que você cancele sua assinatura antes dessa data.</p>

            <b>Política de Cancelamento</b>
            <p>O cancelamento da assinatura do DESCONTINHO CLUB pode ser feita assim que o usuário desejar, através da área do assinante no site ou no aplicativo móvel. Ao efetuar o cancelamento a assinatura será bloqueada nos sistemas de controle e o seu uso não será mais aceito na rede de estabelecimentos parceiros.</p>
            <p>Na medida permitida pelas leis aplicáveis, os pagamentos não são reembolsáveis e o DESCONTINHO CLUB não oferece reembolsos ou créditos por períodos de assinatura utilizados parcialmente ou por promoções não aproveitadas.</p>

            <b>Do Uso dos Serviços</b>
            <p>O Usuário concorda em não utilizar os serviços do DESCONTINHO CLUB para nenhuma outra finalidade, principalmente se ilegal ou proibida por estes Termos de Uso.</p>
            <p>O Usuário não poderá usar a Plataforma de maneira que possa danificar, inutilizar, sobrecarregar ou prejudicar qualquer rede ou servidor DESCONTINHO CLUB. O Usuário não poderá tentar obter acesso não autorizado a qualquer parte da Plataforma, outras contas, sistemas de computadores, ou redes conectadas a servidores do DESCONTINHO CLUB, através de senha, hacking ou de mineração de dados. O Usuário não poderá obter ou tentar obter materiais ou informações através de quaisquer meios intencionalmente não disponibilizados.</p>
            <p>O DESCONTINHO CLUB poderá excluir o cadastro do Usuário, cancelando eventual Assinatura, de forma imediata e sem qualquer direito a questionamentos, caso o Usuário viole os deveres e obrigações estabelecidos aqui nestes Termos de Uso.</p>

            <b>Dos Direitos e Obrigações dos Usuários</b>
            <p>Caso o Usuário necessite alterar os seus dados cadastrados, a qualquer momento, este deverá comunicar o DESCONTINHO CLUB imediatamente.</p>
            <p>Em regra, o DESCONTINHO CLUB se comunicará com o Usuário por meio do e-mail informado no cadastro, bem como através do seu contato telefônico, incluindo, mas não se limitando à sms, aplicativos de mensagens e pushes. </p>
            <p>O Usuário deve adotar todas as medidas necessárias (i.e., configuração de programas AntiSpam e filtros de e-mails) para assegurar que os e-mails enviados pelo DESCONTINHO CLUB serão entregues ao Usuário.</p>
            <p>Os Usuários são responsáveis por manter em segredo a sua senha, não a transmitindo/revelando a terceiros. Se os Usuários tiverem conhecimento de que terceiros se apoderaram de sua senha ou estejam utilizando os seus dados de acesso, o DESCONTINHO CLUB deverá ser imediatamente informado. </p>
            <p>Caso o DESCONTINHO CLUB tenha motivos para acreditar que uma terceira parte não autorizada está na posse de dados de acesso do Usuário, o DESCONTINHO CLUB poderá, a seu exclusivo critério, alterar e/ou bloquear os dados de acesso. Neste caso, o DESCONTINHO CLUB informará ao Usuário e transmitirá, mediante solicitação expressa, os novos dados de acesso. O Usuário não terá direito à reprodução dos dados de acesso originais.</p>
            <p>O Usuário está ciente de acordo que não poderá transferir os dados de sua conta à terceiros em nenhuma hipótese.</p>

            <b>Da Licença Limitada e Direitos Autorais</b>
            <p>Todos os direitos intelectuais e industriais sobre a Plataforma, incluindo sem se limitar ao site, aplicativo, operações, software, hardware, domínio, logomarcas, emblemas, logotipos, design de páginas e peças publicitárias, estrutura, conteúdos, informações, Ferramentas do Programa, Símbolos e Expressões, que caracterizem os serviços e a marca DESCONTINHO CLUB, são de única e exclusiva propriedade do DESCONTINHO CLUB e em nenhum caso se entenderá que ao Usuário é concedido algum tipo de direito sobre os mesmos ou o seu uso sem autorização expressa e por escrito do representante legal do DESCONTINHO CLUB.</p>

            <b>Da Proteção de Dados</b>
            <p>O DESCONTINHO CLUB processará e utilizará os dados dos Usuários obtidos e necessários para a prestação dos serviços, os quais estarão protegidos de acordo com as regras da política de privacidade do DESCONTINHO CLUB.</p>
            <p>O DESCONTINHO CLUB tomará todas as medidas possíveis para manter a confidencialidade e a segurança descritas nesta cláusula, porém, não responderá por prejuízo que possa ser derivado da violação dessas medidas por terceiros que utilizem as redes públicas ou a internet para acessar as informações de Usuários.</p>

            <b>Garantias e Responsabilidade</b>
            <p>O DESCONTINHO CLUB garante e se responsabiliza pelo serviço de intermediação das modalidades de Benefícios disponibilizadas aos Usuários pelo restaurante participante. O Usuário reconhece e concorda que a remuneração paga ao DESCONTINHO CLUB se refere apenas ao serviço de registro da opção escolhida pelo Usuário e a transmissão ao restaurante participante escolhido, sendo certo que, o DESCONTINHO CLUB não possui nenhum controle e não poderá ser responsabilizado pelos serviços prestados pelo restaurante participante ao Usuário. Esses serviços serão prestados pelo restaurante participante em seu próprio nome e por sua conta e risco. Somente o restaurante participante, escolhido pelo Usuário, será responsável pelo cumprimento da opção registrada, bem como pela concessão do mencionado desconto. Ainda assim, o DESCONTINHO CLUB se compromete a assistir o Usuário em casos de problemas na utilização da opção feitas com a intermediação do DESCONTINHO CLUB. Nesses casos, os Usuários deverão entrar em contato com o DESCONTINHO CLUB, através do e-mail: contato@descontinho.club.</p>

            <b>Da Cessão de Direitos</b>
            <p>O Usuário declara-se ciente de que o DESCONTINHO CLUB poderá transferir a terceiros, total ou parcialmente, os seus direitos e deveres decorrentes destes Termos de Uso, incluindo toda a sua base de dados de Usuários, principalmente em caso de qualquer alteração em sua composição societárias, mediante simples comunicação ao Usuário, o qual concorda, desde já, com a adesão automática aos termos e condições de uso da nova empresa que porventura assuma o DESCONTINHO CLUB.</p>

            <b>Das Alterações dos Termos de Uso</b>
            <p>O DESCONTINHO CLUB reserva-se o direito de alterar os presentes Termos de Uso a qualquer momento, a seu exclusivo critério, sempre que julgar necessário, visando o aprimoramento e melhoria dos serviços prestados, por motivos legais, regulatórios e técnicos, ou devido a mudanças nos serviços fornecidos e/ou layout da Plataforma. O novo teor dos Termos de Uso entrará em vigor no prazo de 24 (vinte e quatro) horas contadas da publicação das modificações na Plataforma, de modo que o Usuário deverá se manifestar por e-mail, caso não concorde com as alterações realizadas. Neste caso, o vínculo contratual deixará de existir, desde que não haja débitos em aberto em nome do Usuário. Não havendo manifestação no prazo estipulado, entender-se-á que o Usuário aceitou tacitamente as novas condições dos Termos de Uso e o contrato continuará vinculando as partes para todos os fins.</p>
            <p>Caso o Usuário não esteja de acordo com as alterações, este poderá simplesmente cessar a utilização dos serviços, a qualquer momento, sem qualquer ônus ou encargo.</p>

            <b>Opiniões dos Usuários</b>
            <p>O Usuário, por meio deste documento, concede ao DESCONTINHO CLUB o direito de exibir na Plataforma do DESCONTINHO CLUB as avaliações e comentários feitos a respeito das experiências e usos da Assinatura nos restaurantes participantes.</p>
            <p>As avaliações e comentários das experiências do Usuário deverão estar em consonância com as regras listadas a seguir. Os Usuários responderão pelo conteúdo e opiniões expressadas nas avaliações.</p>

            <b>Condições para a publicação de opiniões:</b>
            <p>São requisitos cumulativos para a publicação de opinião: ter capacidade civil plena, ou seja, ter 18 anos completos ou mais; ter cadastro ativo no CHEFSCLUB; ter utilizado uma Assinatura ativa no restaurante participante.</p>
            <p>Para evitar qualquer conflito de interesse e por razões evidentes de objetividade, os Usuários que trabalham em restaurantes participantes ou afins não poderão ter suas avaliações publicadas na Plataforma.</p>
            <p>A opinião deve restringir-se ao restaurante participante visitado pelo usuário.</p>
            
            <b>O DESCONTINHO CLUB poderá, discricionariamente, proibir a publicação da avaliação do Usuário na ocorrência de quaisquer das seguintes hipóteses:</b>

            <ul>
                <li>Não adesão do usuário às "Condições para publicação de opiniões" acima;</li>
                <li>Possibilidade de o DESCONTINHO CLUB ser responsabilizado civil ou criminalmente;</li>
                <li>Texto inclui insultos ou termos vulgares;</li>
                <li>Dados de identificação do usuário contém insultos ou termos vulgares;</li>
                <li>Texto contém símbolos ou sentenças desprovidos de significado;</li>
                <li>Conteúdo (texto, documento, imagem, etc.) não está relacionado ao assunto em discussão;</li>
                <li>Opinião contém um conflito de interesse específico;</li>
                <li>Texto é ininteligível;</li>
                <li>Usuário fez um comentário inapropriado sobre outra pessoa ou a opinião dela;</li>
                <li>Conteúdo a ser publicado contém dados pessoais, tais como nome e sobrenome de indivíduos que não são pessoas públicas, um número de telefone, um endereço físico ou e-mail específico;</li>
                <li>Texto contém um número de cartão de crédito, CPF, conta de banco ou qualquer outro dado que facilite ou possibilite apropriação indevida de identidade;</li>
                <li>Texto inclui convocação para ação legal;</li>
                <li>Conteúdo contém menções a sites, hyperlinks, URLs, endereços de e-mail ou números de telefone;</li>
                <li>Texto é claramente um spam;</li>
                <li>Texto contém qualquer comentário racista ou preconceituoso sobre assuntos relacionados ao grupo social, raça, classe econômica, religião ou orientação sexual de um indivíduo.</li>
            </ul>
            <p>Caso os usuários publiquem conteúdo falso, o DESCONTINHO CLUB, após proceder à recusa ou eliminação da avaliação, poderá cancelar o registro do Usuário e apagar todas as suas avaliações.</p>

            <b>Moderação das Avaliações</b>
            <p>As avaliações serão moderadas pelo DESCONTINHO CLUB de modo a garantir sua concordância com as condições gerais listadas acima e serão publicadas, rejeitadas ou apagadas, conforme decisão do DESCONTINHO CLUB. Toda publicação será moderada por uma pessoa física.</p>
            <p>O DESCONTINHO CLUB poderá exibir as avaliações pelo prazo de até 3 anos, contados do dia da publicação. Caso o restaurante encerre as suas atividades ou o seja alterado o titular de sua propriedade, as publicações a seu respeito poderão ser apagadas antes do prazo acima indicado. Para simplificar a leitura das publicações pelos Usuários do DESCONTINHO CLUB considera-se autorizada pelos Usuários do DESCONTINHO CLUB a inclusão, ao lado da publicação, dos seguintes dados: data da experiência/uso da Assinatura, nome e primeira letra do sobrenome do Usuário. Os usuários poderão acessar, alterar, retificar e eliminar seus dados pessoais, bem como opor-se ao uso deles, caso haja razões legítimas. </p>

            <b>Das Disposições Gerais</b>
            <p>Se qualquer previsão do presente Termo de Uso for tida como inválida ou inexecutável em conformidade com a lei aplicável, o dispositivo inválido ou inexequível será considerado substituído por um dispositivo válido e exequível que se equipare da melhor maneira possível à intenção do dispositivo original e todos os termos e condições restantes continuarão em pleno vigor e efeito.</p>

            <b>Diversos</b>
            <p>Para auxiliar o usuário com dificuldade sobre a assinatura, cancelamento, utilização ou qualquer outra dúvida, o DESCONTINHO CLUB manterá ativo o canal de atendimento de segunda a sexta (exceto feriados) no intervalo entre 8h e 18h através do site.</p>

            <b>Foro e Legislação Aplicável</b>
            <p>Todos os itens deste termo serão regidos pelas leis da República Federativa do Brasil. Para todos os assuntos referentes à sua interpretação e cumprimento, as partes se submeterão ao foro da cidade de Palmas-TO, exceção feita a reclamações apresentadas por usuários que se enquadrarem no conceito legal de consumidores, que poderão submeter ao foro de seu domicílio.</p>

        </Container>
    );
}

export default TermsAndConditions;