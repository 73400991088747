import styled from "styled-components";

export const Container = styled.main`

    display: grid;
    grid-template-areas:
        "header"
        "section";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    & > header {

        grid-area: "header";
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--margin-2x);

        small {
            cursor: pointer;    
        }
    }

    & > section {
        display: grid;
        grid-template-columns: 20% 1fr;
        grid-gap: var(--margin-2x);
        grid-area: "section";

        header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            label {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }
`;