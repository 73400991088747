import React from "react";

import {
    Container
} from "./styles";

interface AsideMenuItemProps extends React.HTMLAttributes<HTMLDivElement>{
}

const AsideMenuItem: React.FC<AsideMenuItemProps> = ({children, ...rest}) => {
    return (
        <Container {...rest}>
            {children}
        </Container>
    );
}

export default AsideMenuItem;