import React from "react";
import { Route, RouteComponentProps, useHistory } from "react-router-dom";

import ManagerConfigCategory from "./Category";
import ManagerConfigTag from "./Tag";

import {
    Container
} from "./styles";

const ManagerConfigRoute : React.FC<RouteComponentProps> = (props) => {

    const history = useHistory();

    return (
        <Container>
            <header>
                <h1>Configurações do Descontinho Club</h1>     
            </header>
            <nav>
                <ul>
                    <li style={ 
                        props.location.pathname === "/manager/config" ||
                        props.location.pathname === "/manager/config/categories"
                            ? {background: "#292c3a"} 
                            : {}
                    } onClick={() => {
                        history.push("/manager/config/categories")
                    }}>Categorias</li>
                    <li style={ 
                        props.location.pathname === "/manager/config/tags" 
                            ? {background: "#292c3a"} 
                            : {}
                    } onClick={() => {
                        history.push("/manager/config/tags")
                    }}>Tags</li>
                </ul>
            </nav>       
            <section>
                <Route path={`${props.match.path}/`}  exact component={ManagerConfigCategory} />
                <Route path={`${props.match.path}/Categories`}  exact component={ManagerConfigCategory} />
                <Route path={`${props.match.path}/Tags`}  exact component={ManagerConfigTag} />
            </section>
        </Container>
    );
}

export default ManagerConfigRoute;