import React, { 
    useCallback 
} from "react";
import { useHistory } from "react-router-dom";

import DrawRegisterCompany from "../../../assets/images/draw_register_company.svg";

import { useAuth } from "../../../context/auth";
import { useErrors } from "../../../context/errors";

import MemberCreate from "../../../components/Member/Create";
import BackButton from "../../../components/BackButton";

import {
    Container
} from "./styles";

import { MemberData } from "../../../models";

const RegisterMember: React.FC<MemberData> = (data) => {
    
    const { signIn } = useAuth();
    const { sendMessages } = useErrors();

    const navigation = useHistory();

    const handleMemberRegistered = useCallback(async (email: string, password: string) => {        

        try {
            await signIn({
                email,
                password
            });
    
            navigation.push("SelectArea");
        }
        catch(err) {
            sendMessages([
                "Você já possui uma conta no Descontinho Club. Acesse a área de Login para acessar criar e acessar seu Estabelecimento"
            ]);
        }
        
    }, [navigation, sendMessages, signIn]); 

    return (
        <Container>
            <BackButton />
            <img src={DrawRegisterCompany} alt="" />
            <h2>Criando uma Conta</h2>
            <h5>Primeiro vamos criar uma conta para você administrar seu estabelecimento</h5>
            <section>
                <MemberCreate 
                    handleMemberRegistered={handleMemberRegistered}
                />
            </section>
        </Container>
    );
}

export default RegisterMember;