import React, { useRef, useState } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import api from "../../../services/api";
import { CategoryData } from "../../../models";
import getValidationsErros from "../../../utils/getValidationsErros";

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import { useAxiosErrors } from "../../../context/axiosErrors";

import {
    Container
} from "./styles";

interface CategoryCreateProps {
    categoryId?: string;
    setVisibility(value: boolean): void;
    handleCategoryRegistered(): void;
}

interface CategoryProps { 
    title: string;
}

const CategoryCreate: React.FC<CategoryCreateProps> = ({categoryId, setVisibility, handleCategoryRegistered}) => {

    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);
    const { sendError } = useAxiosErrors();

    const handleSubmitForm: SubmitHandler<CategoryData> = async (data) => {

        setButtonInProcess(true);

        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                title: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre o Título")
            });

            await schema.validate(data, {
                abortEarly: false
            });

            if(categoryId) {
                data.categoryId = categoryId;
            }

            await api.post("manager/category", data);

            handleCategoryRegistered();
        } catch (err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErros(err));
            }
            else {
                sendError(err);
            }
        }

        setButtonInProcess(false);

    }

    return (
        <Container>
            <h2>Nova Categoria</h2>
            <Form ref={formRef} onSubmit={handleSubmitForm}>
                <Input name="title" placeholder="Título da Categoria" autoFocus />
                <Button type="submit" inProcess={buttonInProcess} styliest="success">Finalizar Cadastro</Button>
            </Form>
            <small onClick={() => setVisibility(false)}>Voltar</small>
        </Container>
    );
}

export default CategoryCreate;