import React, { 
    useRef,
    useState 
} from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../Input";
import InputMask from "../../InputMask";
import Button from "../../Button";

import api from "../../../services/api";
import { useAxiosErrors } from "../../../context/axiosErrors";
import getValidationsErros from "../../../utils/getValidationsErros";

import { ManagerData } from "../../../models";

import { 
    Container
} from "./styles";

interface ManagerCreateProps 
{
    handleManagerRegistered(result: boolean): any;
    setVisibility(value: boolean): any;
}

const ManagerCreate : React.FC<ManagerCreateProps> = (props) => {

    const formRef = useRef<FormHandles>(null);
    
    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);

    const { sendError } = useAxiosErrors();

    const handleSubmit: SubmitHandler<ManagerData> = async (data) => {  

        setButtonInProcess(true);

        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                cpf: Yup.string()
                    .length(14, "CPF incompleto")
                    .required("Entre com o CPF"),
                name: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com o nome"),
                email: Yup.string()
                    .email("E-mail inválido")
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com o e-mail"),
                phoneNumber: Yup.string()
                    .required("Entre com o telefone")
            });

            await schema.validate(data, {
                abortEarly: false
            });

            // data.birthDate = parse(
            //     data.birthDate.toString(),
            //     'dd/MM/yyyy',
            //     new Date()
            // );

            await api
                .post("manager/manager", data)
                .then(async response => {
                    props.handleManagerRegistered(true);
                })
                .catch(err => {    
                    sendError(err);
                });
        }
        catch (err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErros(err));
            }
        }

        setButtonInProcess(false);
    };

    return (
        <Container>
            <h2>Novo Usuário</h2>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <InputMask
                    type="text" 
                    name="cpf" 
                    placeholder="CPF do Gerente" 
                    mask="999.999.999-99"
                    inputMode="numeric"
                />
                <Input 
                    type="text" 
                    name="name" 
                    placeholder="Nome do Gerente" 
                />
                <Input 
                    type="email" 
                    name="email" 
                    placeholder="E-mail do Gerente" 
                />
                <InputMask 
                    type="text" 
                    name="phoneNumber" 
                    placeholder="Telefone do Gerente" 
                    mask="(99) 9 9999-9999"
                />
                <Button 
                    styliest="success" 
                    inProcess={buttonInProcess}
                    type="submit">
                    Finalizar
                </Button>
            </Form>
            
            <small onClick={() => props.setVisibility(false)}>
                Voltar
            </small>
        </Container>
    );
}

export default ManagerCreate;