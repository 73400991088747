import React, {
    useRef,
    useState,
    useCallback,
    useEffect
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import { parse } from "date-fns";

import api from "../../../services/api";
import { get } from "../../../services/promises";

import { useAxiosErrors } from "../../../context/axiosErrors";
import getValidationsErrors from "../../../utils/getValidationsErros";

import Header from "../../Header";
import TextArea from "../../TextArea";
import Select from "../../Select";
import Button from "../../Button";
import InputMask from "../../InputMask";
import InputMaskIntl from "../../InputMaskIntl";
import InputFile from "../../InputFile";
import Avatar from "../../Avatar";
import DiscountDay from "../../DiscountDay";

import {
    Container,
    Form,
    Input,
    Categories,
    Category
} from "./styles";

import { 
    DiscountData,
    DiscountCategoryData, 
    DiscountStatus,
    CategoryData,
} from "../../../models";

interface DiscountUpdateProps {
    discount: DiscountData;
    routeToGetAllCategories: string;
    routeToGetAllCategoriesByCategory: string;
    routeToUpdate: string;
    routeToUpdateImage: string;
    routeToSubmitToApprove?: string;
    routeToGetAllDiscountCategories: string;
    routeToRegisterDiscountCategory: string;
    routeToRemoveDiscountCategory: string;
    inManagerArea: boolean;
    setVisibility(value: boolean): void;
    handleUpdated(): void;
}

interface DiscountImageData {
    file: File;
}

const DiscountUpdate = ({ 
    discount, 
    routeToGetAllCategories, 
    routeToGetAllCategoriesByCategory,
    routeToUpdate, 
    routeToUpdateImage, 
    routeToSubmitToApprove, 
    routeToGetAllDiscountCategories,
    routeToRegisterDiscountCategory,
    routeToRemoveDiscountCategory,
    inManagerArea,
    setVisibility, 
    handleUpdated
} : DiscountUpdateProps) => {

    const formRef = useRef<FormHandles>(null);
    const formUpdateImageRef = useRef<FormHandles>(null);
    const area = inManagerArea ? "manager" : "member";

    const [ 
        imageAvatar,
        setImageAvatar
    ] = useState<string>(discount.imageUriHigh);

    const [ 
        categories, 
        setCategories 
    ] = useState<CategoryData[]>([]);

    const [ 
        subCategories, 
        setSubCategories 
    ] = useState<CategoryData[]>([]);

    const [
        categoriesSelectedies,
        setCategoriesSelectedies
    ] = useState<DiscountCategoryData[]>([]);

    const [
        price,
        setPrice
    ] = useState<number>(discount.price);

    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);
    const [ imageUpdating, setImageUpdating] = useState<boolean>(false);
    
    const { sendError } = useAxiosErrors();

    // eslint-disable-next-line
    async function loadSubCategories(categoryId:string) {
        get(`${routeToGetAllCategoriesByCategory}/${categoryId}`)
            .then(response => setSubCategories(
                response as CategoryData[]
            ))
            .catch(err => { console.log(err); sendError(err); });
    }

    async function loadCategoriesSelectedies() {
        get(`${routeToGetAllDiscountCategories}/${discount.id}`)
            .then(response => setCategoriesSelectedies(
                response as DiscountCategoryData[]
            ))
            .catch(err => { console.log(err); sendError(err); });
    }

    useEffect(() => {
        
        get(`${routeToGetAllCategories}`)
            .then(response => {
                setCategories(response as CategoryData[]);
                console.log(response, routeToGetAllCategories);
                loadCategoriesSelectedies();
            })            
            .catch(err => { console.log(err); sendError(err); });
        
        // eslint-disable-next-line     
    }, []); 

    useEffect(() => {
        if(categoriesSelectedies.length > 0) {
            loadSubCategories(categoriesSelectedies[0].categoryId);
        }
        // eslint-disable-next-line
    }, [categoriesSelectedies]);

    const handleCategoryIsSelected = useCallback((categoryId: string) => {
        return categoriesSelectedies.some(c => 
            c.categoryId === categoryId
        );
    }, [categoriesSelectedies]);

    const handleRemoveAllCategories = useCallback(() => {
        if(categoriesSelectedies && categoriesSelectedies.length > 0)
        {
            categoriesSelectedies.map(async discountCategory => {
                await api
                    .delete(`${routeToRemoveDiscountCategory}/${discountCategory.id}`);
            })   

            setCategoriesSelectedies([]);
        }
    }, [
        categoriesSelectedies,
        routeToRemoveDiscountCategory
    ]);

    const handlRemoveAllSubCategoriesSelectedies = useCallback(() => {
        if(
            subCategories &&
            categoriesSelectedies && 
            categoriesSelectedies.length > 0
        )
        {
            subCategories.map(subCategory => {
                categoriesSelectedies.map(async c => {
                    if(c.categoryId === subCategory.id) {
                        await api
                            .delete(`${routeToRemoveDiscountCategory}/${c.id}`);
                    }

                    return;
                });

                return setCategoriesSelectedies(
                    categoriesSelectedies.filter(c =>
                        c.categoryId !== subCategory.id
                    )
                );
            })
        }
    }, [
        subCategories,
        categoriesSelectedies,
        routeToRemoveDiscountCategory
    ]);

    const handleCategorySelected = useCallback(async (category: CategoryData) => {
        
        try {
            if(category.id) {

                if(!category.categoryId) {
                    handleRemoveAllCategories();
                }
                else {
                    handlRemoveAllSubCategoriesSelectedies();
                }

                await api.post(`${routeToRegisterDiscountCategory}`,{
                    discountId: discount.id,
                    categoryId: category.id
                });
                
                loadCategoriesSelectedies();
            }
        }
        catch(err) {
            sendError(err);
        }
        
        // eslint-disable-next-line
    }, [
        discount, 
        routeToRegisterDiscountCategory, 
        handleRemoveAllCategories,
        sendError
    ]);

    const handleSubmitForm: SubmitHandler<DiscountData> = async (data) => {
        
        setButtonInProcess(true);

        formRef.current?.setErrors({ });

        try {
            data.price = price;

            data.start = parse(
                data.start.toString(),
                'dd/MM/yyyy',
                new Date()
            );

            data.finish = parse(
                data.finish.toString(),
                'dd/MM/yyyy',
                new Date()
            );

            const shape = Yup.object().shape({
                title: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com o Título"),
                description: Yup.string()
                    .max(500, "Máximo de 500 caracteres")
                    .required("Entre com a descrição"),
                price: Yup.string()
                    .required("Entre com o Valor Original"),
                discountPercent: Yup.string()
                    .required("Entre com o percentual de desconto"),
                start: Yup.date()
                    .typeError("Insira uma data válida"),
                finish: Yup.date()
                    .typeError("Insira uma data válida"),
            });

            data.discountPercent = Number(data.discountPercent);

            data.useLimit = Number(data.useLimit);
            data.periodUseType = Number(data.periodUseType);
            data.status = Number(data.status);

            await shape.validate(data, {
                abortEarly: false
            });
            
            await api.put(routeToUpdate, data);

            if(
                routeToSubmitToApprove && 
                discount.status !== DiscountStatus.SendToReview
            ) {
                await api.put(`${routeToSubmitToApprove}/${data.id}`);
            }

            handleUpdated();
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setButtonInProcess(false);
    }

    const handleSubmitFormUpdateImage: SubmitHandler<DiscountImageData> = async (data) => {
        setImageUpdating(true);

        const formData = new FormData();
        const file = data.file;

        formData.append("file", file);

        await api
            .put(routeToUpdateImage, formData)
            .then(() => setImageAvatar(discount.imageUriMedium))
            .catch(err => sendError(err));

        await api
            .get(`${area}/discount/${discount.id}/uriHighQuality`)
            .then(response => setImageAvatar(response.data));

        setImageUpdating(false);
    }

    function handleChange(event: any, value: any, maskedValue: any) {
        event.preventDefault();
        setPrice(value);
    };

    return (
        <Container>
            <Header>
                <h2>Editar Descontinho</h2>
                <menu>
                    <small onClick={() => setVisibility(false)}>Voltar</small>
                    <Button 
                        styliest="success" 
                        inProcess={buttonInProcess}
                        onClick={() => formRef.current?.submitForm()}
                    >
                        {
                            routeToSubmitToApprove && 
                            discount.status !== DiscountStatus.SendToReview
                                ? "Enviar para Aprovação"
                                : "Salvar Alterações"
                        }                        
                    </Button>
                </menu>
            </Header>
            <section>
                <div>
                    <Form 
                        ref={formUpdateImageRef} 
                        onSubmit={handleSubmitFormUpdateImage}
                        encType="multipart/form-data">
                        <Avatar
                            image={imageAvatar}
                            isDiscount={true}
                            inProcess={imageUpdating}
                            isSmall={false}
                        />
                        <small>
                            <InputFile 
                                name="file" 
                                label={discount.imageUriMedium
                                    ? "Alterar Capa"
                                    : "Inserir Capa"
                                }
                                accept="image/jpeg"
                                onChange={() => formUpdateImageRef.current?.submitForm()}
                            />
                        </small>
                    </Form>
                </div>
                <div>
                    <Form ref={formRef} initialData={discount} onSubmit={handleSubmitForm}>
                        <Input 
                            type="hidden"
                            name="id" />
                        <Input 
                            type="hidden"
                            name="status" />
                        <Input 
                            name="title"
                            placeholder="Título"
                            maxLength={100} />
                        <TextArea
                            name="description"
                            placeholder="Regras de uso do descontinho"
                            rows={10}
                            maxLength={500} >
                        </TextArea>                      
                        <menu>
                            <InputMask
                                name="start"
                                mask="99/99/9999"
                                defaultValue={discount.start && new Date(discount.start).toLocaleDateString('pt-br')}
                                placeholder="Início" />
                            <InputMask
                                name="finish"
                                mask="99/99/9999"
                                defaultValue={discount.finish && new Date(discount.finish).toLocaleDateString('pt-br')}
                                placeholder="Término" />
                        </menu>  
                        <menu>
                            <InputMaskIntl 
                                name="price" 
                                handleOnChange={handleChange}
                            />
                            <InputMask
                                name="discountPercent"
                                mask="99"
                                placeholder="Percentual de Desconto" 
                            />
                        </menu>
                        <p>Frequência de uso por assinante</p>
                        <menu>
                            <Input
                                name="useLimit" />                                           
                            <Select name="periodUseType">
                                <option value="4">Na Promoção</option>
                                <option value="1">Por dia</option>
                                <option value="2">Por Semana</option>
                                <option value="3">Por Mês</option>
                            </Select>
                        </menu>
                    </Form>
                </div>
                <div>
                    <p>Categoria do Descontinho</p>   
                    <Categories>
                        {
                            categories &&
                            categories.map(category => (
                                <Category 
                                    key={category.id}
                                    selected={handleCategoryIsSelected(category.id)}
                                    onClick={() => handleCategorySelected(category)}
                                >
                                    {category.title}
                                </Category>
                            ))
                        }
                    </Categories>
                    {
                        subCategories && subCategories.length > 0 ?
                        (
                            <>                     
                                <p>Subcategoria do Descontinho</p>   
                                <Categories>
                                    {
                                        subCategories &&
                                        subCategories.map(subCategory => (
                                            <Category 
                                                key={subCategory.id}
                                                selected={handleCategoryIsSelected(subCategory.id)}
                                                onClick={() => handleCategorySelected(subCategory)}
                                            >
                                                {subCategory.title}
                                            </Category>
                                        ))
                                    }
                                </Categories>
                            </>
                        ) : <small>Não há categorias disponíveis</small>
                    }
                    <b>Configure os dias e horários que seu Descontinho ficará ativo</b>
                    <ul className="discountDaysList">
                        <li>
                            <DiscountDay
                                dayOfWeek={0}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                        <li>
                            <DiscountDay
                                dayOfWeek={1}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                        <li>
                            <DiscountDay
                                dayOfWeek={2}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                        <li>
                            <DiscountDay
                                dayOfWeek={3}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                        <li>
                            <DiscountDay
                                dayOfWeek={4}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                        <li>
                            <DiscountDay
                                dayOfWeek={5}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                        <li>
                            <DiscountDay
                                dayOfWeek={6}
                                discountId={discount.id}
                                inManagerArea={inManagerArea}
                            />
                        </li>
                    </ul>    
                </div>
            </section>
        </Container>
    );
}

export default DiscountUpdate;