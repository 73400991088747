import React from "react";

import { useAuth } from "../../../../context/auth";

import {
    Container 
} from "./styles";

const ManagerProfile: React.FC = () => {

    const { signOut } = useAuth();

    return (
        <Container>
            <header>
                <h1>Seu Perfil</h1>
                <b onClick={() => signOut()}>Sair do Ambiente</b>
            </header>
        </Container>
    );
}

export default ManagerProfile;