import React, { 
    useRef,
    useState 
} from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../../services/api";
import getValidationsErrors from "../../../utils/getValidationsErros";
import { useAxiosErrors } from "../../../context/axiosErrors";

import Input from "../../Input";
import Button from "../../Button";

import {
    Container
} from "./styles";

interface MemberUpdateProps 
{
    setVisibility(value: boolean): any;
}

interface ChangePasswordProps {
    password: string;
    newPassword: string;
    confirmNewPassword: string;
}

const MemberChangePassword = ({ setVisibility } : MemberUpdateProps) => {

    const [ updateInProcess, setUdpateInProcess ] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();

    const handleSubmit: SubmitHandler<ChangePasswordProps> = async (data) => {

        setUdpateInProcess(true);

        try {
            const schema = Yup.object().shape({
                password: Yup.string()
                    .min(6, "Mínimo de 6 caracteres")
                    .required("Entre com a senha"),
                newPassword: Yup.string()
                    .min(6, "Mínimo de 6 caracteres")
                    .required("Entre com sua nova senha"),
                confirmNewPassword: Yup.string()
                    .test('newPassword', "Senhas diferentes", value => value === data.newPassword)
                    .required("Redigite sua nova senha")
            });

            await schema.validate(data, {
                abortEarly: false
            });
            
            await api
                .post('account/changepassword', {
                    password: data.password,
                    newPassword: data.newPassword
                })
                .then(() => toast.success("Senha alterada"))
                .catch(() => toast.error("Senha não alterada"));
            
            setVisibility(false);
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setUdpateInProcess(false);
    }

    return (
        <Container>
            <h2>Altere sua Senha</h2>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <Input 
                    type="password" 
                    name="password" 
                    placeholder="Senha atual" 
                    maxLength={100}
                />
                <Input 
                    type="password" 
                    name="newPassword" 
                    placeholder="Nova senha" 
                    maxLength={100}
                />
                <Input 
                    type="password" 
                    name="confirmNewPassword" 
                    placeholder="Redigite sua nova senha" 
                />
                <Button 
                    styliest="success" 
                    inProcess={updateInProcess}
                    type="submit">
                    Finalizar
                </Button>
            </Form>
            
            <div onClick={() => setVisibility(false)}>
                Voltar
            </div>
        </Container>
    )
}

export default MemberChangePassword;   