import React, { useRef, useState } from "react";
import * as Fi from "react-icons/fi";
import * as Fa from "react-icons/fa";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";

import Input from "../../components/Input";
import InputMask from "../../components/InputMask";
import Button from "../../components/Button";

import logo from "../../assets/images/logo_full_landscape_text_white.svg";
import SmartPhone from "../../assets/images/smartphone_landing.svg";
import WomanSales from "../../assets/images/woman_more_sales.svg";
import TocantinsState from "../../assets/images/tocantins_state.svg";
import WomanWithSmartphone from "../../assets/images/woman_with_smartphone.svg";

import {
    Container,
    Header,
    Content,
    ButtonSuccess,
    CheckSendEmail,
    Frame,
    MoreItems,
    AlertMessage,
    ImageDescription,
    Footer
} from "./styles";

interface FormData {
    user_companyname: string;
    user_name: string;
    user_phoneNumber: string;
    user_email: string;
};

interface ValidationErrors {
    [Key: string]: string;
}

const Home: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [ showConfirmSendEmail, setShowConfirmSendEmail ] = useState<boolean>(false);

    const navigation = useHistory();

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                user_companyname: Yup.string()
                    .max(100)
                    .required("Digite o nome do seu estabelecimento"),
                user_name: Yup.string()
                    .max(100)
                    .required("Digite o nome do Responsável"),
                user_phoneNumber: Yup.string()
                    .required("Digite seu telefone"),
                user_email: Yup.string()
                    .email("E-mail inválido")
                    .required("Digite seu e-mail")
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const emailData = {
                user_companyname: data.user_companyname,
                user_name: data.user_name,
                user_phoneNumber: data.user_phoneNumber,
                user_email: data.user_email
            };

            setShowConfirmSendEmail(true);

            emailjs.send(
                "service_2wjsq0q",
                "template_pydsrgh",
                emailData,
                "user_PwqLY81RCWxccUy9CFIEW"
            ).then(result => {
            }, error => {
                alert("Não foi possível solicitar seu contato");
            });


        } catch(err) {

            if(err instanceof Yup.ValidationError) {
                const validationErrors: ValidationErrors = {};
                
                err.inner.forEach(error  => {            
                    validationErrors[error.path] = error.message;
                });

                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    return (
        <>
            <Container>
                <Header>
                    <img src={logo} alt="Descontinho Club" />
                    <menu>
                        <a href="https://instagram.com/descontinho.club" target="_blank" rel="noopener noreferrer">
                            <Fi.FiInstagram size={25} />
                        </a>
                        <a href="https://fb.com/descontinho.club" target="_blank" rel="noopener noreferrer">
                            <Fi.FiFacebook size={25} />
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5563992029472&text=Quero%20minha%20empresa%20no%20Descontinho%20Club" target="_blank" rel="noopener noreferrer">
                            <Fa.FaWhatsapp size={25} />
                        </a>
                        <Button styliest="info" onClick={() => navigation.push("Account/Logon")}>
                            Área do Estabelecimento
                        </Button>
                    </menu>
                </Header>
                <Content>
                    <div>
                        <h1>BEM-VINDO AO DESCONTINHO CLUB</h1>
                        <p>INTEIRAMENTE <b>GRATUITO</b> PARA SUA EMPRESA</p>
                        <p className="black">GARANTA UM PÚBLICO EXCLUSIVO COM DESCONTOS AGRESSIVOS!</p>
                        <ButtonSuccess styliest="success" onClick={() => navigation.push("Account/RegisterMember")}>
                            Cadastre seu Estabelecimento
                        </ButtonSuccess>
                    </div>
                    <menu>

                        {
                            showConfirmSendEmail
                                ? 
                                <CheckSendEmail>
                                    <Fi.FiCheckCircle size={150} />
                                    <h2>Solicitação enviada com sucesso!</h2>
                                    <small>Falta pouco para uma nova fase do seu estabelecimento</small>
                                </CheckSendEmail>
                                : 
                                <>
                                    <p>Preencha este formulário e para entrarmos em contato com você</p>
                                    <Form ref={formRef} onSubmit={handleSubmit}>
                                        <Input name="user_companyname" placeholder="Nome do seu Estabelecimento" />
                                        <Input name="user_name" placeholder="Nome do Responsável " />
                                        <InputMask name="user_phoneNumber" placeholder="Telefone" mask="(99) 9 9999-9999" inputMode="numeric" />
                                        <Input name="user_email" placeholder="E-mail" inputMode="email" />
                                        <input type="submit" value="Solicitar Contato" />
                                    </Form>
                                </>
                        }
                        
                    </menu>
                </Content>
                <Frame>
                    <iframe 
                        title="Descontinho Club"
                        src="https://www.youtube.com/embed/ICI6GlGxOr4?controls=0" />
                </Frame>
                <MoreItems>                    
                    <ul>
                        <li>
                            <img src={WomanSales} alt="+Vendas" />
                            <h2><Fi.FiPlus /> VENDAS</h2>
                            <b>Que tal um App para te ajudar a vender mais? </b>
                            <p>
                                É rápido, fácil e seguro. Não perca essa oportunidade e conquiste um novo mercado.
                            </p>
                        </li>
                        <li>
                            <img src={TocantinsState} alt="+Visibilidade" />
                            <h2><Fi.FiPlus /> VISIBILIDADE</h2>
                            <b>O seu empreendimento estará presente no dia a dia dos consumidores tocantinenses.</b>
                            <p>
                            O Descontinho Club é um aplicativo de marketing promocional que reafirma a visibilidade de sua marca.
                            </p>
                        </li>
                        <li>
                            <img src={WomanWithSmartphone} alt="+Fidelização" />
                            <h2><Fi.FiPlus /> FIDELIZAÇÃO</h2>
                            <b>Quem não volta em um estabelecimento que oferece bons descontos?</b>
                            <p>
                            Dê essa oportunidade para o seu público-alvo!
                            </p>
                        </li>
                    </ul>
                </MoreItems>
                <AlertMessage>
                    UM CLUBE DE ASSINANTES TODO SEU!
                </AlertMessage>
                <ImageDescription>
                    <article>
                        A SUA EMPRESA NA PALMA DA MÃO DE NOVOS CLIENTES!
                    </article>
                    <img src={SmartPhone} alt="Descontinho Club App" />
                </ImageDescription>
            </Container>
            <Footer>
                <label onClick={() => navigation.push("TermsAndConditions")}>
                    Termos e Condições
                </label>
                <label onClick={() => navigation.push("PrivacyPolicy")}>
                    Política de Privacidade
                </label>
            </Footer>
        </>
    );
}

export default Home;