import React, {
    useState,
    useEffect, 
    useCallback
} from "react";
import * as Fi from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { get } from "../../../../services/promises";

import { useAuth } from "../../../../context/auth";
import { useAxiosErrors } from "../../../../context/axiosErrors";

import Button from "../../../../components/Button";
import List from "../../../../components/List";
import ListItem from "../../../../components/ListItem";
import Header from "../../../../components/Header";
import { Modal } from "../../../../components/Modal";

import CompanyCreate from "../../../../components/Company/Create";
import CompanyDetails from "../../../../components/Company/Details";

import { CompanyData } from "../../../../models";

import {
    Container
} from "./styles";

const MemberHome = () => {

    const [ companies, setCompanies ] = useState<CompanyData[] | null>(null);
    const [ companySelected, setCompanySelected ] = useState<CompanyData>({} as CompanyData);
    const [ showModalCreate, setShowModalCreate ] = useState<boolean>(false);
    const [ showModalDetails, setshowModalDetails ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const { member, setCompany } = useAuth();
    const { sendError } = useAxiosErrors();    

    const navigation = useHistory();

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const loadCompanies = useCallback(() => {
        get("member/company")
            .then(response => {
                setCompanies(response as CompanyData[]);  
                setIsLoading(false);
            })
            .catch(err => sendError(err));

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadCompanies();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!!companySelected.id) {
            setshowModalDetails(true);
        }
    }, [companySelected]);

    useEffect(() => {
        if(!showModalDetails) {
            setCompanySelected({} as CompanyData);
        }
    }, [showModalDetails]);

    const handleRegistered = useCallback(() => {
        loadCompanies();
        setShowModalCreate(false);
    }, [loadCompanies]);

    const goToCompany = useCallback((company?: CompanyData) => {
        setCompany(company);
        navigation.push("/company");
    }, [navigation, setCompany]);

    return (
        <Container>
            <Header>
                <h1>Seus Estabelecimentos</h1>
                <Button styliest="success" onClick={() => setShowModalCreate(true)}>
                    Criar Novo Estabelecimento
                </Button>
            </Header>
            <section>
                { 
                    isLoading ? <>
                        <Skeleton height={60} count={6} style={{marginBottom: 10}} />
                    </> :
                    companies && companies.length > 0 ?
                    <List> {
                        companies.map(company => (
                            <ListItem 
                                key={company.id}>
                                <label className="activeLink" onClick={() =>  goToCompany(company)}>{company.name}</label>
                                {company.cnpj}
                                <menu>
                                    <Fi.FiChevronRight 
                                        size={20}
                                        onClick={() =>  goToCompany(company)} />
                                </menu> 
                            </ListItem>
                        ))
                    }
                    </List> :
                    <p>Você ainda não possui estabelecimentos no Descontinho Club! Cadastre seu primeiro estabelecimento</p>
                }
            </section>
            <Modal 
                visible={showModalCreate} 
                size="medium"
                handleClose={setShowModalCreate}>
                <CompanyCreate 
                    setVisibility={setShowModalCreate}
                    handleCompanyRegistered={handleRegistered}
                    memberId={member?.id}
                />
            </Modal>
            <Modal 
                visible={showModalDetails} 
                size="big"
                handleClose={setshowModalDetails}>
                <CompanyDetails
                    companyMain={companySelected}
                    setVisibility={setshowModalDetails}
                    inManagerArea={false}
                />
            </Modal>
        </Container>
    );
}

export default MemberHome;