import React, { useCallback } from "react";
import { FiShoppingBag, FiUser, FiDollarSign } from "react-icons/fi";

import {
    Container
} from "./styles";

interface Props {
    image: string;
    inProcess?: boolean;
    isCompany?: boolean;
    isDiscount?: boolean;
    isSmall?: boolean;
}

type AvatarProps = JSX.IntrinsicElements["img"] & Props;

const Avatar: React.FC<AvatarProps> = ({image, inProcess, isCompany, isDiscount, isSmall = false, ...rest }) => {

    const getMoment = useCallback(() => {
        var timestamp = new Date().getTime();

        return timestamp;
    }, []);
    
    return (
        <Container inProcess={inProcess} isSmall={isSmall}>
            {
                image 
                    ? <img src={`${image}?t=${getMoment()}`} {...rest} alt="" />
                    : isCompany 
                        ? <FiShoppingBag size={50} /> 
                        : isDiscount ? 
                            <FiDollarSign size={50} /> 
                            : <FiUser size={50} />
            }
        </Container>
    );
}

export default Avatar;