import React from "react";

import Button from "../../Button";

import { CategoryData } from "../../../models";

import { 
    Container
} from "./styles";

interface CategoryRemoveProps {
    category: CategoryData;
    setVisibility(value: boolean): void;
}

const CategoryRemove = ({ category, setVisibility }: CategoryRemoveProps) => {
    return (
        <Container>
            <p>Tem certeza que desejar remover a categoria <b>{category.title}?</b></p>
            <footer>
                <Button styliest="danger">Sim</Button>
                <Button styliest="success" onClick={() => setVisibility(false)}>Não</Button>
            </footer>
        </Container>
    );
}

export default CategoryRemove;