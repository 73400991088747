import styled from "styled-components";

export const Container = styled.main`

    header { 

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        button {
            width: auto;
            margin-top: 0;
        }

    }

    section {
        margin-top: var(--margin-2x);
    }

`;