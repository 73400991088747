import React, { useEffect, useRef } from "react";
import MaskInput, { Props as MaskInputProps } from "react-input-mask";

import { useField } from "@unform/core";

import { 
    Container, 
} from "./styles";

interface Props extends MaskInputProps {
    name: string;
    label?: string;
};

const InputMask: React.RefForwardingComponent<HTMLInputElement, Props> = ({ name, label, ...rest }, ref) => {

    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },
            clearValue(ref: any) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    return (
        <Container>
            { label && <label htmlFor={fieldName}>{label}</label>}

            <MaskInput 
                ref={inputRef}
                defaultValue={defaultValue}
                autoComplete="off"
                {...rest}
            />

            { error && <span>{error}</span>}
        </Container>
    );
};
    
export default React.forwardRef(InputMask);