import React, {
    useRef,
    useState
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { toast } from "react-toastify";

import api from "../../../services/api";
import { CompanyData } from "../../../models";
import { useAuth } from "../../../context/auth";
import { useAxiosErrors } from "../../../context/axiosErrors";

import Avatar from "../../Avatar";
import InputFile from "../../InputFile";

import {
    Container 
} from "./styles";

interface CompanyDetailsProps {
    company: CompanyData;
}

interface CompanyImageData {
    file: File;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({company}) => {

    const [ imageUpdating, setImageUpdating] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();
    const { updateCompany } = useAuth();

    const handleSubmitFormUpdateImage: SubmitHandler<CompanyImageData> = async (data) => {

        setImageUpdating(true);

        const formData = new FormData();
        formData.append('file', data.file);

        await api
            .put(`member/company/${company.id}/updateImage`, formData)
            .then(() => {
                updateCompany();
                toast.success("Logo atualizada");
            })
            .catch(err => sendError(err));

        setImageUpdating(false);
    }

    return (
        <Container>
            <header>
                <Avatar 
                    image={company.imageUriHigh}
                    inProcess={imageUpdating}
                    isCompany={true } />
                <div>
                    <h3>{company.name}</h3>
                    <p>{company.email}</p>
                    <p>{company.phoneNumber}</p>
                    <br />
                    <Form 
                        ref={formRef} 
                        onSubmit={handleSubmitFormUpdateImage}
                        encType="multiform/form-data">
                        <InputFile 
                            name="file" 
                            label="Alterar logo do estabelecimento"
                            accept="image/jpeg"
                            onChange={() => formRef.current?.submitForm()}
                        />
                    </Form>
                </div>
            </header>
        </Container>
    );
}

export default CompanyDetails;