import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { 
    Container
} from "./styles";

interface Props {
    name: string;
    label?: string;
}

type TextAreaProps = JSX.IntrinsicElements['textarea'] & Props;

const TextArea: React.FC<TextAreaProps> = ({ name, label, ...rest }) => {
    
    const textaAreaRef = useRef<HTMLTextAreaElement>(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: textaAreaRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <Container>
            { label && <label htmlFor={fieldName}>{label}</label>}

            <textarea 
                id={fieldName}
                ref={textaAreaRef}
                defaultValue={defaultValue}
                {...rest}
            />

            { error && <span>{error}</span>}
        </Container>
    );
};

export default TextArea;