import styled from "styled-components";

import waves from "../../assets/images/waves.svg";
import persons_background_logon from "../../assets/images/persons_background_logon.svg";
import icons_background_logon from "../../assets/images/icons_background_logon.svg";

export const Container = styled.main`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: 
        url(${waves}) center no-repeat,
        url(${persons_background_logon}) center no-repeat,
        url(${icons_background_logon}) center no-repeat,
        var(--color-black-ligther); 

    & > section {        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(
            180deg, 
            var(--color-primary), 
            var(--color-secundary),
            var(--color-tertiary)
        );
        box-shadow: 5px 10px 30px rgba(0, 0, 0, .3);
        border-radius: 1rem;
        margin: 5rem;   
        padding: 6rem;
        color: var(--color-white-original);
        max-width: 80%;
    }

    @media (max-width: 768px) {
        background-size: cover;
        padding: 0rem;
        overflow: scroll;

        & > section {
            padding: 4rem;
            margin: 0;            
            max-width: 100%;
            min-height: 100vh;
            border-radius: 0;
        }
    }
`;