import styled, { css } from "styled-components";

interface ContainerContentProps {
    size: 
        "small" | 
        "medium" | 
        "big" | 
        "max";
}

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: var(--rgba-black-ligther);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; 
    overflow-y: auto;
    animation: openContainer 1s;

    section {
        margin-top: 0px;
    }

    @keyframes openContainer {
        to { background: var(--rgba-black-ligther)}
        from { background: var(--rgba-black-ligther-opactity-start)}
    }
`;

export const ContainerContent = styled.section<ContainerContentProps>`
    
    position: relative;

    background: var(--color-black-ligther);
    box-shadow: 0px 0px 3rem rgba(0, 0, 0, .3);
    border-radius: 1rem;
    padding: 5rem; 
    animation: open 1s;
    color: #f7f7f7;
    width: ${props => 
        props.size === "small" 
            ? "25vw" 
            : props.size === "medium" 
                ? "50vw" 
                : "85vw"};
    max-width: 100%;
    max-height: 100%;   
    position: absolute;
    overflow-y: auto;
    
    ::-webkit-scrollbar {
        display: none;
    }

    ${props => props.size === "max" && css`
        height: 100vh;
        width: 100vw;
        max-height: 100vh;
        max-width: 100vw;
    `}

    @media (max-width: 768px) {
        width: 95%;
        height: 95%;
    }
`;

export const ButtonClose = styled.div`

    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
    
    right: 0;
    top: 0;

    width: 4rem;
    height: 4rem;

    background-color: var(--color-tertiary);

    border-bottom-left-radius: var(--border-radius-2x);

    cursor: pointer;

    transition: opacity .2s;

    :hover {
        opacity: .8;
    }

    svg {
        height: 2rem;
    }
`;

