import React, {
    useCallback
} from "react";

import api from "../../../services/api";

import Button from "../../../components/Button";

import {
    Container
} from "./styles";

interface ManagerRemoveProps {
    id?: string,
    name?: string,
    setVisibility(value: boolean): any
    handleManagerRemoved(value: boolean): any
}

const ManagerRemove : React.FC<ManagerRemoveProps> = (props) => {

    const handleRemoveManager = useCallback(async () => {
        await api.delete(`manager/${props.id}`).then(response => {
            props.handleManagerRemoved(true);
        }).catch(error => 
            console.log("Gerente não removido")
        );
    }, [props]);

    return (
        <Container>
            <p>Tem certeza que seja remover o Gerente <b>{props.name}</b>?</p>
            <menu>
                <Button 
                    styliest="danger"
                    onClick={() => handleRemoveManager()}
                >
                    Tenho Certeza
                </Button>
                <Button 
                    styliest="info"
                    onClick={() => props.setVisibility(false)}
                >
                    Cancelar
                </Button>
            </menu>
        </Container>
    )
}

export default ManagerRemove;