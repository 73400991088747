import styled from "styled-components";

export const Container = styled.section`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    a {
        cursor: pointer;
        font-weight: bold;
    }

    form {
        margin: 2rem auto;

        div {
            margin: 1rem auto;
        }

        button {
            width: 100%;
        }
    }

    & > div {
        display: flex;
        align-items: center;
        cursor: pointer;

        :hover {
            svg {
                margin-right: 1rem;
            }
        }

        svg {
            margin-right: .3rem;
            transition: margin-right .3s;
        }
    }
`;