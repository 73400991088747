import React from "react";
import { 
    Route, 
    RouteComponentProps, 
    Redirect, 
    useHistory 
} from "react-router-dom";
import * as Fi from "react-icons/fi";

import { useAuth } from "../../context/auth";
import { CompanyData } from "../../models";

import Avatar from "../../components/Avatar";

import Home from "./pages/Home";
import Discount from "./pages/Discount";
import Profile from "./pages/Profile";

import logo from "../../assets/images/logo_lighter.svg";

import {
    Container,
    Menu,
    Content
} from "./assets/styles/global";

interface CompanyRouteProps extends RouteComponentProps {
    company: CompanyData;
}

const CompanyRoute : React.FC<CompanyRouteProps> = (props) => {
    
    const { company } = useAuth();
    const history = useHistory();
    
    const pathname = props.location.pathname;

    return (
        company != null ? (
            <Container>
                <Menu>
                    <menu>
                        <ul>
                            <li onClick={() => history.push("/account/selectarea")}>
                                <img src={logo} alt="Descontinho Club" />
                            </li>
                            <li
                                style={
                                    pathname === "/company"
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/company")
                                }
                            >
                                <Fi.FiPlay size={25} />
                            </li>
                            <li
                                style={
                                    pathname === "/company/discount"
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/company/discount")
                                }
                            >
                                <Fi.FiDollarSign size={25} />
                            </li>
                        </ul>
                    </menu>
                    <article
                        style={
                            pathname.includes("/company/profile")
                                ? {backgroundColor: '#292c3a'}
                                : {}
                        } 
                        onClick={() => 
                            history.push("/company/profile")
                        }
                    >
                        <Avatar image={company.imageUriLow} isCompany={true} />
                    </article>
                </Menu>
                <Content>
                    <Route path={`${props.match.path}`} exact component={Home} />
                    <Route path={`${props.match.path}/Discount`} component={Discount} />
                    <Route path={`${props.match.path}/Profile`} component={Profile} />
                </Content>
            </Container>       
        ) : (
            <Redirect to="/account/logon" />
        )
    );
}

export default CompanyRoute;