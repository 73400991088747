import styled from "styled-components";

export const Container = styled.div`

    width: 350px;
    max-width: 100%;

    img {
        height: 4rem;
        margin-bottom: 2rem;
    }

    section {

        text-align: center;

        div { 
            margin: 1.2rem 0;

            input { 
                text-align: center;
                color: var(--color-white-original);
            }

            span {
                color: var(--color-white-adjusted);
            }
        }

        button {
            width: 100%;
            margin-top: 0.7rem;
            margin-bottom: 2rem;
            border: 0;
        }

        a {
            color: var(--color-white-original);
            font-size: 1.3rem;
        }
    }
     
    footer {
        margin-top: 3rem;

        button {
            width: 100%;
        }
    }
`;