import styled from "styled-components";

import { Container as Avatar } from "../../../../components/Avatar/styles";

export const Container = styled.div`
    height: 100vh;
    width: 100vw;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "menu content";

    background: var(--color-black-ligther);

    @media (max-width: 768px) {
        grid-template-rows: auto calc(25px + 4rem);
        grid-template-columns: 100%;
        grid-template-areas: 
            "content" 
            "menu";
    }
`;

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    grid-area: menu;
    background: linear-gradient(
        45deg, 
        var(--color-primary), 
        var(--color-secundary), 
        var(--color-tertiary)
    );
    width: 100%;

    menu {
        text-align: center;
    
        ul {
            li {
                display: flex;
                justify-content: center;
                text-align: center;
                color: var(--color-white-adjusted);
                padding: 2rem;
                
                :hover {              
                    background: var(--color-black-ligther);      
                    cursor: pointer;
                    font-weight: 700;

                    a {
                        color: var(--color-white-adjusted);
                    }
                }

                & > img { 
                    text-align: center;
                    max-width: 100%;
                }

                a {
                    color: var(--color-white-adjusted);
                }
            }
        }
    }

    article {
        
        display: flex;  
        align-items: center;
        padding: 2rem;
        font-weight: 700;
    
        :hover {
            background: var(--color-black-ligther);
            color: var(--color-white-adjusted);
            cursor: pointer;
            font-weight: 700;
        }

        ${Avatar}{
            height: 3rem;
            width: 3rem;
            border: none;
            color: var(--color-white-adjusted);
            background: transparent;
        }
    }
    
    @media (max-width: 768px) {
        flex: auto;
        flex-direction: row;

        menu {
            ul {
                display: flex;
                flex-direction: row;

                li > img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
`;

export const Content = styled.div`
    height: 100vh;
    width: 100%;
    color: var(--color-white-adjusted);
    grid-area: content;
    padding: 4rem;
    overflow-y: scroll;

    a {
        color: var(--color-white-adjusted);
    }

    @media (max-width: 768px) {
        height: auto;
    }
`;
