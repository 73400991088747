import styled from "styled-components";

export const Container = styled.main`
    
    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        & > div {
            margin-left: var(--margin-2x);
        }
    }

    @media (max-width: 768px) {
        header {
            flex-direction: column;
        }
    }

`;