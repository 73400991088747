import React from "react";
import SkeletonModel from "react-loading-skeleton";

const Skeleton : React.FC = () => {
    return (
        <SkeletonModel 
            height={60} 
            count={6} 
            style={{marginBottom: "1rem", opacity: .5}} 
        />
    );
}

export default Skeleton;