import styled, { css } from "styled-components";

interface ContainerProps {
    styliest: 
        "success" | 
        "info" | 
        "warning" | 
        "danger"
}

export const Container = styled.div<ContainerProps>`
    font-size: 10pt;
    padding: .3rem .8rem;
    border-radius: var(--border-radius);
    color: var(--color-white-original);
    ${props => 
        props.styliest === "success" 
            ? css`
                background: var(--color-success);
                color: var(--color-black-ligther);
            ` 
            : props.styliest === "info" 
                ? css`
                    background: var(--color-info)
                `      
                : props.styliest === "warning"
                    ? css`
                        background: var(--color-warning);
                        color: var(--color-black-original);
                    `
                    : css`
                        background: var(--color-danger)
                    `
    }
`;