import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Fi from "react-icons/fi";
import { useHistory } from "react-router-dom";

import imgProductPhotografy from "~/../../src/assets/images/product_photography.svg";

import hubConnection from "../../../../services/HubSignalR";
import api from "../../../../services/api";
import { useAxiosErrors } from "../../../../context/axiosErrors";
import { useAuth } from "../../../../context/auth";

import Input from "../../../../components/Input";
import { Modal } from "../../../../components/Modal";
import Button from "../../../../components/Button";
import List from "../../../../components/List";
import ListItem from "../../../../components/ListItem";

import { 
    CustomerDiscountStatus,
    CustomerDiscountData, 
    CompanyData 
} from "../../../../models";

import {
    Container,
    ModalDiscount,
    ContainerWithoutDiscounts
} from "./styles";

const MemberHome: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const navigation = useHistory();

    const [ hasDiscounts, setHasDiscounts ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ codeActive, setCodeActive ] = useState<string>("");
    const [ customerDiscounts, setCustomerDiscounts ] = useState<CustomerDiscountData[]>([]);
    const [ customerDiscount, setCustomerDiscount ] = useState<CustomerDiscountData | null>(null);

    const { sendError } = useAxiosErrors();
    const { company } = useAuth();

    useEffect(() => {
        memberHasDiscounts();
        //eslint-disable-next-line
    }, []);

    async function memberHasDiscounts(): Promise<void> {
        await api.get("member/discount/hasdiscounts").then(response => {
            setHasDiscounts(response.data);
        });
    }

    useEffect(() => {
        if(hasDiscounts) {
            loadCustomerDiscounts();
        }
        //eslint-disable-next-line
    }, [hasDiscounts]);    

    async function loadCustomerDiscounts(): Promise<void> {
        try {
            var response = await api.get(
                `member/customerdiscount/createdTodayByCompany/${(company as CompanyData).id}`
            );

            setCustomerDiscounts(response.data.sort((first: CustomerDiscountData, second: CustomerDiscountData) => {
                return new Date(second.created).getTime() - new Date(first.created).getTime();
            }));
        }
        catch(err) {
            sendError(err)
        }
    }

    const submitForm: SubmitHandler = async (data) => {

        try {
            const customerDiscount = await api.get(
                `member/customerdiscount/${data.code}/company/${(company as CompanyData).id}`
            );

            setCustomerDiscount(customerDiscount.data as CustomerDiscountData);
            setShowModal(true);
            setCodeActive(data.code);
        }
        catch(err) {
            sendError(err);
        }
    }

    function handleButtonValidateCustomerDiscount (customerDiscount: CustomerDiscountData) {
        setCustomerDiscount(customerDiscount);
        setShowModal(true);
        setCodeActive(customerDiscount.code);
    }

    async function validateCustomerDiscount() {
        try {
            if(customerDiscount == null) {
                return;
            }

            const companyId = (company as CompanyData).id;
            await api.put(
                `member/customerdiscount/${codeActive}/validate?companyId=${companyId}`, 
            );
            
            hubConnection.send(
                "ValidateCustomerDiscount", 
                customerDiscount.customerId,
                codeActive.toUpperCase()
            );

            loadCustomerDiscounts();

            setShowModal(false);
        }
        catch(err) {    
            sendError(err);
        }
    }

    return (
        <> 
            {
                hasDiscounts ?
                    <Container>
                        <header>
                            <div>
                                <h1>Validação de Descontinhos</h1>   
                                <small>Aqui serão exibidos os descontinhos validados no dia</small>
                            </div>
                            <Button 
                                styliest="info" 
                                onClick={() => loadCustomerDiscounts()}>
                                <Fi.FiRefreshCcw />
                            </Button>
                        </header>

                        <section>    
                            {
                                customerDiscounts && customerDiscounts.length > 0 ? 
                                    <List>
                                        {
                                            customerDiscounts.map(customerDiscount => (
                                                <ListItem key={customerDiscount.id}>
                                                    <div>
                                                        <p>Nome</p>
                                                        <b>{customerDiscount.customerName}</b>
                                                    </div>
                                                    <div>
                                                        <p>Código</p>    
                                                        <b>{customerDiscount.code}</b>
                                                    </div>
                                                    <div>
                                                        <p>Descontinho</p>
                                                        <b>{customerDiscount.discountTitle}</b>
                                                    </div>
                                                    <div>
                                                        <p>Horário</p>
                                                        {new Date(customerDiscount.created.toString()).toLocaleTimeString()}
                                                    </div>
                                                    <div>
                                                        { 
                                                            customerDiscount.status === CustomerDiscountStatus.Used
                                                                ? <>
                                                                    <p>Status</p>
                                                                    <b>Validado</b>
                                                                </>
                                                                : <Button 
                                                                    styliest="success" 
                                                                    onClick={() => handleButtonValidateCustomerDiscount(customerDiscount)}
                                                                    >
                                                                    Validar
                                                                </Button>
                                                        }   
                                                    </div>
                                                </ListItem>
                                            ))
                                        }
                                    </List> : 
                                    <div>
                                        Nenhum descontinho validado
                                    </div>
                            }
                        </section>
                        
                        <footer>
                            <Form ref={formRef} onSubmit={submitForm}>    
                                <Input 
                                    name="code"
                                    placeholder="Código do Descontinho"
                                    autoComplete="off"  
                                    autoFocus                      
                                    maxLength={5}
                                />
                            </Form>
                            <div onClick={() => formRef.current?.submitForm()}>
                                <Fi.FiArrowRight size={20} />
                            </div>
                        </footer>
                    </Container> :
                    <ContainerWithoutDiscounts>
                        <img src={imgProductPhotografy} alt="Cadastre seu Descontinho" />
                        <p onClick={() => navigation.push("/company/discount")}>
                            Crie seu primeiro <b>Descontinho</b>
                        </p>
                    </ContainerWithoutDiscounts>
            }                

            <Modal 
                visible={showModal} 
                size="medium"
                handleClose={setShowModal}>
                <ModalDiscount>
                    {
                        customerDiscount?.id ? 
                        <>
                            <header>
                                <h2>{customerDiscount?.customerName}</h2>
                                <p>ativou este descontinho</p>
                            </header>    
                            <section>
                                <img src={customerDiscount?.discountImageUri} alt={customerDiscount?.discountTitle} />
                                <h1>{customerDiscount?.discountTitle}</h1>
                            </section>
                            <footer>
                                <Button styliest="success" onClick={validateCustomerDiscount}>
                                    Validar Descontinho
                                </Button>
                                <small onClick={() => setShowModal(false)}>Voltar</small>
                            </footer>
                        </> :
                        <>
                            <header>
                                <Fi.FiXCircle size={50} />
                                <h2>Nenhum descontinho encontrado</h2>
                            </header>
                            <footer>
                                <small onClick={() => setShowModal(false)}>Voltar</small>
                            </footer>
                        </>
                    }
                </ModalDiscount>
            </Modal>
        </>
    );
}

export default MemberHome;