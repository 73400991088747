import styled from "styled-components";

export const Container = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: var(--color-white-adjusted);
    width: 38px;
    height: 38px;
    border: solid 2px var(--color-white-adjusted);
    border-radius: 50%;
    cursor: pointer;
    transition: 
        background .2s,
        color .2s;

    :hover {
        background: var(--color-white-original);
        color: var(--color-black-ligther);
    }
`;