import styled from "styled-components";

export const Container = styled.main`

    width: 100%;

    form {

        input, select {
            margin-bottom: var(--margin-2x);
        }

        span {
            color: #fff;
        }

        button {
            width: 100%;
        }
    }

`;