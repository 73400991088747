import React, { 
    useRef,
    useState,
    useEffect
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { empty } from "uuidv4";

import api from "../../../services/api";
import { get } from "../../../services/promises";

import { useAxiosErrors } from "../../../context/axiosErrors";

import { TagData, CategoryData } from "../../../models";

import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";

import getValidationsErros from "../../../utils/getValidationsErros";

import { 
    Container
} from "./styles";

interface TagCreateProps {
    handleTagRegistered(): void;
    setVisibility(value: boolean): void;
}

const TagCreate: React.FC<TagCreateProps> = ({setVisibility, handleTagRegistered}) => {

    const [ categories, setCategories ] = useState<CategoryData[] | null>(null);
    
    const formRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();

    async function loadCategories() {        
        get("manager/category")
            .then(response => {
                setCategories(response as CategoryData[]);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadCategories();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitForm: SubmitHandler<TagData> = async (data) => {

        try {
            formRef.current?.setErrors({});

            const scheme = Yup.object().shape({
                title: Yup.string()
                    .required("Entre com o título")
            });

            await scheme.validate(data, {
                abortEarly: false
            });

            await api.post("manager/tag", data);

            handleTagRegistered();

        } catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErros(err));
            }
            else {
                sendError(err);
            }
        }

    }

    return (
        <Container>
            <h3>Nova Tag</h3>
            <Form ref={formRef} onSubmit={handleSubmitForm}>
                <Select name="categoryId">
                    <option value={empty()}>Selecione uma Categoria</option>
                    {
                        categories &&
                        categories.map(category => (
                            <option value={category.id}>{category.title}</option>
                        ))
                    }
                </Select>
                <Input name="title" placeholder="Título da Tag" />
                <Button type="submit" styliest="success">Finalizar</Button>
            </Form>
            <small onClick={() => setVisibility(false)}>Voltar</small>
        </Container>
    );
}

export default TagCreate;