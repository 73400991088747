import styled from "styled-components";

export const Container = styled.main`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    a {
        cursor: pointer;
        font-weight: bold;
    }

    form {
        width: 300px;
        max-width: 100%;
        margin: 2rem auto;
        text-align: center;

        div {
            margin: 1rem auto;
        }

        button {
            width: 100%;
        }
    }

    & > div {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            margin-right: .3rem;
        }
    }
`;