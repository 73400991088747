import React from "react";

import {
    Container
} from "./styles";

const ManagerSponser: React.FC = () => {
    return (
        <Container>
            <header>
                <h1>Patrocinados</h1>
            </header>
        </Container>
    );
}

export default ManagerSponser;