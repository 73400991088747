import React, {
    useRef,
    useState
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import { parse } from "date-fns";

import api from "../../../services/api";
import { DiscountData } from "../../../models";
import { useAxiosErrors } from "../../../context/axiosErrors";
import getValidationsErrors from "../../../utils/getValidationsErros";

import Header from "../../Header";
import TextArea from "../../TextArea";
import Select from "../../Select";
import Button from "../../Button";
import InputMask from "../../InputMask";
import InputMaskIntl from "../../InputMaskIntl";

import {
    Container,
    Form,
    Input
} from "./styles";

import { 
    CompanyData
} from "../../../models";

interface DiscountCreateProps {
    company: CompanyData;
    setVisibility(value: boolean): void;
    handleRegistered(): void;
}

const DiscountCreate = ({ company, setVisibility, handleRegistered}: DiscountCreateProps) => {

    const formRef = useRef<FormHandles>(null);

    const [ price, setPrice ] = useState<string>();
    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);
    
    const { sendError } = useAxiosErrors();

    const handleSubmitForm: SubmitHandler<DiscountData> = async (data) => {
        
        setButtonInProcess(true);

        formRef.current?.setErrors({ });

        try {

            data.start = parse(
                data.start.toString(),
                'dd/MM/yyyy',
                new Date()
            );

            data.finish = parse(
                data.finish.toString(),
                'dd/MM/yyyy',
                new Date()
            );

            const shape = Yup.object().shape({
                title: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com o Título"),
                description: Yup.string()
                    .max(500, "Máximo de 500 caracteres")
                    .required("Entre com a descrição"),
                price: Yup.string()
                    .required("Entre com o Valor Original"),
                discountPercent: Yup.string()
                    .required("Entre com o percentual de desconto"),
                start: Yup.date()
                    .typeError("Insira uma data válida"),
                finish: Yup.date()
                    .typeError("Insira uma data válida"),
            });

            data.price = Number(price);
            data.discountPercent = Number(data.discountPercent);

            data.useLimit = Number(data.useLimit);
            data.periodUseType = Number(data.periodUseType);

            await shape.validate(data, {
                abortEarly: false
            });

            await api.post("discount", data);

            handleRegistered();
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setButtonInProcess(false);
    }

    function handleUpdatePrice(event: any, value: any, maskedValue: any)
    {
        event.preventDefault();
        setPrice(value);
    }

    return (
        <Container>
            <Header>
                <h2>Novo Descontinho</h2>
                <menu>
                    <small onClick={() => setVisibility(false)}>Voltar</small>
                    <Button 
                        styliest="success" 
                        inProcess={buttonInProcess}
                        onClick={() => formRef.current?.submitForm()}
                    >
                        Continuar
                    </Button>
                </menu>
            </Header>
            <Form ref={formRef} onSubmit={handleSubmitForm}>
                <div>
                    <Input type="hidden" name="companyId" defaultValue={company.id} />
                    <Input 
                        name="title"
                        placeholder="Título"
                        maxLength={100} />
                    <TextArea
                        name="description"
                        placeholder="Descrição do descontinho"
                        rows={10}
                        maxLength={500}></TextArea>     
                </div>
                <div>

                <menu>                        
                    <InputMaskIntl
                        name="price"
                        handleOnChange={handleUpdatePrice}
                    />
                    <InputMask
                        name="discountPercent"
                        mask="99"
                        placeholder="Percentual de Desconto" />
                </menu>
                <p>Período de Vigência do desconto</p>
                <menu>
                    <InputMask
                        name="start"
                        mask="99/99/9999"
                        placeholder="Início" />
                    <InputMask
                        name="finish"
                        mask="99/99/9999"
                        placeholder="Término" />
                </menu>
                <p>Frequência de uso por assinante</p>
                <menu>
                    <Input
                        name="useLimit"
                        defaultValue="1" />                                           
                    <Select name="periodUseType">
                        <option value="4">Na Promoção</option>
                        <option value="1">Por dia</option>
                        <option value="2">Por Semana</option>
                        <option value="3">Por Mês</option>
                    </Select>
                </menu>
            </div>    
        </Form>
        </Container>
    );
}

export default DiscountCreate;