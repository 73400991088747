import styled from "styled-components";

export const Container = styled.main`

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        form {
            flex:1;
            margin-left: var(--margin-2x);
            margin-right: var(--margin-2x);

            input {
                text-align: center;
                width: 100;
            }
        }
    }

    main {
        margin-top: var(--margin);
    }

`;