import React, {
    useRef,
    useState
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";
import { MemberData } from "../../../models";
import { useAuth } from "../../../context/auth";
import { useAxiosErrors } from "../../../context/axiosErrors";

import Avatar from "../../Avatar";
import InputFile from "../../InputFile";

import {
    Container 
} from "./styles";

interface MemberDetailsProps {
    member: MemberData;
}

interface MemberImageData {
    file: File;
}

const MemberDetails: React.FC<MemberDetailsProps> = ({member}) => {

    const [ imageUpdating, setImageUpdating] = useState<boolean>(false);

    const formRef = useRef<FormHandles>(null);

    const { sendError } = useAxiosErrors();
    const { updateMember } = useAuth();

    const handleSubmitFormUpdateImage: SubmitHandler<MemberImageData> = async (data) => {

        setImageUpdating(true);

        const formData = new FormData();
        const file = data.file;

        formData.append("file", file);
        
        await api.put(`member/account/updateImage`, formData).then(() => {
            updateMember();
        }).catch(err => sendError(err));

        setImageUpdating(false);
    }

    return (
        <Container>
            <header>
                <Avatar 
                    image={member.imageUriHigh}
                    inProcess={imageUpdating} />
                <div>
                    <h3>{member.name}</h3>
                    <p>{member.email}</p>
                    <p>{member.phoneNumber}</p>
                    <br />
                    <Form ref={formRef} onSubmit={handleSubmitFormUpdateImage}>
                        <InputFile 
                            name="file" 
                            label="Alterar Imagem de Perfil"
                            accept="image/jpeg"
                            onChange={() => formRef.current?.submitForm()}
                        />
                    </Form>
                </div>
            </header>
        </Container>
    );
}

export default MemberDetails;