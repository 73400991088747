import { createGlobalStyle } from "styled-components";

import MagallanesThinWoff from "../fonts/magallanes_essential_light.woff";
import MagallanesThinWoff2 from "../fonts/magallanes_essential_light.woff2";
import MagallanesWoff from "../fonts/magallanes_essential.woff";
import MagallanesWoff2 from "../fonts/magallanes_essential.woff2";
import MagallanesBoldWoff from "../fonts/magallanes_essential_black.woff";
import MagallanesBoldWoff2 from "../fonts/magallanes_essential_black.woff2";

export const GlobalStyle = createGlobalStyle`

  @font-face {
      font-family: 'Magallanes';
      src: 
        local('Magallanes Essential'), 
        local('Magallanes-Essential'),
        url(${MagallanesThinWoff2}) format('woff2'),
        url(${MagallanesThinWoff}) format('woff');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Magallanes';
      src: 
        local('Magallanes Essential'), 
        local('Magallanes-Essential'),
        url(${MagallanesWoff2}) format('woff2'),
        url(${MagallanesWoff}) format('woff');
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      font-family: 'Magallanes';
      src: 
        local('Magallanes Essential'), 
        local('Magallanes-Essential'),
        url(${MagallanesBoldWoff2}) format('woff2'),
        url(${MagallanesBoldWoff}) format('woff');
      font-weight: 700;
      font-style: normal;
      word-wrap: break-word;
  }

  :root {
    font-size: 60%;

    --color-white-original: #FFF;
    --color-white-adjusted: #f7f7f7;
    --color-gray-light: #c4c4c4;
    --color-primary: #6962e9;
    --color-secundary: #b82db7;
    --color-tertiary: #d90e65;
    --color-success: #6EB257;
    --color-warning: #C5E063;
    --color-danger: #d90e65;
    --color-info: #537D8D;
    --color-black-ligther: #292c3a;
    --rgba-white-original: rgba(255, 255, 255, 0.7);
    --rgba-black-ligther-opactity-start: rgba(41, 44, 58, 0);
    --rgba-black-ligther: rgba(41, 44, 58, 0.7);
    --rgba-black-transparent: rgba(0, 0, 0, .2);
    
    --border-radius: .5rem;
    --border-radius-2x: 1rem;
    --padding-inner: 1.5rem;
    --padding-inner-max: 3rem;
    --margin: 1rem;
    --margin-2x: 2rem;
    --padding: 1rem;
    --padding-2x: 2rem;
    --box-shadow: 5px 5px 5px rgba(0, 0, 0, .4);
  }

  * {
      margin: 0;
      padding: 0;     
      outline: 0; 
      box-sizing: border-box;
      overflow-wrap: break-word;
      -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    height: 100vh;
  }

  body {
    background: #000;
  }

  body, input, select, textarea, button{
    font: 400 1.7rem "Magallanes";
    letter-spacing: .02rem;
  }

  h1, h2, h3, h4, h5 {
    letter-spacing: .1rem;
  }

  a {
    text-decoration: none;
  }

`;