import React from "react";
import { 
    Route, 
    RouteComponentProps, 
    Redirect, 
    useHistory 
} from "react-router-dom";
import * as Fi from "react-icons/fi";

import { useAuth } from "../../context/auth";

import Avatar from "../../components/Avatar";

import Home from "./pages/Home";
import Profile from "./pages/Profile";

import logo from "../../assets/images/logo_lighter.svg";

import {
    Container,
    Menu,
    Content
} from "./assets/styles/global";

const MemberRoute : React.FC<RouteComponentProps> = (props) => {

    const { member } = useAuth();
    const history = useHistory();
    
    const pathname = props.location.pathname;

    return (
        member != null ? (
            <Container>
                <Menu>
                    <menu>
                        <ul>
                            <li onClick={() => history.push("/account/selectarea")}>
                                <img src={logo} alt="Descontinho Club" />
                            </li>
                            <li
                                style={
                                    pathname === "/member"
                                        ? {backgroundColor: '#292c3a'}
                                        : {}
                                } 
                                onClick={() => 
                                    history.push("/member")
                                }
                            >
                                <Fi.FiShoppingCart size={25} />
                            </li>
                        </ul>
                    </menu>
                    <article
                        style={
                            pathname.includes("/member/profile")
                                ? {backgroundColor: '#292c3a'}
                                : {}
                        } 
                        onClick={() => 
                            history.push("/member/profile")
                        }
                    >
                        <Avatar image={member.imageUriMedium} />
                    </article>
                </Menu>
                <Content>
                    <Route path={`${props.match.path}`} exact component={Home} />
                    <Route path={`${props.match.path}/Profile`} exact component={Profile} />
                </Content>
            </Container>       
        ) : (
            <Redirect to="/account/logon" />
        )
    );
}

export default MemberRoute;