import React, {
    useEffect
} from "react";
import { useHistory } from "react-router-dom";
import * as Fi from "react-icons/fi";
import * as Ri from "react-icons/ri";

import { useAuth } from "../../../context/auth";

import {
    Container
} from "./styles";

const AccountSelectArea: React.FC = () => {

    const history = useHistory();
    const { token, member, manager } = useAuth();

    useEffect(() => {        
        if(token == null) {
            history.push("/account/logon");
        }
        //eslint-disable-next-line
    }, []);

    return (
        <Container>
            <h1>SELECIONE A ÁREA DESEJADA</h1>
            <ul>
                {
                    manager &&
                    <li onClick={() => history.push("/manager")}>
                        <Fi.FiSliders size={40} />
                        Área <br /> Administrativa
                    </li>
                }
                {
                    member &&
                    <li onClick={() => history.push("/member")}>
                        <Ri.RiBuilding2Line size={40} />
                        Área do <br /> Estabelecimento
                    </li>
                }
                {
                    (!manager && !member) &&
                    <b>Está área é exclusiva para usuários com estabelecimentos cadastrados</b>
                }
            </ul>
        </Container>
    );
}

export default AccountSelectArea;