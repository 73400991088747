import React, { useRef } from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../../services/api";
import getValidationsErrors from "../../../utils/getValidationsErros";
import { useErrors } from "../../../context/errors";

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import {
    Container
} from "./styles";

interface FormData {
    email: string;
    newPassword: string;
    confirmPassword: string;
    token: string;
}

interface ParamsData { 
    code: string;
}

interface AccountResetPasswordData extends RouteComponentProps<ParamsData> {

}

const AccountResetPassword: React.FC<AccountResetPasswordData> = (props) => {

    const formRef = useRef<FormHandles>(null);
    const { sendMessages } = useErrors();

    const code = props.match.params.code;

    const handleSubmitForm: SubmitHandler<FormData> = async (data) => {
        
        try {
            const schema = Yup.object().shape({
                email: Yup.string()
                    .max(50, "Máximo de 50 caracteres")
                    .email("E-mail inválido")
                    .required("Entre com o e-mail"),
                newPassword: Yup.string()
                    .min(6, "Mínimo de 6 caracteres")
                    .required("Entre com a senha"),
                confirmPassword: Yup.string()
                    .test(
                        "password",
                        "As senhas devem ser iguais",
                        value => {
                            return value === data.newPassword
                        }
                    )
                    .required("Redigite sua senha")
            });
            
            await schema.validate(data, {
                abortEarly: false
            });

            try {
                var response = await api.post(
                    'account/resetpassword', data
                );

                if(response.status === 200 || response.status === 204) {
                    toast.success("Senha alterarda com sucesso");
                }
                else {
                    toast.error("Não foi possível alterar sua senha");
                }
            }
            catch(err) {
                sendMessages(err.response.data.errors.Messages);
            }
        } catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationsErrors(err));
            }
        }

    }

    return (
        <Container>
            <h1>ALTERE SUA SENHA</h1>
            <Form ref={formRef} onSubmit={handleSubmitForm}>
                <Input name="code" type="hidden" value={code} />
                <Input name="email" placeholder="Digite seu e-mail" />
                <Input name="newPassword" type="password" placeholder="Digite sua nova senha" />
                <Input name="confirmPassword" type="password" placeholder="Redigite sua nova senha" />
                <Button type="submit" styliest="success">Alterar Senha</Button>
            </Form>
            <Link to="Logon">Voltar</Link>
        </Container>
    );
}

export default AccountResetPassword;