import styled from "styled-components";

export const Container = styled.header`
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;

    @media (max-width: 768px) {
        text-align: center;
        flex-direction: column !important;
    }
`;