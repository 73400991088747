import styled from "styled-components";

export const Container = styled.main`

    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas:
        "header header"
        "menu content"
    ;
    grid-template-columns: 25% 1fr;
    grid-template-rows: auto 1fr;

    header {
        grid-area: header;
    }

    nav {
        grid-area: menu;
        margin-top: 2rem;

        ul {
            list-style: none;
            
            li {
                background: var(--rgba-black-transparent);
                margin-bottom: 1rem;
                padding: 1.5rem;
                border-radius: .5rem;
                transition: background .2s;
                cursor: pointer;

                :hover {
                    background: var(--color-black-ligther);
                }
            }
        }
    }

    & > section {
        grid-area: content;
        margin-top: 2rem;
        margin-left: 2rem;
        background: var(--rgba-black-transparent);
        border-radius: var(--border-radius);
        padding: var(--padding-inner-max);
    }
`;