import styled from "styled-components";

export const Container = styled.div`

    background: var(--rgba-black-transparent);
    margin-bottom: 1rem;
    padding: 1.5rem;
    border-radius: .5rem;
    transition: background .2s;
    cursor: pointer;

    :hover {
        background: var(--color-black-ligther);
    }

`;