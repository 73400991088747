import React, {
    useState,
    useCallback,
    useEffect
} from "react";
import * as Fi from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import imgProfileImage from "~/../../src/assets/images/personal_site.svg";

import api from "../../../../services/api";
import { get } from "../../../../services/promises";

import { useAxiosErrors } from "../../../../context/axiosErrors";
import { useAuth } from "../../../../context/auth";
import { CompanyData, DiscountData, DiscountStatus } from "../../../../models";

import Header from "../../../../components/Header";
import Button from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import DiscountCreate from "../../../../components/Discount/Create";
import DiscountUpdate from "../../../../components/Discount/UpdateByMember";
import DiscountRemove from "../../../../components/Discount/Remove";
import List from "../../../../components/List";
import ListItem from "../../../../components/ListItem";
import Badge from "../../../../components/Badge";
import Skeleton from "../../../../components/Skeleton";

import {
    Container,
    HorizontalContent,
    ContainerCustomerWithoutImage
} from "./styles";

const AreaCompanyDiscount: React.FC = () => {

    const navigation = useHistory();

    const [ discounts, setDiscounts ] = useState<DiscountData[]>([]);
    const [ discountSelected, setDiscountSelected ] = useState<DiscountData>({} as DiscountData);
    const [ showModalCreate, setShowModalCreate ] = useState<boolean>(false);
    const [ showModalUpdate, setShowModalUpdate ] = useState<boolean>(false);
    const [ showModalRemove, setShowModalRemove ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const { company } = useAuth();
    const { sendError } = useAxiosErrors();

    //eslint-disable-next-line
    async function loadDiscounts(): Promise<void> {        
        get(`member/discount/bycompany/${company?.id}`)
            .then(response => {
                setDiscounts(response as DiscountData[]);
                setIsLoading(false);
            })
            .catch(err => sendError(err));
    }

    useEffect(() => {
        loadDiscounts();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadDiscounts();
        //eslint-disable-next-line
    }, [showModalUpdate]);

    const handleDiscountRegistered = useCallback(() => {
        loadDiscounts();
        setShowModalCreate(false);
        //eslint-disable-next-line
    }, []);

    const handleDiscountUpdated = useCallback(() => {
        loadDiscounts();
        setShowModalUpdate(false);
        //eslint-disable-next-line
    }, []);

    const handleDiscountSelected = useCallback(
        (discount: DiscountData, toUpdate: boolean, toRemove: boolean) => {
            setDiscountSelected(discount);

            if(toUpdate) {
                setShowModalUpdate(true);
            }
            
            if(toRemove) {
                setShowModalRemove(true);
            }
            //eslint-disable-next-line
        }, 
    []);

    const handleActiveDiscount = useCallback(async discount => {
        await api.post(`member/discount/${discount.id}/active`)
            .then(() => {
                loadDiscounts();
                toast.success("Descontinho Ativado");
            })
            .catch(err => sendError(err));
    }, [loadDiscounts, sendError]);

    const handleRemoveDiscount = useCallback(async discount => {
        await api.post(`member/discount/${discount.id}/remove`)
            .then(() => {
                loadDiscounts();
                toast.success("Descontinho Desativado");
            })
            .catch(err => sendError(err));
    }, [loadDiscounts, sendError]);

    return (
        <>
            {
                company?.imageUriLow
                    ? 
                    <Container>
                        <Header>
                            <h1>Descontinhos</h1>
                            <Button
                                styliest="success"
                                onClick={() => setShowModalCreate(true)}
                            >
                                Criar Descontinho
                            </Button>
                        </Header>
                        {
                            isLoading ? <Skeleton /> :
                            discounts && discounts.length > 0 ? 
                                <List>
                                    {discounts.map(discount => (
                                        <ListItem key={discount.id}>
                                            <div>
                                                <HorizontalContent>
            
                                                    <p>{discount.title}</p>

                                                    {
                                                        discount.removed 
                                                            ? 
                                                                <Badge styliest="danger">
                                                                    Desabilitado
                                                                </Badge>
                                                            : <>
                                                                {
                                                                    discount.status === DiscountStatus.InCreation &&                                            
                                                                    <Badge styliest="warning">
                                                                        Finalize a criação do seu Descontinho
                                                                    </Badge>
                                                                }
                        
                                                                {
                                                                    discount.status === DiscountStatus.SendToReview &&
                                                                    <Badge styliest="info">
                                                                        Aguardando aprovação
                                                                    </Badge>
                                                                }
                        
                                                                {
                                                                    discount.status === DiscountStatus.Aproved &&
                                                                    <Badge styliest="success">
                                                                        Aprovado
                                                                    </Badge>
                                                                }
                                                            </>
                                                    }
            
                                                </HorizontalContent>
                                                <label>{discount.discountPercent}% OFF</label>
                                            </div>
                                            <menu>    
                                                {
                                                    discount.status !== DiscountStatus.Aproved
                                                        ? (
                                                            <>
                                                                <Fi.FiChevronsRight 
                                                                    size={20} 
                                                                    onClick={() => handleDiscountSelected(discount, true, false)} />                                                                          
                                                                <Fi.FiTrash 
                                                                    size={20} 
                                                                    onClick={() => handleDiscountSelected(discount, false, true)} />
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                {/* <Fi.FiGrid 
                                                                    size={20} 
                                                                    onClick={() => handleDiscountSelected(discount)} /> */}
                                                                <Fi.FiEdit 
                                                                    size={20} 
                                                                    onClick={() => handleDiscountSelected(discount, true, false)} />
                                                                {
                                                                    discount.removed 
                                                                        ? <Fi.FiToggleLeft  
                                                                            size={20} 
                                                                            color="red"
                                                                            onClick={() => handleActiveDiscount(discount)} />  
                                                                        : <Fi.FiToggleRight    
                                                                            size={20} 
                                                                            color="green"
                                                                            onClick={() => handleRemoveDiscount(discount)} />
                                                                }
                                                            </>
                                                        )
                                                }
                                            </menu>
                                        </ListItem>
                                    ))}
                                </List> : 
                                <p>Estabelecimento não possui descontinho</p>
                        }   
                        <Modal
                            visible={showModalCreate}
                            size="big"
                            handleClose={setShowModalCreate}
                        >
                            <DiscountCreate 
                                company={company as CompanyData}
                                setVisibility={setShowModalCreate}
                                handleRegistered={handleDiscountRegistered}
                            />
                        </Modal>
                        <Modal
                            visible={showModalUpdate}
                            size="max"
                            handleClose={setShowModalUpdate}
                        >
                            <DiscountUpdate
                                discount={discountSelected}
                                setVisibility={setShowModalUpdate}
                                handleUpdated={handleDiscountUpdated}
                            />
                        </Modal>
                    </Container>                
                    : <ContainerCustomerWithoutImage>
                        <img src={imgProfileImage} alt="Cadastre a logo do seu estabelecimento" />
                        <p onClick={() => navigation.push("/company/profile")}>
                            Cadastre a logo do seu <b>Estabelecimento</b>
                        </p>
                    </ContainerCustomerWithoutImage>
            }
            <Modal 
                size="small" 
                visible={showModalRemove}
                handleClose={setShowModalRemove}>
                <DiscountRemove 
                    discount={discountSelected}
                    setVisibility={setShowModalRemove}
                    handleRemoved={() => {
                        loadDiscounts();
                        setShowModalRemove(false);
                    }}
                />
            </Modal>
        </>
    );
}

export default AreaCompanyDiscount;