import styled from "styled-components";

export const Container = styled.main`

    text-align: center;

    menu {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 4rem;

        section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: solid .2rem var(--rgba-white-original);
            border-radius: 1rem;
            padding: 10rem 5rem;
            width: 50%;
            text-align: center;
            transition: background .6s;
            cursor: pointer;

            p {
                font-weight: bold;
                transition: color .6s;
            }

            img {
                width: 70%;
                margin-bottom: var(--margin-2x);
            }

            :hover {
                background: var(--rgba-white-original);

                p {
                    color: var(--color-black-ligther);
                }
            }
        }
    }

`;