import React, {
    useRef,
    useState
} from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import api from "../../../services/api";
import getValidationErrors from "../../../utils/getValidationsErros";
import { useAxiosErrors } from "../../../context/axiosErrors";

import Input from "../../Input";
import InputMask from "../../InputMask";
import Button from "../../Button";

import { 
    CompanyData
} from "../../../models";

import {
    Container
} from "./styles";

interface CompanyCreateProps {
    company: CompanyData;
    setVisibility(value: boolean): void;
    handleCompanyUpdated(): void;
}

const CompanyUpdate = ({ company, setVisibility, handleCompanyUpdated } : CompanyCreateProps) => {

    const formRef = useRef<FormHandles>(null);

    const [ buttonInProcess, setButtonInProcess ] = useState<boolean>(false);
    const { sendError } = useAxiosErrors();

    const handleSubmit: SubmitHandler<CompanyData> = async (data) => {
        setButtonInProcess(true);

        try {
        
            const schema = Yup.object().shape({
                name: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre como o Nome Fantasia"),
                corporateName: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .required("Entre com a Razão Social"),
                cnpj: Yup.string()
                    .max(18, "Máximo de 18 caracteres")
                    .required("Entre com o CNPJ"),
                email: Yup.string()
                    .max(100, "Máximo de 100 caracteres")
                    .email("E-mail inválido")
                    .required("Entre com o E-mail"),
                phoneNumber: Yup.string()
                    .max(17, "Máximo de 17 caracteres")
                    .required("Entre com o telefone")
            });

            await schema.validate(data, {
                abortEarly: false
            });

            await api.put(`member/company/${company.id}`, data).then(() => {
                toast.success("Estabelecimento atualizado");
            }).catch(err => sendError(err));

            handleCompanyUpdated();
        }
        catch(err) {
            if(err instanceof Yup.ValidationError) {
                formRef.current?.setErrors(getValidationErrors(err));
            }
            else {
                sendError(err);
            }
        }

        setButtonInProcess(false);
    }

    return (
        <Container>
            <h2>Atualizar Estabelecimento</h2>
            <Form ref={formRef} initialData={company} onSubmit={handleSubmit}>
                <Input name="id" type="hidden" />
                <InputMask 
                    name="cnpj"
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99" />
                <Input 
                    name="name"
                    placeholder="Nome Fantasia"
                    maxLength={100} />
                <Input 
                    name="corporateName"
                    placeholder="Razão Social"
                    maxLength={100} />
                <Input 
                    name="email"
                    placeholder="E-mail"
                    inputMode="email"
                    maxLength={100} />
                <InputMask
                    name="phoneNumber"
                    placeholder="Telefone"
                    inputMode="numeric"
                    mask="(99) 9 9999-9999" />
                <Button
                    type="submit" 
                    styliest="success"
                    inProcess={buttonInProcess}>
                    Finalizar
                </Button>
            </Form>
            <small onClick={() => setVisibility(false)}>Voltar</small>
        </Container>
    );
}

export default CompanyUpdate;