import styled from "styled-components";

export const Container = styled.main`
    
    width: 350px;
    max-width: 100%;
    text-align: center;

    h1 {
        margin-bottom: 2rem;
    }

    button {
        margin: 1rem 0px;
        width: 100%;
    }

    input { 
        text-align: center;
    }

    span {
        color: var(--color-white-original);
    }

    a {
        color: var(--color-white-original);
        font-size: 1.3rem;
    }
    form {

        input, select {
            margin-bottom: var(--margin-2x);
        }

        span {
            color: #fff;
        }

        button {
            width: 100%;
        }
    }
`;