import styled from "styled-components";

export const Container = styled.main`

    text-align: center;

    form {

        input {
            margin-top: var(--margin);
            text-align: center;
        }

        select { 
            margin-top: var(--margin);
        }

        button {
            width: 100%;
            margin-bottom: var(--margin);
        }
    }

    small {
        cursor: pointer;
    }

`;